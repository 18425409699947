<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Attach project Leader</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <label for="project_leader">Project Leader</label>
      <select class="form-control" id="project_leader" formControlName="project_leader">
        <option *ngFor="let leader of projectLeaders" [value]="leader.user_id">{{ leader.first_name }} {{ leader.last_name }}</option>
      </select>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="handleSubmit()">Submit</button>
</div>

