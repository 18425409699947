<ng-template #load>
  <ng-container *ngIf="!errorFound; else failed">
    <div class="text-center blink mt-5">Loading...</div>
  </ng-container>
</ng-template>

<ng-template #failed>
  <div class="text-center mt-5">{{error}}</div>
</ng-template>

<div class="videospage">

  <div class="page-header">
    <h2>Videos </h2>
    <div class="toolbar" style="justify-content: flex-start;">
      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle *ngIf="domain">{{domain|t}}</button>
        <button class="btn" ngbDropdownToggle *ngIf="!domain">Loading</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem *ngFor="let d of domains" (click)="domain = d">{{d|t}}</button>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{target && !target.label.includes('null') ? target.label : ''}}</button>
        <div ngbDropdownMenu class="max-height-200 overflow-y-auto">
          <button ngbDropdownItem *ngFor="let u of sortUser(users)" (click)="setTargetUser(u)">
            {{ u.system.teltonika && u.system.teltonika.short_name && u.system.teltonika.short_name.length > 0 ? u.system.teltonika.short_name : (u.first_name || '') + ' ' + (u.last_name || '') }}
          </button>
        </div>
      </div>

      <div class="form-group">
        <div class="input-group input-group-sm">
          <img src="{{!staredOnly? '/assets/images/star.png' : '/assets/images/yellow-star.png'}}"
            class="icon-img cursor-pointer" width="24" height="24" (click)="filterStarOnly()" />
        </div>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="sidebar">

      <ng-container *ngFor="let menuItem of menu">
        <ng-container *ngIf="menuItem.label == domain">
          <div class="menuitem-wrapper">
            <span style="position: absolute; right: 10px; font-size: 14px;">Score</span>
            <div style="margin-top: 4px; margin-bottom: 5px;" *ngFor="let subMenuItem of menuItem.children">
              <div class="boldtitle d-flex" *ngIf="subMenuItem.children.length">
                <div class="flex-grow-1" translate>{{subMenuItem.name|t}}</div>
              </div>
              <div class="subparams">
                <div *ngFor="let sub2menuitem of subMenuItem.children" class="pointer w-100 d-inline-block">
                  <label class="submenu-item">
                    <input type="checkbox" name="checkbox" value="{{subMenuItem.label + '/' +sub2menuitem.label}}"
                      (change)="onFilterChange($event)"
                      [checked]="isFilterChecked(subMenuItem.label + '/' +sub2menuitem.label)" />
                    <div class="submenu-item-label-wrapper"
                      [ngStyle]="{ 'background-color': subMenuItem.color, 'cursor': 'pointer' }">
                      <span class="flex-span">
                        <img src={{sub2menuitem.icon}} class="icon-img" width="24" height="24" />
                        <span class="subparam-count">{{sub2menuitem.count}}</span>
                        <span translate>{{sub2menuitem.name|t}}</span>
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="!loader && !errorFound; else load">
      <div class="videoscontainer h-100">
        <div class="videos-table-wrapper">
          <table>
            <thead style="background-color: white;">
              <tr>
                <th style="cursor: pointer" (click)="toggleSort('type')">Type <i class="fa fa-sort"
                    aria-hidden="true"></i></th>
                <th style="cursor: pointer" (click)="toggleSort('timeOfEvent')"><span translate>TIME OF EVENT</span> <i class="fa fa-sort"
                    aria-hidden="true"></i></th>
                <th style="cursor: pointer" (click)="toggleSort('driver')"><span translate>Driver</span> <i class="fa fa-sort"
                    aria-hidden="true"></i></th>
                <th style="cursor: pointer" (click)="toggleSort('level')"><span translate>LEVEL</span> <i class="fa fa-sort"
                    aria-hidden="true"></i></th>
                <th style="cursor: pointer" (click)="toggleSort('durationStr')"><span translate>Duration</span><i class="fa fa-sort"
                    aria-hidden="true"></i></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let video of filterVideos() | paginate: { itemsPerPage: pageSize,currentPage: p}"
                id="{{video.video_uuid}}"
                [ngClass]="{'selected': video.video_url && selected && video.video_uuid===selected.video_uuid, 'non-clickable': !video.video_url}"
                (click)="selectVideo(video)">
                <td> <img *ngIf="video.subparam"
                    src="https://safet-idreams.s3-eu-west-1.amazonaws.com/cardio/svg_graphs/static/icons_subparams/{{video.subparam}}.svg"
                    width="40" height="40" /></td>
                <td [ngClass]="{'cursor-pointer':video.video_url}" (click)="video.video_url && openVideoInfo(video)">
                  {{ video.timeOfEvent }}
                </td>
                <td>{{ video.driver }}</td>
                <td>{{ video.level }}</td>
                <td>{{ video.durationStr }}</td>
                <td><img [src]="video.starred?'/assets/images/yellow-star.png':'/assets/images/star.png'"
                    class="icon-img" width="24" height="24" style="cursor: pointer;" (click)="starringVideo(video)" />
                </td>
              </tr>
            </tbody>
          </table>
          <table *ngIf="(videoSub && videoSub.closed && filterVideos().length === 0) || !videoSub">
            <tbody class="">
              <tr>
                <div class="text-center mt-5 text-white">No Videos to show</div>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="videos-table-paginator">
          <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)"
            directionLinks="true" responsive="false" previousLabel='<' nextLabel='>'
            screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page">
          </pagination-controls>
          <div style="margin-left:10px; padding-top: 7px;padding-bottom: 16px;">Page {{p}} of {{getPages()}}</div>
        </div>
      </div>
    </ng-container>
  </div>


</div>

<ng-template #rt let-r="result" let-t="term">
  <div class="text-left">
    <i style="width: 30px; text-align: center; color: silver" class="fas"
      [ngClass]="{'fa-users': r.prefix == 'Group', 'fa-user': r.prefix == 'User'}"></i>
    <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
  </div>
</ng-template>
