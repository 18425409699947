import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User, CreateUser, ModalType, EditUser, ProjectTypeKey } from '../../../../helpers/interfaces';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-user-modal',
  templateUrl: './admin-user-modal.component.html',
  styleUrls: ['./admin-user-modal.component.scss'],
})
export class AdminUserModalComponent implements OnInit {
  modalType: ModalType = ModalType.creating;
  isProjectLeader = false;
  userForm: UntypedFormGroup;
  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private dataService: DataService) {}

  ngOnInit(): void {
    // init project form
    if (this.modalType !== ModalType.updating) {
      this.setUserForm({
        first_name: '',
        last_name: '',
        short_name: '',
        email: '',
      });
    }
  }

  setUserForm(user: CreateUser) {
    this.userForm = this.fb.group({
      ...user,
      short_name: user.short_name || '',
    });
  }

  setModalType(modalType: ModalType) {
    this.modalType = modalType;
  }

  getModalTitle() {
    return this.modalType === ModalType.creating ? 'Create User' : 'Edit User';
  }

  handleSubmit() {
    if (this.modalType === ModalType.updating) {
      const { user_id, short_id, first_name, last_name, short_name, email } = this.userForm.value as EditUser;
      const editUser: EditUser = {
        user_id,
        short_id,
        first_name,
        last_name,
        short_name,
        email
      };
      this.activeModal.close(editUser);
    }
    else {
      const { first_name, last_name, short_name, email } = this.userForm.value as CreateUser;
      const createUser: CreateUser = {
        first_name,
        last_name,
        short_name,
        email,
      };
      this.activeModal.close(createUser);
    }
  }
}
