import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export type LanguageCode = 'en' | 'fr';
export type LanguageCodeAPI = 'eng' | 'fra';

export interface Language {
  label: string;
  code: LanguageCode;
}

const LANGUAGE_KEY = 'safet.currentLanguage';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly listOfLanguages: Language[] = [
    {
      label: 'English',
      code: 'en'
    },
    {
      label: 'French',
      code: 'fr'
    }
  ];
  currentLanguage: Language = this.listOfLanguages[0];

  constructor(private translate: TranslateService) {
    const currentLanguage = this.getCurrentLanguageFromStore();
    const storedLanguageCode = currentLanguage ? currentLanguage.code : 'en';
    this.switchLanguage(storedLanguageCode);
  }


  switchLanguage(languageCode: LanguageCode): void {
    const selectedLanguage = this.listOfLanguages.find((language) => language.code === languageCode);
    if (selectedLanguage) {
      this.translate.use(languageCode);
      localStorage.setItem(LANGUAGE_KEY, JSON.stringify(selectedLanguage));
      this.currentLanguage = this.listOfLanguages.find((language) => language.code === languageCode);
    } else {
      this.currentLanguage = this.listOfLanguages[0];
      throw new Error(`Language with code ${languageCode} not found`);
    }
  }

  private getCurrentLanguageFromStore(): Language {
    const storedLanguage = localStorage.getItem(LANGUAGE_KEY);
    if (storedLanguage) {
      return JSON.parse(storedLanguage);
    }
    return null;
  }

  public getCurrentLanguage(): Language {
    return this.currentLanguage;
  }

  getListOfLanguages(): Language[] {
    return this.listOfLanguages;
  }

  getCurrentLanguageCodeAPI(): LanguageCodeAPI {
    return this.currentLanguage.code === 'en' ? 'eng' : 'fra';
  }
}
