<div class="modal-header">
	<h4 class="modal-title" id="modal-title">{{ getModalTitle() }}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<form [formGroup]="userForm">
		<div class="form-group">
			<label for="first_name">First Name</label>
			<input id="first_name" class="form-control" name="first_name" formControlName="first_name" />
		</div>

		<div class="form-group">
			<label for="last_name">Last Name</label>
			<input id="last_name" class="form-control" name="last_name" formControlName="last_name" />
		</div>

		<div class="form-group" *ngIf="!isProjectLeader">
			<label for="short_name">Short Name</label>
			<input id="short_name" class="form-control" name="short_name" formControlName="short_name" />
		</div>

		<div class="form-group">
			<label for="email">Email</label>
			<input id="email" class="form-control" type="email" name="email" formControlName="email" />
		</div>
	</form>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-primary" (click)="handleSubmit()">Submit</button>
</div>
