<div class="modal-header">
  <h4 class="modal-title"><span translate>LEVELS</span></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pb-5">

  <ng-container *ngIf="(!sub || sub.closed); else loader">
    <table *ngIf="categories.length" class="table-responsive">
      <thead>
      <tr>
        <th><span translate>LEVEL</span></th>
        <th>1</th>
        <th>2</th>
        <th>3</th>
        <th>4</th>
        <th>5</th>
        <th>6</th>
        <th>7</th>
        <th>8</th>
        <th>9</th>
        <th>10</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let category of categories">
        <th><span translate>{{category.name|t}}</span></th>
        <td *ngFor="let level of category.levels"><app-doughnut [value]="level"></app-doughnut></td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="!categories.length" class="text-center"><span translate>No Levels to display</span></div>
  </ng-container>

  <ng-template #loader>
    <div *ngIf="sub && !sub.closed" class="text-center blink"><span translate>Loading</span>...</div>
  </ng-template>

</div>
