import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, isEmpty, map, mergeMap, switchMap, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import { DomSanitizer } from "@angular/platform-browser";
import { CompressImageService } from 'src/app/services/compress-image.service';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit, OnDestroy {

  newMessage: UntypedFormGroup;
  formImages: any[] = [];

  messages: any[] = [];
  users: any[] = [];
  groups: any[] = [];

  own
  type = 'project';
  recipientType = 'project';

  recipient = {
    project: null,
    group: null,
    user: null
  };

  categories: any[] = [
    {
      label: 'Project',
      value: 'project',
    },
    {
      label: 'Community',
      value: 'group',
    },
    {
      label: 'User',
      value: 'user',
    },
  ];
  filters = {
    project: null,
    group: null,
    user: null,
    own: false
  };

  sub: Subscription;
  destroy$ = new Subject<void>();

  search: (text: Observable<string>) => Observable<readonly any[]>
  formatter: (item: any) => string;
  sendSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private ds: DomSanitizer,
    private cd: ChangeDetectorRef,
    private router: Router,
    private compressImage: CompressImageService
  ) { }

  ngOnInit(): void {
    this.newMessage = this.fb.group({
      to: [{}],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });

    this.formatter = (item) => item && item.label ? `${item.label}` : '';

    this.search = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      map(term => {
        const users = this.users.filter(x => x).map(user => ({ prefix: 'User', label: `${user.first_name} ${user.last_name}`, value: { user_id: user.user_id } }));
        const groups = this.groups.filter(x => x).map(group => ({ prefix: 'Group', label: `${group.name}`, value: { community_id: group.id } }));
        return [...groups, ...users].filter(item => item.label.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
      })
    )

    this.loadMessages();
  }

  ngOnDestroy(): void {
    this.sub && this.sub.unsubscribe();
    this.sendSub && this.sendSub.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  postMessage() {
    if (this.newMessage.value.subject && this.newMessage.value.message) {
      const body = {
        title: this.newMessage.value.subject,
        body: this.newMessage.value.message,
        project_id: this.dataService.project.project_id
      };

      if (this.recipientType !== 'project') {
        let to = {};
        if (this.recipientType === 'user') {
          to['user_id'] = this.recipient[this.recipientType]['user_id'];
        }
        else if (this.recipientType === 'group') {
          to['community_id'] = this.recipient[this.recipientType]['id'];
        }
        Object.assign(body, to);
      }

      this.sendSub = this.dataService.postMessage(body).pipe(
        mergeMap(result => this.formImages.length
          ? combineLatest(this.formImages.map(img_vid => img_vid.is_video ? this.postVideo(result.id, img_vid) : this.postImage(result.id, img_vid.file)))
          : of([])
        )
      ).subscribe(result => {
        this.newMessage.reset();
        this.formImages = [];
        this.loadMessages();
      });
    }

  }

  postImage(msgId, file) {
    return this.dataService.postImage(msgId, file)
      .pipe(
        catchError(() => of(null))
      );
  }

  postVideo(msgId, file) {
    return this.dataService.postVideo(msgId, file)
      .pipe(
        catchError(() => of(null))
      );
  }

  loadMessages() {
    this.sub = this.dataService.getMessages<any[]>().pipe(
      switchMap(m => this.loadUsers(m))
    ).subscribe(messages => {
      this.messages = messages;
    });
  }

  loadUsers(messages: any[]) {
    return this.users.length ? of(messages) :
      combineLatest([this.dataService.getProjectUsers(), this.dataService.getProjectGroups()]).pipe(
        map(([users, groups]) => {
          users.forEach(user => this.users[user.user_id] = user);
          this.groups = groups;
          this.filters.group = this.groups[0];
          const availableUsers = this.users.filter(e => e);
          this.filters.user = availableUsers.length ? availableUsers[0] : null;
          this.recipient.group = this.groups[0];
          this.recipient.user = availableUsers.length ? availableUsers[0] : null;
          return messages;
        })
      );
  }

  like(msg: any) {
    const idx = msg.likes.findIndex(like => like.user_id == this.dataService.user.user_id);

    if (idx > -1) {
      msg.likes.splice(idx, 1);
      this.dataService.deleteLike(msg.message_id).subscribe();
    } else {
      msg.likes.push({ user_id: this.dataService.user.user_id });
      this.dataService.postLike(msg.message_id).subscribe();
    }
  }

  comment(msg: any) {
    this.router.navigate(['/forum', 'message', msg.message_id]);
  }

  newMsgAddFile(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        let item = {
          mode: 'add',
          name: droppedFile.fileEntry.name,
          file: null,
          url: null
        };

        this.addFile(droppedFile.fileEntry as FileSystemFileEntry, item).then(i => {
          this.formImages.push(i);
        });
      }
    }
  }

  private addFile(fileEntry: FileSystemFileEntry, item: any) {
    return new Promise(resolve => {
      fileEntry.file((f: File) => {
        console.log(f.type);

        if (f.type.indexOf('video') > -1) {
          f['url'] = this.ds.bypassSecurityTrustStyle(`url('/assets/images/thumbnail.jpg')`);
          f['is_video'] = true;
          resolve(f);
        }
        else {
          this.compressImage.compress(f)
            .pipe(take(1))
            .subscribe(compressedImage => {
              item['file'] = compressedImage;
              console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
              let reader = new FileReader();
              reader.readAsDataURL(compressedImage);
              reader.onloadend = () => {
                item['url'] = this.ds.bypassSecurityTrustStyle(`url('${reader.result}')`);
                item['is_video'] = false;
                resolve(item);
              };
            });
        }
      });
    });
  }

  newMsgDelFile(img: any) {
    const idx = this.formImages.indexOf(img);
    if (idx > -1) {
      this.formImages.splice(idx, 1);
    }
  }

  get sendDisabled() {
    return !this.newMessage.valid || (this.sendSub && !this.sendSub.closed);
  }

  setType(value) {
    this.type = value;
  }

  setRecipientType(event, value) {
    event.preventDefault();
    this.recipientType = value;
  }

  setFilter(field, value) {
    this.filters[field] = value;
  }

  setRecipient(event, field, value) {
    event.preventDefault();
    this.recipient[field] = value;
  }

  availableUsers() {
    return this.users.filter(e => e);
  }

  filterMessages() {
    let filterKey = '',
      key = null;
    switch (this.type) {
      case 'project':
        filterKey = 'project_id';
        break;
      case 'group':
        filterKey = 'community_id';
        key = 'id';
        break;
      case 'user':
        filterKey = 'user_id',
          key = 'user_id';
        break;
      default:
        break;
    }
    return this.messages.filter(e => {
      if (this.type == 'project') {
        if (this.filters.own) {
          return e.sender_id === this.dataService.user.user_id;
        }
        return e;
      }
      else {
        if (this.filters.own) {
          return e.sender_id === this.dataService.user.user_id && e[filterKey] === this.filters[this.type][key];
        }
        return e[filterKey] === this.filters[this.type][key];
      }
    })
  }

}
