import {Component, OnInit} from '@angular/core';
import {StorageService} from "../../../services/storage.service";
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.scss']
})
export class PhasesComponent implements OnInit {

  functionalities = {};
  sub;

  constructor(
    private dataService: DataService,
    private storage: StorageService,
  ) {
  }

  ngOnInit(): void {
    this.sub = this.dataService.getPhases().subscribe(data => {
      this.functionalities = data.functionalities;
      Object.keys(this.functionalities).map(key => {
        this.functionalities[key].title = this.storage.get('project').configuration.psychological_profile_type_dict[key];
      });
    })
  }

  getKeys(obj) {
    return Object.keys(obj);
  }

  checkChange($event, k1, k2) {
    console.log($event.target.checked);
    this.functionalities[k1][k2].status = $event.target.checked ? 'enabled' : 'disabled';
  }

  save() {
    let body = {};
    Object.keys(this.functionalities).map(key => {
        body[key] = Object.assign({} , this.functionalities[key]);
        delete body[key].title;
    });
    this.sub = this.dataService.setPhases(body).subscribe(data => {
      console.log(data)
    })
  }
}
