<div id="cont">
  <svg
    [attr.height]="radius * 2"
    [attr.width]="radius * 2"
    >
      <circle
        stroke="black"
        stroke-width="10"
        [style.stroke-dasharray]="strokeDasharray"
        [style.stroke-dashoffset]="0"
        fill="white"
        [attr.r]="normalizedRadius"
        [attr.cx]="radius"
        [attr.cy]="radius"
      />
      <circle
        stroke="#f2a100"
        stroke-width="10"
        [style.stroke-dasharray]="strokeDasharray"
        [style.stroke-dashoffset]="strokeDashoffset"
        fill="transparent"
        [attr.r]="normalizedRadius"
        [attr.cx]="radius"
        [attr.cy]="radius"
      />
  </svg>
  <div class="percent">{{percent}}</div>
</div>