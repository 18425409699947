<div class="toolbar justify-content-start">
  <h3 translate>Pros & Cons</h3>
  <div ngbDropdown class="custom_dropdown">
    <button class="btn" ngbDropdownToggle>{{domain|t}}</button>
    <div ngbDropdownMenu>
      <button *ngFor="let d of domains" ngbDropdownItem (click)="setDomain(d)">{{d|t}}</button>
    </div>
  </div>
</div>
<div class="prosnconscontainer">
    <table class="param-table">
        <thead>
            <tr>
                <th *ngFor="let h of headers">{{h}}</th>
            </tr>
        </thead>
        <tbody *ngIf="dataSub && dataSub.closed">
            <tr *ngFor="let row of maxSubParamCount">
                <td *ngFor="let h of paramHeader">
                    <div *ngIf="params[h].children[row]" class="submenu-item-label-wrapper" [routerLink]="['/gamification', 'prosncons-edit']" [queryParams]="params[h].children[row].query">
                        <span class="flex-span" [ngStyle]="{ 'background-color': params[h].color, 'cursor': 'pointer' }" >
                            <img src="{{params[h].children[row].icon}}" class="icon-img" width="24" height="24" />
                            <span>{{params[h].children[row].name|t | translate}}</span>
                            <span class="subparam-count">{{params[h].children[row].pros | translate}}</span>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody>
            <tr *ngIf="dataSub && !dataSub.closed">
                <td colspan="5"><div class="blink text-center py-4"><span translate>Loading</span>...</div></td>
            </tr>
        </tbody>
    </table>
</div>
