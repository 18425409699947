import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from "../../../../services/data.service";
import { combineLatest, from, Observable, of, Subscription } from "rxjs";
import { catchError, defaultIfEmpty, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AlertPopupComponent } from "../../../_shared/alert-popup/alert-popup.component";
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-add-prosncons',
  templateUrl: './add-prosncons.component.html',
  styleUrls: ['./add-prosncons.component.scss']
})
export class AddProsnconsComponent implements OnInit, OnDestroy {

  item: any;
  items: any[];
  infos: any[] = [];
  id: number;

  title = '';
  description = [];
  pages: string[] = [];
  domain = '';
  param = '';
  subparam = '';
  subparam_label = '';
  type: string;
  private system: string;

  filesToUpload = [[]];
  videoToUpload = [[]];
  sub: Subscription;
  startString = "https://safe-t-picture.s3.amazonaws.com/test/knowns/";
  endString = "?response-content-disposition=";
  language_choices = [
    {short: 'eng', full: 'English'},
    {short: 'dut', full: 'Nederlands'},
    {short: 'deu', full: 'Deutsch'},
    {short: 'gre', full: 'Ελληνικά'},
    {short: 'por', full: 'Português'},
    {short: 'eng-uk', full: 'English (UK)'},
    {short: 'fra', full: 'Français'},
    {short: 'pol', full: 'Polski'},
  ];
  available_language_choices: string[] = [];
  available_known_languages: string[] = [];

  image_uploading = false;
  video_uploading = false;

  constructor(
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private dataService: DataService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;

    if (this.item) {
      this.domain = this.item.domain_type;
      this.param = this.item.param_type;
      this.subparam = this.item.subparam_type;
      this.subparam_label = this.dataService.project['configuration']['schema'][this.domain]?.params[this.param].subparams[this.subparam]['name'];
      // this.type = this.item.pros_and_cons_type;

      this.id = this.item.known_id;
      this.title = this.item.title;
    }
    this.dataService.getProjectConfiguration(this.dataService.project.project_id)
      .subscribe(res => {
        this.available_known_languages = res.available_known_languages;
        this.available_known_languages.map(language => {
          switch (language) {
            case this.language_choices[0].short:
              this.available_language_choices.push(this.language_choices[0].full);
              break;
            case this.language_choices[1].short:
              this.available_language_choices.push(this.language_choices[1].full);
              break;
            case this.language_choices[2].short:
              this.available_language_choices.push(this.language_choices[2].full);
              break;
            case this.language_choices[3].short:
              this.available_language_choices.push(this.language_choices[3].full);
              break;
            case this.language_choices[4].short:
              this.available_language_choices.push(this.language_choices[4].full);
              break;
            case this.language_choices[5].short:
              this.available_language_choices.push(this.language_choices[5].full);
              break;
            case this.language_choices[6].short:
              this.available_language_choices.push(this.language_choices[6].full);
              break;
            case this.language_choices[7].short:
              this.available_language_choices.push(this.language_choices[7].full);
              break;
            default:
              break;
          }
        })
        if (this.item)
          this.processLanguages();
      })
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  async processLanguages() {
    const promises = this.available_known_languages.map(language => {
      return this.dataService.getProAndCon(this.system, language, this.item.known_id, this.type).toPromise()
        .then(response => {
          return response;
        })
        .catch(err => {
          return null;
        });
    });

    this.items = await Promise.all(promises);
    this.items = this.items.filter(value => value !== null);

    this.items.forEach(item => {
      let description_item = item.pages.find(i => i.description !== '');
      if (description_item) {
        let index = item.pages.indexOf(description_item);
        item.pages.splice(index, 1);
        item.pages.unshift(description_item);
      }
    })

    for (let i=0; i<this.items.length; i++) {
      this.infos[i] = (this.items[i].pages || []).length > 0 ? this.items[i].pages[0] : {};
      this.description[i] = this.infos[i].description;
      this.pages[i] = this.infos[i].description;
    }

    for (let idx=0; idx<this.available_known_languages.length; idx++) {
      if (this.infos[idx] && this.infos[idx].image) {
        this.items[idx].pages
          .filter(page => page !== null)
          .forEach(page => {
            const loadImgs = this.loadImageOrVideo(page.image);
              combineLatest(loadImgs).subscribe(imgs => {
                imgs.forEach(i => {
                  if (!this.filesToUpload[idx])
                    this.filesToUpload[idx] = [];
                  this.filesToUpload[idx].push(i);
                })
              })
          })
      }
      if (this.infos[idx] && this.infos[idx].video) {
        this.items[idx].pages
          .filter(page => page.video != null)
          .forEach(page => {
            if (!this.videoToUpload[idx])
              this.videoToUpload[idx] = [];
            this.videoToUpload[idx].push({status: 200, url: page.video});
          })
      }
    }
  }

  loadImageOrVideo(url) {
    return this.dataService.getKnownImageOrVideo(url).pipe();
  }

  loadVideo(video_id) {
    return this.dataService.getProConsVideo(this.item.known_id, video_id)
      .pipe(
        map(video => ({
          video_id: video_id,
          item: video,
          url: video.url, mode: 'keep',
          thumbnail: '/assets/images/thumbnail.jpg'
        }))
      );
  }

  closeModal() {
    this.modal.close();
  }

  generateImageOrVideoId(file: File): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    let extension = file.name.split('.').pop();

    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result + '.' + extension;
  }

  addFilesToUpload(files: any, num: number) {
    const maxSizeInBytes = 5 * 1024 * 1024;
    const filteredFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size <= maxSizeInBytes) {
        filteredFiles.push(file);
      }
    }

    if (filteredFiles.length < files.length) {
      const ref = this.modalService.open(AlertPopupComponent, { centered: true });
      ref.componentInstance.title = 'Error';
      ref.componentInstance.msg = 'The Image Size should be less than 5MB';
    }

    filteredFiles.map(f => {
      if (!this.filesToUpload[num])
        this.filesToUpload[num] = [];
      this.filesToUpload[num].push({ item: f, url: URL.createObjectURL(f as Blob), mode: 'add' });
    });
    console.log(this.filesToUpload);
  }

  remove(index: number, num: number) {
    if (this.filesToUpload[num][index].mode == 'keep') {
      this.videoToUpload[num][index].mode = 'del';
    } else {
      this.filesToUpload[num].splice(index, 1);
    }
    console.log(this.filesToUpload);
  }

  addVideoToUpload(files: any, num: number) {
    Object.values(files).map((f: Blob) => {

      this.dataService.generateThumbnail(f).then(thumbnail => {
        if (!this.videoToUpload[num])
          this.videoToUpload[num] = [];
        this.videoToUpload[num].push({ item: f, url: URL.createObjectURL(f), mode: 'add', thumbnail: thumbnail });
      });
    });

    console.log(this.videoToUpload);
  }

  removeVideo(index: number, num: number) {
    if (this.videoToUpload[num][index].mode == 'keep') {
      this.videoToUpload[num][index].mode = 'del';
    } else {
      this.videoToUpload[num].splice(index, 1);
    }
    console.log(this.videoToUpload);
  }

  submit() {
    let body = [];
    let image_id = [[]];
    let video_id = [[]];
    let image = [[]];
    let video = [[]];
    if (this.item) {
      let image_length = [];
      let video_length = [];
      let attached_imgs = [[]];
      let attached_videos = [[]];
      let length = [];

      for (let i=0; i<this.available_known_languages.length; i++) {
        image_length[i] = 0;
        video_length[i] = 0;
        if (this.filesToUpload[i]) {
          this.filesToUpload[i]
            .filter(img => img.mode === 'add')
            .forEach(img => {
              image_length[i] ++;
              if (!image[i])
                image[i] = [];
              image[i].push(img);
              if (!image_id[i])
                image_id[i] = [];
              image_id[i].push(this.generateImageOrVideoId(img.item));
            })
          this.filesToUpload[i]
            .filter(img => img.status === 200)
            .forEach(img => {
              if (!attached_imgs[i])
                attached_imgs[i] = [];
              attached_imgs[i].push(img.url);
              image_length[i] ++;
            })
        }

        if (this.videoToUpload[i]) {
          this.videoToUpload[i]
            .filter(v => v.mode === "add")
            .forEach(v => {
              video_length[i] ++;
              if (!video[i])
                video[i] = [];
              video[i].push(v);
              if (!video_id[i])
                video_id[i] = [];
              video_id[i].push(this.generateImageOrVideoId(v.item));
            })
          this.videoToUpload[i]
            .filter(video => video.status === 200)
            .forEach(video => {
              if (!attached_videos[i])
                attached_videos[i] = [];
              attached_videos[i].push(video.url);
              video_length[i] ++;
            })
        }

        length[i] = image_length[i] > video_length[i] ? image_length[i] : video_length[i];

        body.push({
          title: this.title,
          system_type: this.system,
          domain_type: this.domain,
          param_type: this.param,
          subparam_type: this.subparam,
          project_id: this.dataService.project.project_id,
          language: this.available_known_languages[i],
          pages: [
            {
              description: this.description[i]
            }
          ],
          known_id: this.id
        })

        if (length[i] > 1) {
          for (let idx = 0; idx < length[i] - 1; idx ++) {
            body[i].pages.push({
              description: ''
            })
          }
        }
      }

      if (this.description.length === 0) {
        body[0] = {
          title: this.title,
          system_type: this.system,
          domain_type: this.domain,
          param_type: this.param,
          subparam_type: this.subparam,
          project_id: this.dataService.project.project_id,
          language: this.available_known_languages[0],
          pages: [
            {
              description: this.description[0]
            }
          ],
          known_id: this.id
        }
      }

      this.processProsAndCons(this.id, body, image, image_id, attached_imgs, video, video_id, attached_videos);
    }
    else {
      let image_length = [];
      let video_length = [];
      let length = [];

      for (let i=0; i<this.available_known_languages.length; i++) {
        image_length[i] = 0;
        video_length[i] = 0;
        if (this.filesToUpload[i])
          this.filesToUpload[i]
            .filter(img => img.mode === 'add')
            .forEach(img => {
              image_length[i] ++;
              if (!image[i])
                image[i] = [];
              image[i].push(img);
              if (!image_id[i])
                image_id[i] = [];
              image_id[i].push(this.generateImageOrVideoId(img.item));
            })

        if (this.videoToUpload[i])
          this.videoToUpload[i]
            .filter(v => v.mode === 'add')
            .forEach(v => {
              video_length[i] ++;
              if (!video[i])
                video[i] = [];
              video[i].push(v);
              if (!video_id[i])
                video_id[i] = [];
              video_id[i].push(this.generateImageOrVideoId(v.item));
            })

        length[i] = image_length[i] > video_length[i] ? image_length[i] : video_length[i];

        body.push(
          {
            title: this.title,
            system_type: this.system,
            domain_type: this.domain,
            param_type: this.param,
            subparam_type: this.subparam,
            project_id: this.dataService.project.project_id,
            language: this.available_known_languages[i],
            pages: [
              {
                description: this.description[i]
              }
            ]
          }
        )
        if (length[i] > 1) {
          for (let idx = 0; idx < length[i] - 1; idx ++) {
            body[i].pages.push({
              description: ''
            })
          }
        }
      }

      this.dataService.postProsncons(body[0], this.type).pipe(
        tap(result => {
          this.id = result.id;
          console.log(this.id);
        }),
      ).subscribe(res => {
        this.processProsAndCons(this.id, body, image, image_id, null, video, video_id, null);
      })
    }
  }

  async processProsAndCons(id, body: any[], image: any[][], image_id: any[][], attached_imgs: any[][], video: any[][], video_id: any[][], attached_videos: any[][]) {
    let idx = -1;
    const image_promise = this.postOrDeleteImage(id, body, image, image_id, attached_imgs);
    const video_promise = this.postOrDeleteVideo(id, body, video, video_id, attached_videos);
    await Promise.all([image_promise, video_promise]);
    for (const pro_and_con of body) {
      if (!this.item)
      pro_and_con['known_id'] = id;
      this.sub = this.dataService.postProsncons(pro_and_con, this.type).pipe(
        tap(result => {
          console.log(this.id);
        }),
      ).subscribe(res => {
        idx++;
        if (idx === body.length - 1)
          this.modal.close('saved');
      })
    }
  }

  async postOrDeleteImage(id, body: any[], img: any[][], image_id: any[][], attached_imgs: any[][]) {
    for(let i=0; i<this.available_known_languages.length; i++) {
      if (attached_imgs && (!img[i] || img[i].length === 0) && attached_imgs[i]) {
        let img_idx = 0;
        attached_imgs[i].forEach(attached_file => {
          let result = attached_file.substring(attached_file.indexOf(this.startString) + this.startString.length, attached_file.indexOf(this.endString));
          body[i].pages[img_idx]['picture_key'] = result;
          img_idx++;
        });
      }
      if (img[i])
        for (let idx1 = 0; idx1 < img[i].length; idx1++) {
          const file = img[i][idx1];
          try {
            this.image_uploading = true;
            const imgResult = await this.dataService.postKnownImage(id, this.dataService.project.project_id, image_id[i][idx1], file.item).toPromise();
            this.image_uploading = false;
            if (imgResult != null) {
              file.mode = 'keep';
              file.image_id = imgResult.image_id;
            }
            const idx2 = idx1;
            body[i].pages[idx2]['picture_key'] = imgResult.image_id;
            if (idx2 === img[i].length - 1 && attached_imgs && attached_imgs[i]) {
              let img_idx = 0;
              attached_imgs[i].forEach(attached_file => {
                let result = attached_file.substring(attached_file.indexOf(this.startString) + this.startString.length, attached_file.indexOf(this.endString));
                body[i].pages[idx2 + img_idx + 1]['picture_key'] = result;
                img_idx++;
              });
            }
          } catch (e) {
            const ref = this.modalService.open(AlertPopupComponent, { centered: true });
            ref.componentInstance.title = 'Error';
            ref.componentInstance.msg = 'Error while uploading file "' + file.item.name + '". ' + e.statusText;
            await ref.result;
          }
        }
    }
  }

  async postOrDeleteVideo(id, body: any[], video: any[][], video_id: any[][], attached_videos: any[][]) {
    for (let i=0; i<this.available_known_languages.length; i++) {
      if (attached_videos && (!video[i] || video[i].length === 0) && attached_videos[i]) {
        let video_idx = 0;
        attached_videos[i].forEach(attached_file => {
          let result = 'test/knowns/' + attached_file.substring(attached_file.indexOf(this.startString) + this.startString.length, attached_file.indexOf(this.endString));
          body[i].pages[video_idx]['video_key'] = result;
          video_idx ++;
        });
      }
      if (video[i])
        for (let idx1 = 0; idx1 < video[i].length; idx1++) {
          const file = video[i][idx1];
          try {
            this.video_uploading = true;
            const videoResult = await this.dataService.prosandconsVideoPresignedUrl(id, video_id[i][idx1], this.type).toPromise();
            this.video_uploading = false;
            if (videoResult != null) {
              file.mode = 'keep';
              file.video_id = videoResult.video_id;
            }
            const idx2 = idx1;
            body[i].pages[idx2]['video_key'] = videoResult.video_id;
            if (idx2 === video[i].length - 1 && attached_videos && attached_videos[i]) {
              let video_idx = 0;
              attached_videos[i].forEach(attached_file => {
                let result = 'test/knowns/' + attached_file.substring(attached_file.indexOf(this.startString) + this.startString.length, attached_file.indexOf(this.endString));
                body[i].pages[idx2 + video_idx + 1]['video_key'] = result;
                video_idx ++;
              });
            }
            this.video_uploading = true;
            await this.dataService.uploadFileToS3(videoResult, file.item).toPromise();
            this.video_uploading = false;
          } catch (e) {
            const ref = this.modalService.open(AlertPopupComponent, { centered: true });
            ref.componentInstance.title = 'Error';
            ref.componentInstance.msg = 'Error while uploading file "' + file.item.name + '". ' + e.statusText;
            await ref.result;
          }
        }
    }
  }

  get valid() {
    return this.description;
  }

}
