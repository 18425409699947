import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-choose-project',
  templateUrl: './choose-project.component.html',
  styleUrls: ['./choose-project.component.scss']
})
export class ChooseProjectComponent implements OnInit {

  projects: any[] = [];
  project: any = null;
  backurl = '/';
  sub: Subscription;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {

      if (params['backurl']) {
        this.backurl = params['backurl'];
      }

      this.dataService.getProjectList().subscribe((data) => {
        localStorage.setItem('user', JSON.stringify(data.user));
        this.dataService.user = data.user;
        this.projects = data.projects.filter((p: any) => {
          const containAtrium = p.systems.find(s => s.system_type == 'atrium');
          return !containAtrium;
        });
        this.project = this.projects[0];
      });
    });

  }

  findParams(object, key) {
    let value;
    let that = this;
    Object.keys(object).some(function (k) {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] && typeof object[k] === 'object') {
        value = that.findParams(object[k], key);
        return value !== undefined;
      }
    });
    return value;
  }

  chooseProject() {
    this.sub = this.dataService.getProjectConfiguration(this.project.project_id).subscribe(result => {
      Object.keys(result.schema).map(key => {
        const subparams = this.findParams(result.schema[key], 'subparams');
        if (typeof subparams === 'object' && Object.values(subparams).length === 0) {
          delete result.schema[key];
        }
      });
      this.storage.set('project', Object.assign({ configuration: result }, this.project));
      this.dataService.project = null;
      this.router.navigateByUrl(this.backurl);
    });
  }

  cancel() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
