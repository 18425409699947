import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './components/user/user.component';
import { LoginComponent } from './components/login/login.component';
import { ChooseProjectComponent } from './components/choose-project/choose-project.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { LeaderboardsComponent } from './components/leaderboards/leaderboards.component';
import { UserGuard } from './services/user.guard';
import { GamificationComponent } from './components/gamification/gamification.component';
import { GoalsComponent } from './components/gamification/goals/goals.component';
import { ProsnconsComponent } from './components/gamification/prosncons/prosncons.component';
import { TipsComponent } from './components/gamification/tips/tips.component';
import { CreditsComponent } from './components/gamification/credits/credits.component';
import { ShopComponent } from './components/gamification/shop/shop.component';
import { SurveyComponent } from './components/gamification/survey/survey.component';
import { PhasesComponent } from './components/gamification/phases/phases.component';
import { ResultsComponent } from './components/results/results.component';
import { ForumComponent } from './components/forum/forum.component';
import { ForumMessageComponent } from './components/forum-message/forum-message.component';
import { TipsEditComponent } from './components/gamification/tips/tips-edit.component';
import { ProsnconseditComponent } from './components/gamification/prosncons/prosnconsedit/prosnconsedit.component';
import { ContactComponent } from './components/contact/contact.component';
import { ParamGoalsComponent } from './components/gamification/goals/param-goals/param-goals.component';
import { ReportsComponent } from './components/results/reports/reports.component';
import { TripsComponent } from './components/results/trips/trips.component';
import { ScoresComponent } from './components/results/scores/scores.component';
import { VideosComponent } from './components/results/videos/videos.component';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { FactsComponent } from './components/gamification/facts/facts.component';
import { FactsEditComponent } from './components/gamification/facts/facts-edit.component';
import { AdminProjectsComponent } from './components/admin/projects/admin-projects.component';
import { AdminProjectComponent } from './components/admin/project/admin-project.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import {WebConfigurationGuard} from './services/web-configuration.guard';
import {DriversPositionComponent} from './components/position/drivers-position/drivers-position.component';
import {PositionsComponent} from './components/position/positions.component';
import {VehiclesPositionComponent} from './components/position/vehicles-position/vehicles-position.component';
import { IncidentsComponent } from './components/results/incidents/incidents.component';

const routes: Routes = [
  {
    path: 'TermsandConditions/IDreamsTermsandConditions.pdf',
    pathMatch: 'full',
    component: TermsandconditionsComponent,
  },
  {
    path: 'TermsandConditions/PrivacyPolicy',
    pathMatch: 'full',
    component: PrivacypolicyComponent,
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
  },
  {
    path: 'password',
    pathMatch: 'full',
    component: ResetPasswordComponent,
  },
  {
    path: 'choose-project',
    pathMatch: 'full',
    component: ChooseProjectComponent,
  },
  {
    path: '',
    component: UserComponent,
    canActivate: [UserGuard, WebConfigurationGuard],
    resolve: [UserGuard],
    canActivateChild: [UserGuard, WebConfigurationGuard],
    data: {
      roles: ['planner', 'projectleader'],
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DriversComponent,
      },
      // super admin routing start
      {
        path: 'admin',
        pathMatch: 'full',
        redirectTo: 'admin/projects',
      },
      {
        path: 'admin/projects',
        pathMatch: 'full',
        component: AdminProjectsComponent,
      },
      {
        path: 'admin/projects/:projectType/:id',
        pathMatch: 'full',
        component: AdminProjectComponent,
      },
      // super admin routing end
      {
        path: 'results',
        // pathMatch: 'full',
        component: ResultsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'trips',
            // component: ProsnconsComponent,
          },
          {
            path: 'reports',
            pathMatch: 'full',
            component: ReportsComponent,
          },
          {
            path: 'trips',
            pathMatch: 'full',
            component: TripsComponent,
          },
          {
            path: 'scores',
            pathMatch: 'full',
            component: ScoresComponent,
          },
          {
            path: 'videos',
            pathMatch: 'full',
            component: VideosComponent,
          },
          {
            path: 'incidents',
            pathMatch: 'full',
            component: IncidentsComponent,
          }
        ],
      },
      {
        path: 'position',
        // pathMatch: 'full',
        component: PositionsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'drivers',
          },
          {
            path: 'drivers',
            component: DriversPositionComponent,
          },
          {
            path: 'vehicles',
            component: VehiclesPositionComponent,
          }
        ]
      },
      {
        path: 'leaderboards',
        pathMatch: 'full',
        component: LeaderboardsComponent,
      },
      {
        path: 'contact',
        pathMatch: 'full',
        component: ContactComponent,
      },
      {
        path: 'gamification',
        // pathMatch: 'full',
        component: GamificationComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'prosncons',
            // component: ProsnconsComponent,
          },
          {
            path: 'prosncons',
            pathMatch: 'full',
            component: ProsnconsComponent,
          },
          {
            path: 'prosncons-edit',
            pathMatch: 'full',
            component: ProsnconseditComponent,
          },
          {
            path: 'tips',
            pathMatch: 'full',
            component: TipsComponent,
          },
          {
            path: 'tips-edit',
            pathMatch: 'full',
            component: TipsEditComponent,
          },
          {
            path: 'facts',
            pathMatch: 'full',
            component: FactsComponent,
          },
          {
            path: 'facts-edit',
            pathMatch: 'full',
            component: FactsEditComponent,
          },
          {
            path: 'goals',
            pathMatch: 'full',
            component: GoalsComponent,
          },
          {
            path: 'param-goals',
            pathMatch: 'full',
            component: ParamGoalsComponent,
          },
          {
            path: 'credits',
            pathMatch: 'full',
            component: CreditsComponent,
          },
          {
            path: 'shop',
            pathMatch: 'full',
            component: ShopComponent,
          },
          {
            path: 'survey',
            pathMatch: 'full',
            component: SurveyComponent,
          },
          {
            path: 'phases',
            pathMatch: 'full',
            component: PhasesComponent,
          },
        ],
      },
      {
        path: 'forum',
        pathMatch: 'full',
        component: ForumComponent,
      },
      {
        path: 'forum/message/:id',
        pathMatch: 'full',
        component: ForumMessageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
