import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {DataService} from '../../../../services/data.service';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {

  answers = [{
    text: ''
  }];
  answer = 0;
  question = '';
  explain = '';
  survey: any;
  q:any;

  constructor(private modal: NgbActiveModal,
              private dataService: DataService,
              private sanitizer: DomSanitizer,) {
  }

  ngOnInit(): void {
    console.log(this.q);
    if(this.q){
      this.question=this.q.questionText;
      if(this.q.choices)
      {
        this.answers=[];
        Object.values(this.q.choices).map(v=>{
          this.answers.push({text:v['choiceText']})
        })
      }
    }
  }

  closeModal() {
    this.modal.close();
  }


  save() {
    console.log(this.survey);
    let choices = {};
    let choice_order = [];
    let scoring = {};
    this.answers.map((ans, index) => {
      scoring['' + (index + 1)] = (index == this.answer ? 1 : 0);
      choices['' + (index + 1)] = {Display: ans['text']};
      choice_order.push('' + (index + 1));
    });

    this.dataService.addSurveyQuestion(this.survey.survey_id, choices, choice_order, scoring, this.explain).subscribe(data => {
      console.log(data);
    });
  }

  addA() {
    this.answers.push({
      text: ''
    });
  }

  deleteA(i: number) {
    if (this.answers.length > 1) {
      this.answers.splice(i, 1);
    }
  }
}
