import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent implements OnInit {

  image: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

  yes() {
    this.modal.close('yes');
  }

  no() {
    this.modal.close('no');
  }

}
