import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {StorageService} from './storage.service';
import {DOCUMENT} from '@angular/common';
import {Observable} from 'rxjs';


export interface TrackingObject {
  lat: number;
  lon: number;
  timestamp: number;
  user_id: number;
  license_plate: string;
}

export interface Vehicle {
  license_plate: string;
  vehicle_uuid: string;
}

@Injectable({
  providedIn: 'root'
})
export class TripService {

  constructor(
    private http: HttpClient,
    private config: AppConfig,
  ) {}

  liveTracking(projectId: number, userId?: number, communityId?: number, tagName?: string): Observable<TrackingObject[]> {
    const params: any = {
      project_id: projectId.toString(),
    };
    if (userId) {
      params.user_id = userId.toString();
    }
    if (communityId) {
      params.community_id = communityId.toString();
    }
    if (tagName) {
      params.tags = tagName;
    }
    return this.http.get<TrackingObject[]>(this.config.getEndpoint('trip/teltonika/live_tracking'), {
      params,
    });
  }

  liveTrackingVehicles(projectId: number, vehicleId?: string): Observable<TrackingObject[]> {
    const params: any = {
      project_id: projectId.toString(),
    };
    if (vehicleId) {
      params.vehicle_uuid = vehicleId;
    }
    return this.http.get<TrackingObject[]>(this.config.getEndpoint('trip/teltonika/live_tracking/vehicles'), {
      params,
    });
  }


  getListOfVehicles(projectId: number): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(this.config.getEndpoint('vehicle/teltonika'), {
      params: {
        project_id: projectId.toString(),
      }
    });
  }
}
