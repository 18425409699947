import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../../services/data.service";
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: 'app-driver-levels',
  templateUrl: './driver-levels.component.html',
  styleUrls: ['./driver-levels.component.scss']
})
export class DriverLevelsComponent implements OnInit, OnDestroy {

  sub: Subscription;

  userId: number;

  categories: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.sub = this.dataService.getUserLevels(this.userId, 'eco_efficiency').subscribe(result => {
      for (let key in result.eco_efficiency) {

        const cat = result.eco_efficiency[key];
        const levels = [];

        for (let i=0; i<cat.level; i++) {
          levels.push(100);
        }

        levels.push(100-cat.percentage_until_next_level);

        for (let i=cat.level+1; i<10; i++) {
          levels.push(0);
        }

        this.categories.push({name: key, levels});
      }
    });
  }

  ngOnDestroy(): void {
    this.sub && this.sub.unsubscribe();
  }

}
