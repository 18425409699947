import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../../services/data.service";
import { Subscription } from "rxjs";
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-driver-badges',
    templateUrl: './driver-badges.component.html',
    styleUrls: ['./driver-badges.component.scss']
})
export class DriverBadgesComponent implements OnInit {

    userId: number;

    sub: Subscription;

    params: string[] = [];
    subparams: any[][] = []
    columns: number[] = [];

    private system: string;

    domain = '';

    constructor(
        public activeModal: NgbActiveModal,
        private dataService: DataService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.system = this.dataService.project.systems[0].system_type;
        const schema = this.dataService.project.configuration.schema;

        this.route.queryParams.subscribe(query => {
            if (query.domain) {
                this.setDomain(query.domain);
            }
        });

        if (schema.eco_efficiency) {

            this.domain = this.domain || 'eco_efficiency';

            this.params = Object.keys(schema.eco_efficiency[this.system].params);

            for (const param in schema.eco_efficiency[this.system].params) {
                let colIdx = this.params.indexOf(param);
                if (colIdx == -1) {
                    colIdx = this.params.push(param) - 1;
                    this.columns[colIdx] = 0;
                }
                if (typeof this.columns[colIdx] == 'undefined') {
                    this.columns[colIdx] = 0;
                }

                Object.keys(schema.eco_efficiency[this.system].params[param].subparams).map(subparam => {
                    let rowIdx = this.columns[colIdx];
                    if (typeof this.subparams[rowIdx] == 'undefined') {
                        this.subparams[rowIdx] = Array(this.params.length);
                        this.subparams[rowIdx].fill(undefined);
                    }

                    if (typeof this.subparams[rowIdx][colIdx] == 'undefined') {
                        this.subparams[rowIdx][colIdx] = {
                            name: schema.eco_efficiency[this.system].params[param].subparams[subparam].name,
                            icon: schema.eco_efficiency[this.system].params[param].subparams[subparam].icon,
                            color: schema.eco_efficiency[this.system].params[param].color,
                            type: subparam,
                            awarded_date: {},
                        };
                        this.columns[colIdx] += 1;
                    }
                });
            }
        }

        if (schema.safety) {

            this.domain = this.domain || 'safety';

            this.params = Object.keys(schema.safety.params);

            for (const param in schema.safety.params) {
                let colIdx = this.params.indexOf(param);
                if (colIdx == -1) {
                    colIdx = this.params.push(param) - 1;
                    this.columns[colIdx] = 0;
                }
                if (typeof this.columns[colIdx] == 'undefined') {
                    this.columns[colIdx] = 0;
                }

                Object.keys(schema.safety.params[param].subparams).map(subparam => {
                    let rowIdx = this.columns[colIdx];
                    if (typeof this.subparams[rowIdx] == 'undefined') {
                        this.subparams[rowIdx] = Array(this.params.length);
                        this.subparams[rowIdx].fill(undefined);
                    }

                    if (typeof this.subparams[rowIdx][colIdx] == 'undefined') {
                        this.subparams[rowIdx][colIdx] = {
                            name: schema.safety.params[param].subparams[subparam].name,
                            icon: schema.safety.params[param].subparams[subparam].icon,
                            color: schema.safety.params[param].color,
                            type: subparam,
                            awarded_date: {},
                        };
                        this.columns[colIdx] += 1;
                    }
                });
            }
        }

        if (schema.functioning) {

            this.domain = this.domain || 'functioning';

            this.params = Object.keys(schema.functioning.params);

            for (const param in schema.functioning.params) {
                let colIdx = this.params.indexOf(param);
                if (colIdx == -1) {
                    colIdx = this.params.push(param) - 1;
                    this.columns[colIdx] = 0;
                }
                if (typeof this.columns[colIdx] == 'undefined') {
                    this.columns[colIdx] = 0;
                }

                Object.keys(schema.functioning.params[param].subparams).map(subparam => {
                    let rowIdx = this.columns[colIdx];
                    if (typeof this.subparams[rowIdx] == 'undefined') {
                        this.subparams[rowIdx] = Array(this.params.length);
                        this.subparams[rowIdx].fill(undefined);
                    }

                    if (typeof this.subparams[rowIdx][colIdx] == 'undefined') {
                        this.subparams[rowIdx][colIdx] = {
                            name: schema.functioning.params[param].subparams[subparam].name,
                            icon: schema.functioning.params[param].subparams[subparam].icon,
                            color: schema.functioning.params[param].color,
                            type: subparam,
                            awarded_date: {},
                        };
                        this.columns[colIdx] += 1;
                    }
                });
            }
        }

        this.sub = this.dataService.getUserBadges(this.userId).subscribe(result => {
            result.forEach(badge => {

                let colIdx = this.params.indexOf(badge.param_type);
                if (colIdx == -1) {
                    colIdx = this.params.push(badge.param_type) - 1;
                    this.columns[colIdx] = 0;
                }

                const params = this.domain === 'eco_efficiency' ? schema[this.domain][this.system].params : schema[this.domain].params;

                let rowIdx = Object.keys(params[badge.param_type].subparams).indexOf(badge.subparam_type);

                if (typeof this.subparams[rowIdx] == 'undefined') {
                    this.subparams[rowIdx] = Array(this.params.length);
                    this.subparams[rowIdx].fill(undefined);
                }

                if (typeof this.subparams[rowIdx][colIdx] == 'undefined') {

                    this.subparams[rowIdx][colIdx] = {
                        name: params[badge.param_type].subparams[badge.subparam_type].name,
                        type: badge.subparam_type,
                        awarded_date: {},
                    };
                    this.columns[colIdx] += 1;
                }

                if (typeof this.subparams[rowIdx][colIdx].level == 'undefined') {
                    this.subparams[rowIdx][colIdx].level = [];
                }
                this.subparams[rowIdx][colIdx].level.push(badge.level);
                this.subparams[rowIdx][colIdx].awarded_date[badge.level] = badge.awarded_date;
            });
        });
    }

    setDomain(d: string) {
        this.domain = d;
    }
}
