import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import {from, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {DataService} from '../../../../services/data.service';
import {CreateUser, ModalType, Order, ProjectTypeKey, Sort, User, UserProject} from '../../../../helpers/interfaces';
import {AdminUserModalComponent} from '../../modals/user-modal/admin-user-modal.component';
import {AlertPopupComponent} from '../../../_shared/alert-popup/alert-popup.component';

@Component({
    selector: 'admin-project-drivers',
    templateUrl: './project-drivers.component.html',
    styleUrls: ['./project-drivers.component.scss'],
})
export class ProjectDriversComponent implements OnInit, OnDestroy {
    project_id: string;
    drivers: UserProject[];
    sort: Sort = {
        field: 'project_name',
        order: Order.none,
    };
    isToastVisible = false;
    private projectType: ProjectTypeKey;
    private destroy$ = new Subject();


    constructor(private route: ActivatedRoute, private dataService: DataService, private modalService: NgbModal) {
    }

    ngOnInit(): void {
        // init project id
        this.project_id = this.route.snapshot.paramMap.get('id');
        this.projectType = this.route.snapshot.paramMap.get('projectType') as ProjectTypeKey;
        // fetch project drivers
        this.fetchProjectDrivers();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    fetchProjectDrivers() {
        this.dataService.getUsersByProjectType(this.projectType, this.project_id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((drivers) => {
                this.drivers = drivers;
            });
    }

    createUser(createUser: CreateUser, type: ModalType = ModalType.creating) {
        const project = {
            project_id: this.project_id,
            role: 'user',
        };
        this.dataService.createUserByProjectType({...createUser, project}, this.projectType)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((data) => {
                this.fetchProjectDrivers();
            });
    }

    openModal(modalType: string, driver?: User) {
        const modalRef = this.modalService.open(AdminUserModalComponent);
        modalRef.componentInstance.setModalType(modalType as ModalType);

        if ((modalType as ModalType) === ModalType.updating) {
            modalRef.componentInstance.setUserForm(driver);
        }

        from(modalRef.result)
            .pipe(
                catchError((err) => of('no')),
                takeUntil(this.destroy$)
            )
            .subscribe((result) => {
                if (result && result !== 'no') {
                    this.createUser(result as CreateUser, modalType as ModalType);
                }
            });
    }

    inviteUser(driver: UserProject) {
        this.dataService
            .inviteUser({
                project_id: this.project_id,
                user_short_ids: [driver.short_id],
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.isToastVisible = true;
                setTimeout(() => {
                    this.isToastVisible = false;
                }, 5000);
            });
    }

    getDrivers() {
        if (this.sort.order === Order.none) {
            return this.drivers;
        }

        return _.orderBy(this.drivers, [this.sort.field], [this.sort.order]);
    }

    getSafeTID(driver: User) {
        if (!driver.system || !driver.system.system_type) {
            return null;
        }

        const data = driver.system[driver.system.system_type];
        return data && typeof data === 'object' ? data.short_id : null;
    }

    getModalTitle(modalType: ModalType) {
        return modalType === ModalType.creating ? 'Add Project Driver' : 'Edit Project Driver';
    }

    toggleSort(field: string) {
        if (this.sort.order === Order.none || this.sort.field !== field) {
            this.sort = {
                field,
                order: Order.asc,
            };

            return;
        }

        switch (this.sort.order) {
            case Order.asc:
                this.sort.order = Order.desc;
                break;
            case Order.desc:
                this.sort.order = Order.none;
                break;
        }
    }

    getSortClass(field: string) {
        if (this.sort.field !== field || this.sort.order === Order.none) {
            return {
                'fa-sort': true,
                'text-muted': true,
            };
        }

        if (this.sort.order === Order.asc) {
            return {
                'fa-sort-down': true,
            };
        } else if (this.sort.order === Order.desc) {
            return {
                'fa-sort-up': true,
            };
        }
    }

    magicLink(driver: any) {
        console.log(driver);
        this.dataService.createMagicLink({
            system_type: this.projectType,
            short_id: driver.short_id,
        })
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((data) => {
                const ref = this.modalService.open(AlertPopupComponent, { centered: true });
                ref.componentInstance.title = 'Magic link created';
                ref.componentInstance.msg = data.magic_link;
            });
    }
}
