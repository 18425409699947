import {Pipe, PipeTransform} from '@angular/core';

const DOMAINS = {
  safety: 'Safety',
  learning: 'Learning',
  eco_efficiency: 'Eco Efficiency',
  functioning: 'Functioning'
};

@Pipe({
  name: 'domain'
})
export class DomainPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!DOMAINS[value as string]) {
      return value;
    }
    return DOMAINS[value as string];
  }

}
