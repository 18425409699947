<div class="gamecontainer" style="min-height: 100%; font-family: 'Open Sans';">
    <div class="top-nav-bar">
      <div class="top-nav-barmenu">
        <a *ngFor="let item of items" [routerLink]="item.url"
           [ngClass]="{disabled_link: item.disabled}"
              [routerLinkActive]="['active']">
         <span (click)="menuItemClick($event, item)">{{item.label | translate}}</span>
        </a>
      </div>
    </div>
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
  </div>
