import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriverBadgesComponent } from './driver-badges/driver-badges.component';
import { DriverLevelsComponent } from './driver-levels/driver-levels.component';
import { from, Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { ViewGroupComponent } from './view-group/view-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { AddDriverComponent } from './add-driver/add-driver.component';
import { StorageService } from '../../services/storage.service';
import { AlertPopupComponent } from '../_shared/alert-popup/alert-popup.component';
import { ConfirmationComponent } from '../_shared/confirmation/confirmation.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit, OnDestroy {
  public system: string;
  items = [];
  lists = [];
  indgroup: number = 1;
  type: string = 'Safety';

  pageSize = 8;
  p = 1;

  domains: string[] = [];
  domain: string;
  driverSub: Subscription;
  groupSub: Subscription;
  _subs: Subscription[] = [];
  groups = [];
  menuitems = ['1', '2', '3'];
  @ViewChild(ContextMenuComponent) public contextMenu: ContextMenuComponent;
  searchfield = '';
  sort = {
    drivers: {
      column: '',
      asc: 1,
      subfield: null,
    },
    group: {
      column: '',
      asc: 1,
      subfield: null,
    },
  };
  psychological_profile_type_dict = {};
  inviteonly = true;

  project_id: number;
  project_tags: string[] = [];

  driverLabel: string = 'All Drivers';

  set subs(value: Subscription) {
    this._subs.push(value);
  }

  constructor(
    private modal: NgbModal,
    private dataService: DataService,
    private contextMenuService: ContextMenuService,
    private storage: StorageService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.system = this.dataService.project.systems && this.dataService.project.systems.length ? this.dataService.project.systems[0].system_type : '';
    let proj = this.storage.get('project');
    this.psychological_profile_type_dict = proj.configuration.psychological_profile_type_dict;
    this.domains = Object.keys(proj.configuration.schema);
    this.domain = this.domains.find(d => d === 'safety') ?? this.domains[0];
    this.project_id = this.dataService.project.project_id;

    this.getProjectTags();
    this.getProjectUsers();
    this.getProjectGroups();
  }

  ngOnDestroy() {
    this.driverSub && this.driverSub.unsubscribe();
    this.groupSub && this.groupSub.unsubscribe();
    this._subs.forEach(s => s.unsubscribe());
  }

  getProjectTags() {
    this.dataService.getProjectTags(this.project_id).subscribe(project_tags => {
      this.project_tags = project_tags;
    });
  }

  getProjectUsers() {
    this.driverSub = this.dataService.getProjectUsers(true).pipe(
      map(result => result.map(d => {
        const dur = moment.duration((d.stats && d.stats.duration) || 0, 'seconds');
        d.duration = Math.floor(dur.as('hours')) && (Math.floor(dur.as('hours')) + 'h');
        return d;
      }))
    ).subscribe(result => {
      this.items = result;
      this.lists = this.items;
    });
  }

  getProjectGroups() {
    this.groupSub = this.dataService.getProjectGroups().subscribe(data => {
      this.groups = data;
    });
  }

  navigateToUrl(url) {
    if (url == 'individual') {
      if (this.indgroup != 1) {
        this.p = 1;
        this.getProjectUsers();
      }
      this.indgroup = 1;
    }
    else {
      if (this.indgroup != 2) {
        this.p = 1;
        this.getProjectGroups();
      }
      this.indgroup = 2;
    }
  }

  pageChanged($event: number) {
    this.p = $event;

  }

  badgesModal(user) {
    const ref = this.modal.open(DriverBadgesComponent, { size: 'xl', windowClass: 'badgeModal' });
    ref.componentInstance.userId = user.user_id;
  }

  levelsModal(user) {
    const ref = this.modal.open(DriverLevelsComponent, { size: 'lg' });
    ref.componentInstance.userId = user.user_id;
  }

  onContextMenu($event: MouseEvent, item: any) {
    this.contextMenuService.show.next({
      contextMenu: this.contextMenu,
      event: $event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();


  }

  viewProfile(item: any) {
    const ref = this.modal.open(ViewGroupComponent, { size: 'lg' });
    ref.componentInstance.item = item;
  }

  editProfile(item: any) {
    const ref = this.modal.open(this.indgroup == 1 ? AddDriverComponent : EditGroupComponent, { size: 'xl' });
    ref.componentInstance.item = item;
    ref.componentInstance.domain = this.domain;
    ref.result.then((data) => {
      if (this.indgroup == 1) {
        this.driverSub = this.dataService.getProjectUsers(true).pipe(
          map(result => result.map(d => {
            const dur = moment.duration((d.stats && d.stats.duration) || 0, 'seconds');
            d.duration = Math.floor(dur.as('hours')) && (Math.floor(dur.as('hours')) + 'h');
            return d;
          }))
        ).subscribe(result => {
          this.items = result;
        });
      }
      else {
        this.groupSub = this.dataService.getProjectGroups().subscribe(data => {
          this.groups = data;
        });
        this.driverSub = this.dataService.getProjectUsers(true).pipe(
          map(result => result.map(d => {
            const dur = moment.duration((d.stats && d.stats.duration) || 0, 'seconds');
            d.duration = Math.floor(dur.as('hours')) && (Math.floor(dur.as('hours')) + 'h');
            return d;
          }))
        ).subscribe(result => {
          this.items = result;
        });
      }
    });
    if (ref.componentInstance && ref.componentInstance.passDeactivated) {
      ref.componentInstance.passDeactivated.subscribe((deactivated) => {
        this.items = this.items.map(it => {
          if (it.user_id === item.user_id) {
            it.deactivated_since = deactivated;
          }
          return it;
        });
      });
    }
  }

  deleteProfile(item: any) {
    let ref = this.modal.open(ConfirmationComponent);
    let component = ref.componentInstance as ConfirmationComponent;
    component.title = `Remove Group`;
    component.message = `Are you sure you want to remove this group?`;
    ref.result.then(result => {
      if (result == 'yes') {
        this.groupSub = this.dataService.deleteGroup(item.id).pipe(
          switchMap(m => this.dataService.getProjectGroups())
        ).subscribe(data => {
          this.groups = data;
        }, err => {
          const ref = this.modal.open(AlertPopupComponent, { centered: true });
          ref.componentInstance.title = 'Error';
          ref.componentInstance.msg = err.error.message;
          return from(ref.result);
        });
      }
    }).catch(() => { });
  }

  addGroupPopup() {
    const ref = this.modal.open(EditGroupComponent, { size: 'xl' });

    ref.result.then((data) => {
      if (data === 'save') {
        this.ngOnInit();
      }
    })
  }

  filterItems(items) {
    return this.sortItems(this.sort.drivers.column, this.sort.drivers.asc, this.sort.drivers.subfield, items.filter(item => {
      if (this.inviteonly && !item.in_service) return false;
      item.trucker_type = item.profile.trucker_type;
      item.psychological_profile = item.profile.psychological_profile;
      return (item['first_name'] + ' ' + item['last_name']).toLowerCase().indexOf(this.searchfield.toLowerCase()) > -1;

    }));
  }

  filterGroups() {

    return this.sortItems(this.sort.group.column, this.sort.group.asc, this.sort.group.subfield, this.groups.filter(item => {
      return item['name'].toLowerCase().indexOf(this.searchfield.toLowerCase()) > -1;
    }));
  }

  addDriverPopup() {
    const ref = this.modal.open(AddDriverComponent, { size: 'lg' });
    ref.componentInstance.domain = this.domain;

  }

  setDomain(d) {
    this.domain = d;
  }

  getPages() {
    return Math.ceil((this.indgroup == 1 ? this.filterItems(this.lists) : this.filterGroups()).length / this.pageSize);
  }

  sortItems(column, asc, subfield, array) {
    return array.sort((a, b) => {
      if (!a[column]) {
        a[column] = '';
      }
      if (!b[column]) {
        b[column] = '';
      }
      if (subfield) {
        if (!a[column][subfield]) {
          a[column][subfield] = '';
        }
        if (!b[column][subfield]) {
          b[column][subfield] = '';
        }
      }
      if (subfield) {
        return a[column][subfield].localeCompare(b[column][subfield]) * asc;
      }
      return a[column].localeCompare(b[column]) * asc;
    });
  }

  toggleSort(arrayname, field: string, subfield: string) {
    if (this.sort[arrayname].column != field) {
      this.sort[arrayname].column = field;
      this.sort[arrayname].asc = 1;
    } else {
      this.sort[arrayname].asc *= -1;
    }
    this.sort[arrayname].subfield = subfield;
  }

  navigate(params: any) {
    this.router.navigate([], { queryParamsHandling: 'merge', queryParams: params });
  }

  setTargetAudience(audience) {
    if (audience) {
      this.navigate({ user_id: null, community_id: null, audience: audience });
    } else {
      this.navigate({ user_id: null, community_id: null });
    }
  }

  sortGroup(array) {
    return array.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  sortUser(array) {
    return array.sort((a, b) => {
      if (a.first_name + a.last_name < b.first_name + b.last_name) {
        return -1;
      }
      if (a.first_name + a.last_name > b.first_name + b.last_name) {
        return 1;
      }
      return 0;
    });
  }

  filterAll() {
    this.driverLabel = 'All Drivers';
    this.lists = this.items;
  }

  filterByGroups() {
    this.driverLabel = 'Groups';
    this.lists = [];
    this.items.forEach(item => {
      if (item.communities.length)
        this.lists.push(item);
    })
  }

  filterByGroup(group) {
    this.driverLabel = group.name;
    this.lists = [];
    this.items.forEach(item => {
      if (item.communities.length && group.name == item.communities[0].community_name)
        this.lists.push(item);
    })
  }

  filterByTags() {
    this.driverLabel = 'Tags';
    this.lists = [];
    this.items.forEach(item => {
      if (item.projects[0].tags.length)
        this.lists.push(item);
    })
  }

  filterByTag(tag) {
    this.driverLabel = tag.tag_name;
    this.lists = [];
    this.items.forEach(item => {
      if (item.projects[0].tags.length && tag.tag_name == item.projects[0].tags)
        this.lists.push(item);
    })
  }
}
