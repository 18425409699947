import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {take, takeUntil} from 'rxjs/operators';
import {DataService, ProjectMember} from '../../../../services/data.service';
import {User} from '../../../../helpers/interfaces';

@Component({
  selector: 'app-attach-project-leader-modal',
  templateUrl: './attach-project-leader-modal.component.html',
  styleUrls: ['./attach-project-leader-modal.component.scss']
})
export class AttachProjectLeaderModalComponent implements OnInit {

  form: UntypedFormGroup;
  projectLeaders: ProjectMember[] = [];
  existingLeaders: User[] = [];

  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private dataService: DataService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      project_leader: [null],
    });

    this.dataService.getProjectMembers('projectleader').pipe(
      take(1),
    ).subscribe((leaders) => {
      this.projectLeaders = leaders.filter((leader) => {
        // @ts-ignore
        return !this.existingLeaders.find((existingLeader) => existingLeader.user_id === leader.user_id);
      });
    });
  }

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }
    const { project_leader } = this.form.value;
    this.activeModal.close(project_leader);
  }
}
