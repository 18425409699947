<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">{{video.subparam}}</h4>
    <div class="d-flex">
      <img [src]="video.starred?'/assets/images/yellow-star.png':'/assets/images/star.png'" class="icon-img mr-2"
        width="24" height="24" style="cursor: pointer;" (click)="starringVideo(video)" />
      <!-- <a *ngIf="video_down_url" target="_blank" [href]="video_down_url" [download]="'video-'+video.video_uuid+'.mp4'">
        <img src="/assets/images/download.png" class="icon-img cursor-pointer" width="24" height="24" />
      </a> -->
      <!-- <a *ngIf="video_down_url" (click)="videoDownload()">
        <img src="/assets/images/download.png" class="icon-img cursor-pointer" width="24" height="24" />
      </a> -->
      <ng-container *ngIf="video_down_url">
        <img src="/assets/images/download.png" class="icon-img cursor-pointer" width="24" height="24"
          (click)="videoDownload()" *ngIf="!downloading;else loader" />
        <ng-template #loader>
          <div class="text-center blink">...</div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="modal-body pb-5" [ngClass]="{ 'p-0': step===1 }">
    <div class="group-content" [hidden]="step===1">
      <div class="flex-group">
        <div class="info-row">
          <span>Time of event: </span>
          <span>{{video.timeOfEvent}}</span>
        </div>
        <div class="info-row">
          <span>Driver: </span>
          <span>{{video.driver}}</span>
        </div>
        <div class="info-row">
          <span>Level: </span>
          <span>{{video.level}}</span>
        </div>
        <div class="info-row">
          <span>Duration: </span>
          <span>{{video.durationStr}}</span>
        </div>

        <div class="info-row">
          <div class="player-panel">
            <video id="video" style="margin:auto;" width="320" height="240" controls>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>

    <div id="map" *ngIf="step===1"></div>

    <div class="text-center" style="margin-top: 15px; margin-right: 15px;">
      <button *ngIf="step===0" class="btn text-warning font-weight-bold px-4" (click)="onNext()"><img
          src="assets/images/distance_mark.svg" width="40" height="40" />&nbsp;visualize trip
      </button>
      <button *ngIf="step===1" class="btn text-warning font-weight-bold px-4" (click)="onBack()"><img
          src="assets/images/left-arrow.svg" width="40" height="40" />&nbsp;Back to video
      </button>
    </div>
  </div>
</div>