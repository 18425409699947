import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  get(key: string): any {
    const value = localStorage.getItem(key);
    return JSON.parse(value);
  }

  set(key: string, value: any): StorageService {
    localStorage.setItem(key, JSON.stringify(value));
    return this;
  }

}
