import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

export type WebConfigurationStatus = 'enabled' | 'disabled';

export interface WebConfiguration {
  drivers: {
    status: WebConfigurationStatus;
  };
  leaderboards: {
    status: WebConfigurationStatus;
  };
  results: {
    status: WebConfigurationStatus;
    subsections: {
      trips: {
        status: WebConfigurationStatus;
      },
      scores: {
        status: WebConfigurationStatus;
      },
      reports: {
        status: WebConfigurationStatus;
      }
    }
  };
  gamification: {
    status: WebConfigurationStatus;
    subsections: {
      pros_cons: {
        status: WebConfigurationStatus;
      },
      coping_tips: {
        status: WebConfigurationStatus;
      },
      facts: {
        status: WebConfigurationStatus;
      },
      goals: {
        status: WebConfigurationStatus;
      },
      credits: {
        status: WebConfigurationStatus;
      },
      shop: {
        status: WebConfigurationStatus;
      },
      survey: {
        status: WebConfigurationStatus;
      },
      phases: {
        status: WebConfigurationStatus;
      }
    }
  };
  forum: {
    status: WebConfigurationStatus;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WebConfigurationService {
  private webConfigurationSubject = new BehaviorSubject<WebConfiguration | null>(null);
  webConfiguration$: Observable<WebConfiguration> = this.webConfigurationSubject.asObservable();

  constructor(private http: HttpClient, private config: AppConfig) {
  }

  getConfigurationWeb(projectId: string): Observable<WebConfiguration> {
    return this.http.get<WebConfiguration>(this.config.getEndpoint('project/configuration/web'), {
      params: {
        project_id: projectId,
      }
    }).pipe(
      map(response => {
        // response.leaderboards.status = 'disabled';
        return response;
      }),
      tap((response => {
      this.webConfigurationSubject.next(response);
    })));
  }

  getValue(): WebConfiguration | null {
    return this.webConfigurationSubject.getValue();
  }

  clear() {
    this.webConfigurationSubject.next(null);
  }
}
