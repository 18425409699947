import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../services/data.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  item: any;
  tabs = [{ display_name: '' }];
  sub: Subscription;
  create = true;
  imageFile = null;
  title = '';
  credits = '';
  category = '';
  description = '';
  imageChanged = false;
  selectedCat = 0;
  error = '';

  constructor(
    public activeModal: NgbActiveModal,
    private ds: DomSanitizer,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    console.log(this.tabs);
    if (!this.create) {
      this.title = this.item.title;
      this.credits = this.item.credits;
      this.description = this.item.description;
      if (this.item.imgurl) {
        this.imageFile = { url: this.item.imgurl };
      }
    }

  }

  isFileSizeAllowed(size) {
    let isFileSizeAllowed = false;
    if (size < 1000000) {
      isFileSizeAllowed = true;
    }
    return isFileSizeAllowed;

  }

  newImage($event: NgxFileDropEntry[]) {
    this.error = null;
    let droppedFile = $event[0];
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        if (this.isFileSizeAllowed(file.size)) {
          let item = {
            mode: 'add',
            name: fileEntry.name,
            file: null,
            url: null
          };
          this.addFile(fileEntry as FileSystemFileEntry, item).then(i => {
            console.log(i);
            this.imageFile = i;
            this.imageChanged = true;
          });
        } else {
          this.error = "Image size should be smaller than 2MB";
        }
      });

    }
  }

  private addFile(fileEntry: FileSystemFileEntry, item: any) {
    return new Promise(resolve => {
      fileEntry.file((f: File) => {
        item['file'] = f;
        let reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onloadend = () => {
          item['url'] = this.ds.bypassSecurityTrustUrl(reader.result + '');
          resolve(item);
        };
      });
    });
  }

  submitProduct() {
    if (this.create) {
      this.dataService.addProduct(null, this.title, this.description, this.credits, false, this.tabs[this.selectedCat]['key']).subscribe(data => {
        console.log(data);
        if (this.imageFile) {
          this.dataService.addProductImage(data['product_id'], this.imageFile.file).subscribe(data2 => {
            this.activeModal.close('create');
          });
        } else {
          this.activeModal.close('create');
        }

      });
    } else {
      //title and description will be ignored
      this.dataService.addProduct(this.item.product_id, this.title, this.description, this.credits, this.item.is_general_product, this.tabs[this.selectedCat]['key']).subscribe(data => {
        console.log(data);

        if (this.imageChanged) {
          if (this.imageFile) {
            this.dataService.addProductImage(data['product_id'], this.imageFile.file).subscribe(data2 => {
              this.activeModal.close('edit');
            });
          } else {
            this.dataService.deleteProductImage(data['product_id'], this.item.product_image.image_id).subscribe(data2 => {
              console.log(data2);
              this.activeModal.close('edit');
            });
          }
        } else {
          this.activeModal.close('edit');
        }

      });
    }
  }

  drop($event: DragEvent) {
    console.log($event);
  }

  removeImage() {
    if (this.item?.is_general_product) return;
    this.imageFile = null;
    this.imageChanged = true;
  }

  log($event: Event) {
    console.log($event);
  }

  setCat(i: number) {
    this.selectedCat = i;
  }
}
