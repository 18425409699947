import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-doughnut',
  templateUrl: './doughnut.component.html',
  styleUrls: ['./doughnut.component.scss']
})
export class DoughnutComponent implements OnInit {

  @Input() value: number;

  stroke = '0 100';

  constructor() { }

  ngOnInit(): void {
    this.stroke = `${this.value} ${100 - this.value}`;
  }

}
