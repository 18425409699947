import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Project, ProjectForm, ModalType, ProjectRequestPayload } from '../../../../helpers/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-admin-project-modal',
  templateUrl: './admin-project-modal.component.html',
  styleUrls: ['./admin-project-modal.component.scss'],
})
export class AdminProjectModalComponent implements OnInit {
  DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  DAYS_NUM = [0, 1, 2, 3, 4, 5, 6];
  LANGUAGES = ['eng', 'dut', 'deu', 'gre', 'por', 'eng-uk'];
  TRANSPORTS = ['car', 'van', 'bus', 'tram', 'train', 'truck'];
  DOMAINS = ['safety', 'learning', 'eco_efficiency', 'functioning'];
  KNOWNLANGUAGES = ['eng', 'dut', 'deu', 'gre', 'por', 'eng-uk', 'fra', 'pol'];

  project: Project;
  modalType: ModalType = ModalType.creating;
  projectForm: UntypedFormGroup;
  available_known_languages: number[] = [];
  loading = false;

  constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private dataService: DataService) {}

  ngOnInit(): void {
    // init project form
    if (this.modalType !== ModalType.updating) {
      this.setProjectForm({
        project_name: '',
        project_type: 'oseven',
        auto_accept_trip: 'Yes',
        auto_accept_trip_hours: 1,
        auto_reject_trip_hours_start: null,
        auto_reject_trip_hours_end: null,
        auto_reject_trip_days_of_week: [],
        available_known_languages: [],
        language: this.LANGUAGES[0],
        vehicle_type: this.TRANSPORTS[0],
        domains: [this.DOMAINS[0]],
      });
    }
    else {
      this.setProjectForm({
        ...this.project,
        auto_accept_trip: this.project.auto_accept_trip_hours === 0 ? 'Yes' : 'No',
      });
      this.dataService.getProjectDomains(this.project.project_id).subscribe((data: {project_id: number, schema: any}) => {
        this.projectForm.get('domains').setValue(Object.keys(data.schema).filter(key => data.schema[key].status === "enabled"));
      })
    }
  }

  setProjectForm(project: ProjectForm) {
    this.loading = true;
    this.projectForm = this.fb.group({
      ...project,
      auto_reject_trip_days_of_week: [this.DAYS_NUM.filter(num => !project.auto_reject_trip_days_of_week.includes(num)) || []],
      available_known_languages: [],
      domains: [project.domains || []],
      auto_reject_trip_hours_start: project.auto_reject_trip_hours_start || null,
      auto_reject_trip_hours_end:  project.auto_reject_trip_hours_end || null,
    });
    if (this.modalType === ModalType.updating)
      this.dataService.getAvailableLanguages(project.project_uuid, project.project_id, project.project_type)
        .subscribe(res => {
          if (res.available_known_languages) {
            res.available_known_languages.forEach(language => {
              switch (language) {
                case 'eng':
                  this.available_known_languages.push(0);
                  break;
                case 'dut':
                  this.available_known_languages.push(1);
                  break;
                case 'deu':
                  this.available_known_languages.push(2);
                  break;
                case 'gre':
                  this.available_known_languages.push(3);
                  break;
                case 'por':
                  this.available_known_languages.push(4);
                  break;
                case 'eng-uk':
                  this.available_known_languages.push(5);
                  break;
                case 'fra':
                  this.available_known_languages.push(6);
                  break;
                case 'pol':
                  this.available_known_languages.push(7);
                  break;
                default:
                  break;
              }
            });
            this.projectForm.patchValue({
              available_known_languages: this.available_known_languages
            });
          }
          this.loading = false;
        }, err => {
          console.log(err);
        })
  }

  setModalType(modalType: ModalType) {
    this.modalType = modalType;
  }

  setProject(project: Project) {
    this.project = project;
  }

  getModalTitle() {
    return this.modalType === ModalType.creating ? 'Create Project' : 'Edit Project';
  }

  handleSubmit() {
    if (this.projectForm.invalid) {
      return;
    }
    let available_languages: string[] = [];
    if (this.projectForm.value.available_known_languages)
      this.projectForm.value.available_known_languages.forEach(language => {
        switch (language) {
          case 0:
            available_languages.push('eng');
            break;
          case 1:
            available_languages.push('dut');
            break;
          case 2:
            available_languages.push('deu');
            break;
          case 3:
            available_languages.push('gre');
            break;
          case 4:
            available_languages.push('por');
            break;
          case 5:
            available_languages.push('eng-uk');
            break;
          case 6:
            available_languages.push('fra');
            break;
          case 7:
            available_languages.push('pol');
            break;
          default:
            break;
        }
      });
    const {
      project_id,
      project_name,
      project_type,
      project_uuid,
      auto_accept_trip,
      auto_accept_trip_hours,
      auto_reject_trip_days_of_week,
      available_known_languages,
      vehicle_type,
      language,
      domains,
    } = this.projectForm.value as ProjectForm;
    let {
      auto_reject_trip_hours_start,
      auto_reject_trip_hours_end
    } = this.projectForm.value as ProjectForm;
    const project: Project = {
      project_id: this.modalType === ModalType.creating ? undefined : project_id,
      project_name,
      project_display_name: project_name,
      project_type,
      project_uuid,
      auto_accept_trip_hours: auto_accept_trip === 'Yes' ? 0 : auto_accept_trip_hours,
      auto_reject_trip_hours_start,
      auto_reject_trip_hours_end,
      auto_reject_trip_days_of_week,
      available_known_languages,
      vehicle_type,
      language,
      domains,
    };
    project.available_known_languages = available_languages;
    project.auto_reject_trip_days_of_week = this.DAYS_NUM.filter(num => !project.auto_reject_trip_days_of_week.includes(num));
    if (auto_reject_trip_hours_start === '00:00') {
      auto_reject_trip_hours_start = null;
    }
    if (auto_reject_trip_hours_end === '00:00') {
      auto_reject_trip_hours_end = null;
    }
    if (project.auto_reject_trip_hours_start === '') {
      project.auto_reject_trip_hours_start = null;
    }
    if (project.auto_reject_trip_hours_end === '') {
      project.auto_reject_trip_hours_end = null;
    }
    this.activeModal.close(project);
  }
}
