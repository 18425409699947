<div class="popupcontainer">

  <div class="modal-header">
    <h4 class="modal-title"><span translate>{{ edit ? 'Driver Details' : 'Add Driver'}}</span></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pb-5">

    <div class="driver-details-wrapper" style="">
      <div *ngIf="edit" class="driver-details-column flex-1" style="flex: 1;">
        <div style="width: 100%;margin-top: 10px;" class="text-bold">ID</div>

        <div class="group-title"></div>
        <input disabled [(ngModel)]="user_id" class="form-control" placeholder="{{'User Id' | translate}}">
        <div class="group-title"></div>

        <div ngbDropdown class="d-inline-block custom_dropdown" style="width: 100%;">
          <button class="btn" ngbDropdownToggle style="width: 100%;"
            [disabled]="deactivated">{{getTransportTypeName(transport_type)|
            translate}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button *ngFor="let p of transport_profile; let i= index" (click)="transport_type=p" ngbDropdownItem><span
                translate>{{getTransportTypeName(p)}}</span></button>
          </div>
        </div>
        <div class="group-title"></div>
        <div ngbDropdown class="d-inline-block custom_dropdown" style="width: 100%;">
          <button class="btn" ngbDropdownToggle style="width: 100%;"
            [disabled]="deactivated">{{getPsychoProfName(behaviour_phase) |
            translate}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button *ngFor="let p of psychological_profile; let i= index" (click)="behaviour_phase=p"
              ngbDropdownItem><span translate>{{getPsychoProfName(p)}}</span></button>
          </div>
        </div>
        <div class="group-title"></div>
        <input disabled [(ngModel)]="group" class="form-control" placeholder="{{'Group' | translate}}">
        <div class="group-title"></div>
        <div ngbDropdown class="d-inline-block custom_dropdown" style="width: 100%;">
          <button class="btn" style="width: 100%;" (click)="show()"
            >{{!shown ? ('+ Add tag' | translate) : ('Hide' | translate)}}</button>
          <div *ngIf="shown" class="search-dropdown-menu" aria-labelledby="dropdownBasic1">
            <input [(ngModel)]="keyword" class="form-control" placeholder="{{'Search tags' | translate}}" (keyup)="search(keyword)" #input>
            <button *ngFor="let p of list; let i= index" (click)="addTag(p)" ngbDropdownItem><span translate>{{p}}</span></button>
            <button class="btn" style="width: 100%;" (click)="addTag(keyword)" [disabled]="keyword.length===0">Add Tag</button>
          </div>
        </div>
        <div class="group-title"></div>
        <div class="tag-button-form" *ngFor="let user_tag of user_tags">
          <div style="margin-bottom: 10px;">
            <button class="tag-button">{{user_tag}}<span class="icon-x" (click)="deleteTag(user_tag)">{{'X' | translate}}</span></button>
          </div>
        </div>
      </div>
      <div class="driver-details-column flex-2" style="flex: 2;">
        <div class="driver-contact-details text-bold" style="width: 100%;margin-top: 10px;"><span translate>CONTACT
            DETAILS</span></div>
        <div class="group-title"></div>
        <input [(ngModel)]="fname" class="form-control" placeholder="{{'First Name' | translate}}"
          [disabled]="deactivated">
        <div class="group-title"></div>
        <input [(ngModel)]="lname" class="form-control" placeholder="{{'Last Name' | translate}}"
          [disabled]="deactivated">
        <div style="display: flex;">

          <div style="display: flex;flex-direction: column; padding-right: 15px;">
            <div class="group-title"></div>
            <input [(ngModel)]="address1" class="form-control" placeholder="{{'Address Line 1' | translate}}"
              [disabled]="deactivated">
          </div>
          <div style="display: flex;flex-direction: column; padding-left: 15px;">
            <div class="group-title"></div>
            <input [(ngModel)]="address2" class="form-control" placeholder="{{'Address Line 2' | translate}}"
              [disabled]="deactivated">
          </div>
        </div>

        <div style="display: flex;">
          <div style="display: flex;flex-direction: column; padding-right: 15px;">

            <div class="group-title"></div>
            <input [(ngModel)]="city" class="form-control" placeholder="{{'City' | translate}}"
              [disabled]="deactivated">
          </div>
          <div style="display: flex;flex-direction: column; padding-left: 15px;">
            <div class="group-title"></div>
            <input [(ngModel)]="postal" class="form-control" placeholder="{{'Postal Code' | translate}}"
              [disabled]="deactivated">
          </div>
        </div>
        <div class="group-title"></div>
        <input [(ngModel)]="country" class="form-control" placeholder="{{'Country' | translate}}"
          [disabled]="deactivated">
        <div class="group-title"></div>
        <input disabled [(ngModel)]="email" class="form-control" placeholder="{{'Email' | translate}}">
        <div class="group-title"></div>
        <input [(ngModel)]="phone" class="form-control" placeholder="{{'Phone No' | translate}}"
          [disabled]="deactivated">
      </div>
      <div class="driver-details-column flex-3" style="flex: 2;">
        <div class="text-bold" style="width: 100%;margin-top: 10px;"><span translate>LOGIN DETAILS</span></div>
        <div class="group-title"></div>
        <input [value]="safeId" class="form-control" disabled placeholder="Id">
        <div class="group-title"></div>
        <input class="form-control" disabled value="************" />
      </div>
    </div>
    <div class="text-right" style="margin-top: 15px; margin-right: 15px;">
      <button class="btn text-white font-weight-bold px-4 ld-ext-right mr-2"
        [ngClass]="{'running': sub && !sub.closed, 'btn-danger': !deactivated, 'btn-primary':deactivated}"
        [disabled]="sub && !sub.closed" (click)="toggleActivate()">
        <span translate *ngIf="deactivated">
          REACTIVATE
        </span>
        <span translate *ngIf="!deactivated">
          DEACTIVATE
        </span>
        <div class="ld ld-ring ld-spin"></div>
      </button>
      <button class="btn btn-warning text-white font-weight-bold px-4 ld-ext-right"
        [ngClass]="{'running': sub && !sub.closed}" [disabled]="sub && !sub.closed || deactivated"
        (click)="saveDriver()"><span translate>SAVE</span>
        <div class="ld ld-ring ld-spin"></div>
      </button>
    </div>
  </div>

</div>
