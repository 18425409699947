<div class="position">
  <div class="position__filters">
    <form [formGroup]="form">
      <div class="toolbar justify-content-start">
        <h3 translate>Driver</h3>
        <div ngbDropdown class="custom_dropdown">
          <button class="btn" ngbDropdownToggle>{{ !driver ? 'All Drivers' : driver.system.teltonika && driver.system.teltonika.short_name && driver.system.teltonika.short_name.length > 0 ? driver.system.teltonika.short_name : (driver.first_name || '') + ' ' + (driver.last_name || '') }}</button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="selectUser(null)" *ngIf="driver">All</button>
            <button *ngFor="let driver of drivers" ngbDropdownItem (click)="selectUser(driver)">{{driver.first_name}} {{driver.last_name}}</button>
            <button *ngFor="let group of groups" ngbDropdownItem (click)="selectGroup(group)">
              {{ group.name }}
            </button>
            <button *ngFor="let project_tag of project_tags" ngbDropdownItem (click)="selectTag(project_tag)">
              {{ project_tag.tag_name }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="position__map">
    <div id="map"></div>
  </div>
</div>
