import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-project',
  templateUrl: './admin-project.component.html',
  styleUrls: ['./admin-project.component.scss'],
})
export class AdminProjectComponent implements OnInit {
  constructor() {}

  active = 1;

  ngOnInit(): void {}
}
