import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Pipe({
  name: 'authVideo'
})
export class AuthVideoPipe implements PipeTransform {

  constructor(
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) { }

  transform(ids: any[]): any {
    return new Observable<string | SafeUrl>((observer) => {
      observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      this.dataService.getVideo(ids[0], ids[1]).subscribe((response: Blob) => {
        let url = URL.createObjectURL(response);
        observer.next(this.sanitizer.bypassSecurityTrustUrl(url))
      });

      return {
        unsubscribe() { }
      };
    });
  }

}
