import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import {Language, LanguageCode, LanguageService} from '../../services/language.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  languages: Language[];
  lang: LanguageCode;

  token: string;
  form: UntypedFormGroup;
  requested: boolean;
  sub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private languageService: LanguageService
  ) {
    this.lang = this.languageService.getCurrentLanguage().code;
    this.languages = this.languageService.getListOfLanguages();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      password: '',
      confirmPassword: '',
    });

    this.route.queryParams.subscribe((params) => {
      this.token = params['access_code'];
    });
  }

  handleSubmit() {
    const { password, confirmPassword } = this.form.value as { password: string; confirmPassword: string };

    if (!this.token || password !== confirmPassword) {
      return;
    }

    this.requested = false;
    this.sub = this.authService.resetPassword({ password, token: this.token }).subscribe(() => {
      this.requested = true;
    });
  }

  handleLangChange(): void {
    this.languageService.switchLanguage(this.lang);
  }

  get matched() {
    const { password, confirmPassword } = this.form.value as { password: string; confirmPassword: string };

    return password && password === confirmPassword;
  }
}
