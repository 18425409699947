<div style="display: flex; flex:1;flex-direction: column;width: 100%;">
  <h2 translate>Contact us</h2>
  <div
    style="flex: 1; display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-start; position: relative">
    <div
      style="position: relative;background-color:white; z-index: 999; border-radius: 20px;box-shadow: 2px 2px 10px 3px rgba(0,0,0,0.75); padding: 20px;margin-left: 30px;margin-top: 50px;">
      <img src="/assets/images/positieve_versie.svg" alt="" class="my-3"><br>
      <b>SAFE-T Fleet and HR Prevention BVBA</b><br>
      Gistelse Steenweg 300<br>
      8200 Brugge<br>
      <br>
      Tel: +32 (0) 490 / 41.00.08<br>
      Email: <a href="mailto:contact@safet.be">contact@safet.be</a>
    </div>
    <div id="map" style="position:absolute;top:0;bottom: 0;left: 0;right: 0;"></div>
  </div>
</div>