import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../services/auth.service';
import {WebConfigurationService} from '../../services/web-configuration.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MenuItem} from '../../helpers/interfaces';
import {LanguageService} from '../../services/language.service';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [
    {
      label: 'Drivers',
      url: '/',
      exact: true,
      configProperty: 'drivers',
    },
    {
      label: 'Leaderboards',
      url: '/leaderboards',
      exact: false,
      configProperty: 'leaderboards',
    },
    {
      label: 'Results',
      url: '/results',
      exact: false,
      configProperty: 'results',
    },
    {
      label: 'Gamification',
      url: '/gamification',
      exact: false,
      configProperty: 'gamification',
    },
    {
      label: 'FORUM',
      url: '/forum',
      exact: false,
      configProperty: 'forum',
    },
    {
      label: 'Contact us',
      url: '/contact',
      exact: false,
    },
  ];

  private destroy$ = new Subject<void>();
  private system = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private configuration: WebConfigurationService,
    private dataService: DataService,
    // Don't remove languageService, it's used for selecting the language in constructor
    private languageService: LanguageService,
  ) {
    this.configuration.webConfiguration$
      .pipe(takeUntil(this.destroy$)).subscribe((config) => {
      if (!config) {
        return;
      }
      this.menuItems.forEach((item) => {
        const property = config[item.configProperty];
        if (!property) {
          item.disabled = false;
          return;
        }
        if (property.status === 'disabled') {
          item.disabled = true;
          return;
        }
        item.disabled = false;
      });
      this.system = this.dataService.project.systems[0].system_type;
      if (this.system === 'teltonika'){
        this.menuItems.splice(1, 0, {
          label: 'Position',
          url: '/position',
          exact: false,
          configProperty: 'position',
        });
      }
    });
  }

  public isMenuCollapsed = true;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  getProjectlogo() {
    const project = JSON.parse(localStorage.getItem('project'));

    if (!project) {
      return '/assets/images/logo_horizontaal_RGB.svg';
    }

    const { configuration } = project;

    return configuration.project_image ? configuration.project_image : '/assets/images/logo_horizontaal_RGB.svg';
  }

  isSuperAdmin() {
    return this.authService.isSuperAdmin();
  }

  getUserName() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.email;
  }

  menuItemClick(event: MouseEvent, item: MenuItem) {
    if (item.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

}
