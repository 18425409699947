<div class="resultspage w-100">
  <div class="top-nav-bar">
    <div class="top-nav-barmenu">
      <ng-container *ngFor="let item of menuItems">
        <a [routerLink]="item.url" [routerLinkActive]="['active']"
           [ngClass]="{disabled_link: item.disabled}"
           *ngIf="isShowingTab(item.label)"><span (click)="menuItemClick($event, item)" translate>{{item.label}}</span></a>
      </ng-container>
    </div>
  </div>
  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>

</div>
