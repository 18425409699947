import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../services/data.service';
import { EditProductComponent } from '../edit-product/edit-product.component';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss']
})
export class ViewProductComponent implements OnInit {

  item: any;
  tabs;
  sub: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    // console.log(this.item);
  }

  getCategoryDisplayName(category) {
    return this.tabs.find(obj => {
      return obj.key === category
    });
  }

  editProduct() {

    const ref = this.modal.open(EditProductComponent, { size: 'lg' });
    ref.componentInstance.item = this.item;
    ref.componentInstance.create = false;
    ref.componentInstance.tabs = this.tabs;

    ref.result.then((data) => {
      this.activeModal.close('edit');
    });
  }

  removeProduct() {
    this.dataService.deleteProduct(this.item.product_id).subscribe(data => {
      this.activeModal.close('remove');
    });
  }
}
