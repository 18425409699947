import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-prosncons',
  templateUrl: './prosncons.component.html',
  styleUrls: ['./prosncons.component.scss']
})
export class ProsnconsComponent implements OnInit {
  private system: string;

  domain = '';
  maxSubParamLength = 0;

  menu: { [key: string]: {} } = {};
  menuHeader = [];

  dataSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private languageService: LanguageService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;
    const schema = this.dataService.project.configuration.schema;

    this.route.queryParams.subscribe(query => {
      if (query.domain) {
        this.setDomain(query.domain);
      }
    });

    let proscons = [];
    if (schema.safety) {

      this.domain = this.domain || 'safety';

      this.menu['safety'] = [];
      const safety = this.menu['safety'];

      this.dataSub = this.dataService.getProsncons(this.system, this.domain, null, null, this.languageService.getCurrentLanguageCodeAPI()).subscribe(items => {
        const { pros, cons } = items;
        proscons = pros.concat(cons);

        for (const param in schema.safety.params) {
          const paramMenu = this.menuItem(param, schema.safety.params[param].name, 'safety', param, null, null, schema.safety.params[param].color);
          safety[param] = paramMenu;
          if (this.maxSubParamLength < Object.keys(schema.safety.params[param].subparams).length) {
            this.maxSubParamLength = Object.keys(schema.safety.params[param].subparams).length;
          }
          // safety.push(paramMenu);
          Object.keys(schema.safety.params[param].subparams).map(subparam => {
            const pros = proscons.filter((el) => {
              return el.param_type == param && el.subparam_type == subparam;
            })
            paramMenu.children.push(this.menuItem(subparam, schema.safety.params[param].subparams[subparam].name, 'safety', param, subparam, schema.safety.params[param].subparams[subparam].icon, '#f5a623', pros.length));
          });
        }
      });
    }

    if (schema.eco_efficiency) {

      this.domain = this.domain || 'eco_efficiency';

      this.menu['eco_efficiency'] = {};
      const eco = this.menu['eco_efficiency'];

      this.dataSub = this.dataService.getProsncons(this.system, this.domain, null, null, this.languageService.getCurrentLanguageCodeAPI()).subscribe(items => {
        const { pros, cons } = items;
        proscons = pros.concat(cons);

        for (const param in schema.eco_efficiency[this.system].params) {
          const paramMenu = this.menuItem(param, schema.eco_efficiency[this.system].params[param].name, 'eco_efficiency', param, null, null, schema.eco_efficiency[this.system].params[param].color);
          eco[param] = paramMenu;
          // eco.push(paramMenu);
          if (this.maxSubParamLength < Object.keys(schema.eco_efficiency[this.system].params[param].subparams).length) {
            this.maxSubParamLength = Object.keys(schema.eco_efficiency[this.system].params[param].subparams).length;
          }
          Object.keys(schema.eco_efficiency[this.system].params[param].subparams).map(subparam => {
            const pros = proscons.filter((el) => {
              return el.param_type == param && el.subparam_type == subparam;
            })
            paramMenu.children.push(this.menuItem(subparam, schema.eco_efficiency[this.system].params[param].subparams[subparam].name
              , 'eco_efficiency', param, subparam,
              schema.eco_efficiency[this.system].params[param].subparams[subparam].icon, '#f5a623', pros.length));
          });
        }
      });
    }

    if (schema.functioning) {

      this.domain = this.domain || 'functioning';

      this.menu['functioning'] = [];
      const functioning = this.menu['functioning'];

      this.dataSub = this.dataService.getProsncons(this.system, this.domain, null, null, this.languageService.getCurrentLanguageCodeAPI()).subscribe(items => {
        const { pros, cons } = items;
        proscons = pros.concat(cons);

        for (const param in schema.functioning.params) {
          const paramMenu = this.menuItem(param, schema.functioning.params[param].name, 'functioning', param, null, null, schema.functioning.params[param].color);
          // functioning.push(paramMenu);
          functioning[param] = paramMenu;
          if (this.maxSubParamLength < Object.keys(schema.functioning.params[param].subparams).length) {
            this.maxSubParamLength = Object.keys(schema.functioning.params[param].subparams).length;
          }
          console.log(schema.functioning.params[param].subparams);
          Object.keys(schema.functioning.params[param].subparams).map(subparam => {
            console.log(schema.functioning.params[param].subparams[subparam].icon);
            const pros = proscons.filter((el) => {
              return el.param_type == param && el.subparam_type == subparam;
            })
            paramMenu.children.push(this.menuItem(subparam, schema.functioning.params[param].subparams[subparam].name, 'functioning', param, subparam, schema.functioning.params[param].subparams[subparam].icon, '#f5a623', pros.length));
          });
        }
      });
    }
    this.menuHeader = Object.keys(this.menu[this.domain]);
  }

  private menuItem(label, name, domain, param = null, subparam = null, icon = null, color = '#f5a623', pros = 0) {
    let item = { label, name, children: [], query: { domain } as any, icon, color, pros };

    if (param !== null) {
      item.query.param = param;

      if (subparam !== null) {
        item.query.subparam = subparam;
      }
    }

    return item;
  }

  get params() {
    return this.menu[this.domain]
  }

  get domains() {
    return Object.keys(this.menu);
  }

  get paramHeader() {
    return Object.keys(this.menu[this.domain]);
  }

  get headers() {
    const keys = Object.keys(this.menu[this.domain]);
    return keys?.map((key) => this.menu[this.domain][key].name);
  }

  get maxSubParamCount() {
    return Array(this.maxSubParamLength).fill(1).map((x, i) => i);
  }

  setDomain(d: string) {
    this.domain = d;
  }
}
