import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { StorageService } from '../../services/storage.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-leaderboards',
  templateUrl: './leaderboards.component.html',
  styleUrls: ['./leaderboards.component.scss']
})
export class LeaderboardsComponent implements OnInit {
  type: string = 'Safety';
  items = [];
  domains = [
    {
      label: 'Safety',
      value: 'safety',
    },
    {
      label: 'Eco efficiency',
      value: 'eco_efficiency',
    },
    {
      label: 'Functioning',
      value: 'functioning',
    },
  ];
  psychological_profile = ['none', 'precontemplation', 'contemplation', 'preparation', 'action', 'maintenance'];
  psychological_profile_type_dict = {};

  groups = [];
  types = [];
  domainselectedindex = 0;
  alldrivers = { name: 'All drivers', id: -1 };
  selectedGroup = { name: 'All drivers', id: -1 };
  loading = false;
  periodsFull = [
    {
      label: 'Life time',
      value: 'life-time'
    },
    {
      label: 'Relative to yesterday',
      value: 'daily'
    },
    {
      label: 'Relative to last week',
      value: 'weekly'
    },
    {
      label: 'Relative to last month',
      value: 'monthly'
    },];
  periodsSafety = [
    {
      label: 'Life time',
      value: 'life-time'
    },
    {
      label: 'Relative to last week',
      value: 'weekly'
    },
    {
      label: 'Relative to last month',
      value: 'monthly'
    },];
  periodsFunctioning = [
    {
      label: 'Life time',
      value: 'life-time'
    },
    {
      label: 'Relative to last month',
      value: 'monthly'
    },];

  periods = this.periodsFull;
  selectedperiodindex = 0;
  psyprofindex = 0;

  project_id: number;
  project_tags: string[] = [];

  constructor(
    private dataService: DataService,
    private storage: StorageService,
  ) {

  }

  ngOnInit(): void {

    this.dataService.getProjectGroups().subscribe(data => {
      this.groups = [];
      this.groups = this.groups.concat(data);
      let truckerTypeDict = this.storage.get('project').configuration.trucker_type_dict;
      this.psychological_profile_type_dict = this.storage.get('project').configuration.psychological_profile_type_dict;

      this.types = [];
      Object.keys(truckerTypeDict).map(key => {
        this.types.push({ id: key, name: truckerTypeDict[key] });
      });
    });
    this.project_id = this.storage.get('project').configuration.project_id;
    this.dataService.getProjectTags(this.project_id).subscribe(project_tags => {
      project_tags.forEach(project_tag => {
        this.project_tags.push(project_tag.tag_name);
      });
    });
    this.refresh();
  }

  refresh() {
    this.loading = true;
    this.items = [];
    let o: Observable<any>;
    const system_type = this.storage.get('project').systems[0].system_type;
    let newDomains = [];
    this.domains.map((domain, i) => {
      if (typeof this.storage.get('project').configuration.schema[domain.value] !== 'undefined') {
        newDomains.push(domain)
      }
    });
    this.domains = newDomains;
    console.log(this.selectedGroup)
    if (this.selectedGroup.id == -1) {//all drivers

      o = this.dataService.getLeaderboards(system_type, this.domains[this.domainselectedindex].value, this.periods[this.selectedperiodindex].value, this.psyprofindex == 0 ? undefined : this.psychological_profile[this.psyprofindex]);
    }
     else if (this.selectedGroup.id == 0) {//tags
      o = this.dataService.getLeaderboardsTag(system_type, this.selectedGroup.name, this.domains[this.domainselectedindex].value, this.periods[this.selectedperiodindex].value, this.project_id);
     }
     else if (this.selectedGroup.id > 0) {//id is number
      o = this.dataService.getLeaderboardsCommunity(this.selectedGroup.id, this.domains[this.domainselectedindex].value, this.periods[this.selectedperiodindex].value, true, this.psyprofindex == 0 ? undefined : this.psychological_profile[this.psyprofindex]);
    } else {//id is string
      o = this.dataService.getLeaderboardsTruckerType(this.selectedGroup.id, this.domains[this.domainselectedindex].value, system_type, this.periods[this.selectedperiodindex].value, this.psyprofindex == 0 ? undefined : this.psychological_profile[this.psyprofindex]);
    }
    o.subscribe(data => {
      this.items = data;
      this.loading = false;
    });
  }

  floor(score: number) {
    return Math.floor(score);
  }

  abs(position_delta: number) {
    return Math.abs(position_delta);
  }

  domainUpdate(i: number) {
    this.domainselectedindex = i;
    this.selectedperiodindex = 0;
    switch (i) {
      case 0: {
        this.periods = this.periodsFull;
        break;
      }
      case 1: {
        this.periods = this.periodsFull;
        break;
      }
      case 2: {
        this.periods = this.periodsSafety;
        break;
      }
      default: {
        this.periods = this.periodsFull;
      }
    }
    this.refresh();
  }

  groupUpdate(g: any) {
    this.selectedGroup = g;
    this.refresh();
  }

  periodUpdate(i: number) {
    this.selectedperiodindex = i;
    this.refresh();
  }

  psyprofUpdate(i: number) {
    this.psyprofindex = i;
    this.refresh();
  }

  addTag(tag) {
    // this.items = [];
    // this.selectedGroup.name = tag;
    // this.dataService.getProjectGroups().subscribe(data => {
    //   this.groups = [];
    //   this.groups = this.groups.concat(data);
    //   this.dataService.addTagToLeaderboards(tag, this.domains[this.domainselectedindex].value, this.project_id, this.storage.get('project').systems[0].system_type,)
    //     .subscribe(data => {
    //       this.items = data;
    //       this.loading = false;
    //     })
    // })
    this.selectedGroup.id = 0;
    this.selectedGroup.name = tag;
    this.refresh();
  }
}
