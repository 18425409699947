<div class="container py-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active">Projects List</li>
    </ol>
  </nav>
	<div class="card p-4">
		<div class="d-flex align-items-center mb-2">
			<h2 class="m-0 mr-2">Projects</h2>

			<select class="form-control" style="width: 200px" [(ngModel)]="projectType">
				<option value="all">All</option>
				<option value="oseven">OSeven</option>
				<option value="teltonika">Teltonika</option>
			</select>

			<button class="btn btn-primary ml-auto" (click)="openModal('creating')">Create Project</button>
		</div>

		<table class="table table-striped">
			<thead>
				<tr>
					<th class="text-left cursor-pointer" (click)="toggleSort('project_name')">
						<span translate>Project Name</span>&nbsp;
						<i class="fa" [ngClass]="getSortClass('project_name')" aria-hidden="true"></i>
					</th>
					<th class="cursor-pointer" (click)="toggleSort('project_type')">
						<span translate>Type</span>&nbsp;
						<i class="fa" [ngClass]="getSortClass('project_type')" aria-hidden="true"></i>
					</th>
					<th>
						<span translate>ATA</span>
					</th>
					<th class="text-right"><span translate>ATA Time</span></th>
					<th><span translate>TR Hours</span></th>
					<th><span translate>TR Days</span></th>
					<th class="cursor-pointer" (click)="toggleSort('language')">
						<span translate>Language</span>&nbsp;
						<i class="fa" [ngClass]="getSortClass('language')" aria-hidden="true"></i>
					</th>
					<th class="cursor-pointer" (click)="toggleSort('vehicle_type')">
						<span translate>Transportation Type</span>&nbsp;
						<i class="fa" [ngClass]="getSortClass('vehicle_type')" aria-hidden="true"></i>
					</th>
					<th><span translate>Domains</span></th>
					<th></th>
				</tr>
			</thead>

			<tbody>
				<tr *ngFor="let project of getProjects(); let i = index">
					<td class="text-left">{{ project.project_name }}</td>
					<td>{{ project.project_type }}</td>
					<td>{{ project.auto_accept_trip_hours === 0 ? 'Yes' : 'No' }}</td>
					<td class="text-right">{{ project.auto_accept_trip_hours }}</td>
					<td>
						<span *ngIf="project.auto_reject_trip_hours_start && project.auto_reject_trip_hours_end">
							From {{ project.auto_reject_trip_hours_end }} until {{ project.auto_reject_trip_hours_start }}
						</span>
					</td>
					<td>{{ getDays(project.auto_reject_trip_days_of_week) }}</td>
					<td>{{ project.language }}</td>
					<td>{{ project.vehicle_type }}</td>
					<td>{{ domains_data[i] ? domains_data[i].join(', ') : '' }}</td>
					<td class="cursor-pointer">
						<div class="d-flex align-items-center">
							<i class="fa fa-pen mr-2" (click)="openModal('updating', project)"></i>
							<a routerLink="/admin/projects/{{project.project_type}}/{{ project.project_id }}">
								<i class="fa fa-users"></i>
							</a>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
