import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) { }

  transform(ids: any[], type: 'message' | 'pros_and_cons' | 'tip' | 'fact' | 'user' = 'message'): any {
    return new Observable<string | SafeUrl>((observer) => {
      switch (type) {
        case 'tip':
          observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
          this.dataService.getTipImage(ids[0], ids[1]).subscribe((response: Blob) => {
            let url = URL.createObjectURL(response);
            observer.next(this.sanitizer.bypassSecurityTrustUrl(url))
          });
          break;
        case 'pros_and_cons':
          observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
          this.dataService.getProsAndConsImage(ids[0], ids[1]).subscribe((response: Blob) => {
            let url = URL.createObjectURL(response);
            observer.next(this.sanitizer.bypassSecurityTrustUrl(url))
          });
          break;
        case 'fact':
          observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
          // this.dataService.getFactsImage(ids[0], ids[1]).subscribe((response: Blob) => {
          //   let url = URL.createObjectURL(response);
          //   observer.next(this.sanitizer.bypassSecurityTrustUrl(url))
          // });
          break;
        // case 'user':
        //   this.dataService.getPicture(ids[0]).subscribe(response => {
        //     observer.next(response);
        //   });
        //   return {unsubscribe(){}};
        default:
          this.dataService.getImage(ids[0], ids[1]).subscribe(response => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = function () {
              observer.next(reader.result as string);
            };
          });
      }


      return {
        unsubscribe() { }
      };
    });
  }

}
