import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sender'
})
export class SenderPipe implements PipeTransform {

  transform(value: any): string {

    if (value.first_name || value.last_name) {
      return `${value.first_name} ${value.last_name}`;
    }

    return value.email;
  }

}
