import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DataService } from "../../../services/data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationComponent } from "../../_shared/confirmation/confirmation.component";
import { AddFactComponent } from "./add-fact/add-fact.component";
import { map } from 'rxjs/operators';
import { VjsPlayerComponent } from '../../_shared/vjs-player/vjs-player.component';
import { AlertPopupComponent } from '../../_shared/alert-popup/alert-popup.component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-facts-edit',
  templateUrl: './facts-edit.component.html',
  styleUrls: ['./facts-edit.component.scss']
})
export class FactsEditComponent implements OnInit, OnDestroy {

  facts = [];

  domain = '';
  param = '';
  subparam = '';
  subparam_label = '';
  private system: string;
  dataSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private languageService: LanguageService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {

    this.system = this.dataService.project.systems[0].system_type;

    this.route.queryParams.subscribe(query => {
      this.domain = query.domain;
      this.param = query.param;
      this.subparam = query.subparam;
      this.subparam_label = this.dataService.project['configuration']['schema'][this.domain]?.params[this.param].subparams[this.subparam]['name'];
      this.updateData();
    });
  }

  ngOnDestroy() {
    this.dataSub && this.dataSub.unsubscribe();
  }

  updateData() {
    this.facts = [];
    this.dataSub = this.dataService.getFacts(this.system, this.domain, this.param, this.subparam, this.languageService.getCurrentLanguageCodeAPI()).subscribe(items => {
      this.facts = items;
    });
  }

  add() {
    const ref = this.modal.open(AddFactComponent, { size: 'xl' });
    const c = ref.componentInstance as AddFactComponent;
    c.domain = this.domain;
    c.param = this.param;
    c.subparam = this.subparam;
    c.subparam_label = this.subparam_label;

    ref.result.then(result => {
      if (result == 'saved')
        this.updateData();
    }).catch(result => {})
  }

}
