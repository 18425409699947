import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  title: string;
  message: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

  yes() {
    this.modal.close('yes');
  }

  no() {
    this.modal.close('no');
  }

}
