<div class="wrapper">
	<div class="form-wrapper">
		<img class="form-logo" src="/assets/images/logo_horizontaal_RGB.svg" alt="logo" />

		<form (submit)="handleSubmit()" [formGroup]="form">
			<h1 class="form-title" translate>Forgot Password?</h1>
			<input type="email" class="form-control" id="email" name="email" placeholder="E-mail" formControlName="email" />
			<p *ngIf="requested" class="text-success small text-center">
				You have received an email. <br />
				Follow the instructions to reset your password.
			</p>
			<button
				type="submit"
				class="btn btn-warning ld-ext-right"
				[disabled]="sub && !sub.closed"
				[ngClass]="{ running: sub && !sub.closed }"
			>
				<span class="text-uppercase" translate>Send Request</span>
				<span class="ld ld-ring ld-spin"></span>
			</button>
		</form>
	</div>

	<div class="language-wrapper">
		<select name="language" (change)="handleLangChange()" class="form-control" [(ngModel)]="lang">
			<option *ngFor="let l of languages" [ngValue]="l.code" translate>{{ l.label }}</option>
		</select>
	</div>
</div>
