import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { Project, CreateProject, Sort, Order, ModalType, ProjectType } from '../../../helpers/interfaces';
import { AdminProjectModalComponent } from '../modals/project-modal/admin-project-modal.component';
import {AlertPopupComponent} from '../../_shared/alert-popup/alert-popup.component';

@Component({
  selector: 'app-admin-projects',
  templateUrl: './admin-projects.component.html',
  styleUrls: ['./admin-projects.component.scss'],
})
export class AdminProjectsComponent implements OnInit {
  DOMAINS = ['safety', 'learning', 'eco_efficiency', 'functioning'];
  DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  DAYS_NUM = [0, 1, 2, 3, 4, 5, 6];

  domains_data = [];
  projects: Project[] = [];

  sort: Sort = {
    field: 'project_name',
    order: Order.none,
  };
  projectType = ProjectType.all;

  constructor(private dataService: DataService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.update();
  }

  update() {
    // fetch oseven projects
    this.dataService.getProjects('oseven').subscribe((projects) => {
      this.addProjects(
        projects.map((p) => ({
          ...p,
          project_type: 'oseven',
          auto_reject_trip_days_of_week: _.isEmpty(p.auto_reject_trip_days_of_week)
            ? []
            : p.auto_reject_trip_days_of_week,
        })),
      );
    });

    // fetch teltonika projects
    this.dataService.getProjects('teltonika').subscribe((projects) => {
      this.addProjects(
        projects.map((p) => ({
          ...p,
          project_type: 'teltonika',
          auto_reject_trip_days_of_week: _.isEmpty(p.auto_reject_trip_days_of_week)
            ? []
            : p.auto_reject_trip_days_of_week,
        })),
      );
    });
  }

  addProjects(projects: Project[]) {
    this.projects = this.projects.concat(
      projects.map((project) => ({
        ...project,
        auto_reject_trip_hours_start: project.auto_reject_trip_hours_start || null,
        auto_reject_trip_hours_end: project.auto_reject_trip_hours_end || null,
        language: project.language || null,
        vehicle_type: project.vehicle_type || null,
        auto_accept_trip_hours: project.auto_accept_trip_hours || 0,
      })),
    );

    projects.forEach((project) => {
      this.dataService.getProjectDomains(project.project_id).subscribe((data: {project_id: number, schema: any}) => {
        this.domains_data[this.getProjects().findIndex(item => item.project_id === data.project_id)] = Object.keys(data.schema).filter(key => data.schema[key].status === "enabled");
      });
    });
  }

  createProject(project: Project, type: ModalType = ModalType.creating) {
    const createProject: CreateProject = {
      ...project,
      auto_reject_trip_days_of_week: _.isEmpty(project.auto_reject_trip_days_of_week)
        ? null
        : project.auto_reject_trip_days_of_week,
    };

    this.dataService.createProject(createProject).subscribe((data) => {
      const project_id = data.project_id;
      const project_uuid = data.project_uuid;

      if (project_id) {
        if (type === ModalType.creating) {
          this.projects.push({
            ...project,
            project_id,
            project_uuid
          });
          const schema = {};
          this.DOMAINS.forEach(domain => {
            if (project.domains.includes(domain))
              schema[domain] = { status: 'enabled' };
            else
              schema[domain] = { status: 'disabled' };
          })
          this.dataService.updateProjectDomains(project_id, schema).subscribe(res => {
            this.dataService.getProjectDomains(project_id).subscribe((data: {project_id: number, schema: any}) => {
              this.domains_data[this.getProjects().findIndex(item => item.project_id === data.project_id)] = Object.keys(data.schema).filter(key => data.schema[key].status === "enabled");
            })
          });
        } else if (type === ModalType.updating) {
          this.projects = this.projects.map((p) => (p.project_id === project_id ? project : p));

          const schema = {};
          this.DOMAINS.forEach(domain => {
            if (project.domains.includes(domain))
              schema[domain] = { status: 'enabled' };
            else
              schema[domain] = { status: 'disabled' };
          })

          this.dataService.updateProjectDomains(project_id, schema).subscribe(res => {
            this.dataService.getProjectDomains(project_id).subscribe((data: {project_id: number, schema: any}) => {
              this.domains_data[this.getProjects().findIndex(item => item.project_id === data.project_id)] = Object.keys(data.schema).filter(key => data.schema[key].status === "enabled");
            })
          });
        }
      }
    }, (error) => {
      const ref = this.modalService.open(AlertPopupComponent, { centered: true });
      ref.componentInstance.title = 'Error';
      ref.componentInstance.msg = error.error.warning;
      return from(ref.result);
    } );
  }

  openModal(modalType: string, project?: Project) {
    const modalRef = this.modalService.open(AdminProjectModalComponent);
    modalRef.componentInstance.setModalType(modalType as ModalType);
    modalRef.componentInstance.setProject(project);

    from(modalRef.result)
      .pipe(catchError((err) => of('no')))
      .subscribe((result) => {
        if (result && result !== 'no') {
          this.createProject(result as Project, modalType as ModalType);
        }
      });
  }

  getProjects() {
    let projects = this.projects.filter(
      (project) => this.projectType === ProjectType.all || project.project_type === this.projectType,
    );
    if (this.sort.order === Order.none) {
      return projects;
    }

    return _.orderBy(projects, [this.sort.field], [this.sort.order]);
  }

  toggleSort(field: string) {
    if (this.sort.order === Order.none || this.sort.field !== field) {
      this.sort = {
        field,
        order: Order.asc,
      };

      return;
    }

    switch (this.sort.order) {
      case Order.asc:
        this.sort.order = Order.desc;
        break;
      case Order.desc:
        this.sort.order = Order.none;
        break;
    }
  }

  getSortClass(field: string) {
    if (this.sort.field !== field || this.sort.order === Order.none) {
      return {
        'fa-sort': true,
        'text-muted': true,
      };
    }

    if (this.sort.order === Order.asc) {
      return {
        'fa-sort-down': true,
      };
    } else if (this.sort.order === Order.desc) {
      return {
        'fa-sort-up': true,
      };
    }
  }

  getDays(days: number[]) {
    return (this.DAYS_NUM.filter(day => !days.includes(day)) || []).map((d) => this.DAYS[d]).join(', ');
  }
}
