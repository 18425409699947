import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../services/data.service';
import {Subscription} from 'rxjs';
import {StorageService} from '../../../services/storage.service';
import {TreeviewComponent, TreeviewItem} from 'ngx-treeview';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {

  sub: Subscription;
  title: any;
  description: any;
  invite_only: any;


  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
  ) {

  }

  ngOnInit(): void {

  }


  addGroup() {
    // console.log(this.title,this.description,this.invite_only);
    this.sub=this.dataService.addProjectGroups(this.title,this.description,this.invite_only, '').subscribe(data=>{//
      this.activeModal.dismiss();
      window.location.reload();

    })
  }
}
