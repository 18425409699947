import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss']
})
export class VideoPopupComponent implements OnInit {

  url = '';
  title = '';

  options = {
    autoplay: true,
    controls: true,
    sources: [{ src: this.url }]
  }

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
