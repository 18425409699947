import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from '../../helpers/interfaces';
import {Subject} from 'rxjs';
import {WebConfigurationService} from '../../services/web-configuration.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-gamification',
  templateUrl: './gamification.component.html',
  styleUrls: ['./gamification.component.scss']
})
export class GamificationComponent implements OnInit, OnDestroy {
  tab = 0;
  items: MenuItem[] = [
    {
      url: 'prosncons',
      label: 'Pros & Cons',
      configProperty: 'pros_cons',
    },
    {
      url: 'tips',
      label: 'Coping Tips',
      configProperty: 'coping_tips',
    },
    {
      url: 'facts',
      label: 'Facts',
      configProperty: 'facts',
    },
    {
      url: 'goals',
      label: 'Goals',
      configProperty: 'goals',
    },
    {
      url: 'credits',
      label: 'Credits',
      configProperty: 'credits',
    },
    {
      url: 'shop',
      label: 'Shop',
      configProperty: 'shop',
    },
    {
      url: 'survey',
      label: 'Survey',
      configProperty: 'survey',
    },
    {
      url: 'phases',
      label: 'Phases',
      configProperty: 'phases',
    },
  ];
  private destroy$ = new Subject<void>();

  constructor(private configuration: WebConfigurationService) {
    this.configuration.webConfiguration$
      .pipe(takeUntil(this.destroy$)).subscribe((config) => {
      if (!config) {
        return;
      }
      this.items.forEach((item) => {
        const property = config.gamification.subsections[item.url];
        if (!property) {
          item.disabled = false;
          return;
        }
        if (property.status === 'disabled') {
          item.disabled = true;
          return;
        }
        item.disabled = false;
      });
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  menuItemClick(event: MouseEvent, item: MenuItem) {
    if (item.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
}
