import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbTypeaheadSelectItemEvent
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import 'moment-duration-format';
import * as L from 'leaflet';
import { gridLayer, latLng, tileLayer } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';


type ChartCfg = {
  id: string,
  api: string,
  label: string,
  params: any
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  generalMenu = [];

  chartsCfg: ChartCfg[] = [];
  chartCfg: ChartCfg;
  chartData: any;

  from: NgbDate;
  until: NgbDate;

  system: string;

  chartId: string;
  defaultChartId: string;

  map: L.DrawMap;
  menu = [];

  trips: any[] = [];
  selected: any = null;

  routing: L.Routing.Control;

  period = 'day'

  colors = ['#f8be1f', '#23b231', '#dc3545', '#007bff'];

  options = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          // max: 100
        }
      }],
      xAxes: [{
        scaleLabel: {
          padding: 20
        },
        ticks: {
          autoSkip: true,
          maxRotation: 20,
          minRotation: 0
        }
      }]
    }
  };

  users: any[] = [];
  groups: any[] = [];
  search: (text: Observable<string>) => Observable<readonly any[]>

  target: any;
  targetChartParams: any;
  formatter: (item: any) => string;

  private _domain: string;
  private _type: string;

  domains: string[] = [];
  loader: boolean = true;

  project_id: number;
  project_tags: any[] = [];

  drivers_selected: boolean[] = [false];
  tags_selected: boolean[] = [false];

  selected_drivers: string[] = [];
  selected_tags: string[] = [];

  selected_drivers_num: number = 0;
  selected_tags_num: number = 0;

  company_loading = false;
  driver_loading = false;
  drivers_set_loading = false;
  tag_loading = false;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar
  ) { }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;
    this.project_id = this.dataService.project.project_id;

    this.formatter = (item) => item && item.label ? `${item.label}` : '';

    this.search = (text$: Observable<string>) => text$.pipe(
      // debounceTime(200),
      distinctUntilChanged(),
      // filter(term => term.length >= 2),
      map(term => {

        if (term.length < 2) {
          return [{prefix: 'Group', label: `All`, value: {community_id: null, user_id: null}}];
        }

        const users = this.users.filter(x=>x).map(user => ({prefix: 'User', label: `${ user.system.teltonika && user.system.teltonika.short_name && user.system.teltonika.short_name.length > 0 ? user.system.teltonika.short_name : (user.first_name || '') + ' ' + (user.last_name || '') }`, value: {user_id: user.user_id, community_id: null}}));
        const groups = this.groups.filter(x=>x).map(group => ({prefix: 'Group', label: `${group.name}`, value: {community_id: group.id, user_id: null}}));
        return [...groups, ...users].filter(item => item.label.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
      })
    )
    this.dataService.getProjectTags(this.project_id).subscribe(project_tags => {
      this.project_tags = project_tags;
    });

    this.dataService.getProjectConfigurationGeneral().pipe(
      tap(config => this.init(config)),
      switchMap(() => this.loadUsers()),
      switchMap(() => this.route.queryParams)
    ).subscribe(params => {
      this.loader = true
      this._type = params.type || 'general';
      this._domain = params.domain || this.domains[0];


      this.chartId = params.id || this.getDefaultChartId();

      this.menu.forEach(m => {
        m.children.forEach(mm => {
          if (mm.id == this.chartId) mm.collapsed = false;
          mm.children.forEach(mmm => {
            if (mmm.id == this.chartId) mm.collapsed = false;
          })
        })
      })

      this.chartCfg = this.chartsCfg.find(cfg => cfg.id == this.chartId);

      this.period = params.period || 'day';

      this.until = this.str2NgbDate(params.until, this.calendar.getToday());
      this.from = this.str2NgbDate(params.from, this.calendar.getPrev(this.calendar.getToday(), 'm', 1));

      if (!params.user_id && !params.community_id && !params.tags) {
        this.target = { prefix: 'Group', label: `All`, value: { community_id: null, user_id: null, tags: null } };
      } else if (params.user_id) {
        const user = this.users.find(u => u.user_id == params.user_id);
        this.target = { prefix: 'User', label: `${ user.system.teltonika && user.system.teltonika.short_name && user.system.teltonika.short_name.length > 0 ? user.system.teltonika.short_name : (user.first_name || '') + ' ' + (user.last_name || '') }`, value: { user_id: user.user_id, community_id: null, tags: null } };
      } else if (params.community_id) {
        const group = this.groups.find(g => g.id == params.community_id);
        this.target = { prefix: 'Group', label: `${group.name}`, value: { community_id: group.id, user_id: null, tags: null } };
      } else if (params.tags) {
        const tag = this.project_tags.find(g => g.tag_name == params.tags);
        this.target = { prefix: 'Tag', label: `${tag.tag_name}`, value: { community_id: null, user_id: null, tags: tag.tag_name } };
      }

      //   const chartParams = {
      //     ...this.chartCfg.params,
      //     ...this.targetChartParams,
      //     system_type: this.system,
      //     aggregate: this.period,
      //     from: this.ngbDate2Str(this.from),
      //     until: this.ngbDate2Str(this.until),
      //     project_id: this.dataService.project.project_id
      //   };

      // this.dataService.getChart(this.chartCfg.api, chartParams).subscribe(result => {
      //     this.updateData(result);
      //     this.loader = false;
      // });

      //   console.log(this.chartCfg);
      //   console.log(chartParams);
      //   console.log(chartParams);
    });
  }

  updateData(data) {
    this.chartData = data['data'];

    if (!data['data']) return;

    for (let j = 0; j < this.chartData['datasets'].length; j++) {
      this.chartData['datasets'][j]['backgroundColor'] = [];
      for (let i = 0; i < this.chartData['datasets'][j]['data'].length; i++) {
        this.chartData['datasets'][j]['backgroundColor'].push(this.colors[j % this.colors.length])
      }
    }
  }

  private str2NgbDate(str: string, def: NgbDate) {
    if (str) {
      const dd = str.split('-');
      return {
        year: parseInt(dd[0]),
        month: parseInt(dd[1]),
        day: parseInt(dd[2])
      } as NgbDate;
    }

    return def;
  }

  private ngbDate2Str(date: NgbDate) {
    return `${date.year}-${date.month}-${date.day}`;
  }

  private init(config: any) {

    const schema = this.dataService.project.configuration.schema;

    for (const key in config[this.system]) {
      const item = config[this.system][key];
      const id = `general/${key}`;

      if (!this.defaultChartId) {
        this.defaultChartId = id;
      }

      this.chartsCfg.push(this.chartCfgItem(id, 'general', item.display_name, { type: key }));
      this.generalMenu.push(this.menuItem(id, item.display_name));
    }

    if (schema.eco_efficiency) {
      this.domains.push('eco_efficiency');
      this.chartsCfg.push(this.chartCfgItem('eco', 'eco', 'eco_efficiency', {}));
      const eco = this.menuItem('eco', 'eco_efficiency');
      this.menu.push(eco);

      for (const param in schema.eco_efficiency[this.system].params) {
        const id = `eco/${param}`;

        this.chartsCfg.push(this.chartCfgItem(id, 'eco', param, { param_type: param }));
        const paramMenu = this.menuItem(id, param, true, null, schema.eco_efficiency[this.system].params[param].color);
        eco.children.push(paramMenu);

        Object.keys(schema.eco_efficiency[this.system].params[param].subparams).map(subparam => {
          const id = `eco/${param}/${subparam}`;
          this.chartsCfg.push(this.chartCfgItem(id, 'eco', subparam, { param_type: param, subparam_type: subparam }))
          paramMenu.children.push(this.menuItem(id, subparam, false, schema.eco_efficiency[this.system].params[param].subparams[subparam].icon));
        });
      }
    }

    if (schema.safety) {
      this.domains.push('safety');
      this.chartsCfg.push(this.chartCfgItem('safety', 'safety', 'safety', {}));
      const safety = this.menuItem('safety', 'safety');
      this.menu.push(safety);

      for (const param in schema.safety.params) {
        const id = `safety/${param}`;

        this.chartsCfg.push(this.chartCfgItem(id, 'safety', param, { param_type: param }));
        const paramMenu = this.menuItem(id, param, true, null, schema.safety.params[param].color);
        safety.children.push(paramMenu);
        Object.keys(schema.safety.params[param].subparams).map(subparam => {
          const id = `safety/${param}/${subparam}`;
          this.chartsCfg.push(this.chartCfgItem(id, 'safety', subparam, { param_type: param, subparam_type: subparam }))
          paramMenu.children.push(this.menuItem(id, subparam, false, schema.safety.params[param].subparams[subparam].icon));
        });
      }
    }

    if (schema.functioning) {
      this.domains.push('functioning');
      this.chartsCfg.push(this.chartCfgItem('functioning', 'functioning', 'functioning', {}));
      const functioning = this.menuItem('functioning', 'functioning');
      this.menu.push(functioning);

      for (const param in schema.functioning.params) {
        const id = `functioning/${param}`;

        this.chartsCfg.push(this.chartCfgItem(id, 'functioning', param, { param_type: param }));
        const paramMenu = this.menuItem(id, param, true, null, schema.functioning.params[param].color);
        functioning.children.push(paramMenu);

        Object.keys(schema.functioning.params[param].subparams).map(subparam => {
          const id = `functioning/${param}/${subparam}`;
          this.chartsCfg.push(this.chartCfgItem(id, 'functioning', subparam, { param_type: param, subparam_type: schema.functioning.params[param].subparams[subparam].category }))
          paramMenu.children.push(this.menuItem(id, subparam, false, schema.functioning.params[param].subparams[subparam].icon));
        });
      }
    }

  }

  navigate(params: any) {
    this.router.navigate([], { queryParamsHandling: 'merge', queryParams: params });
  }

  private chartCfgItem(id: string, api: string, label: string, params: any): ChartCfg {
    return { id, api, label, params };
  }

  private menuItem(id, label, collapsed = false, icon = null, color = '#f5a623') {
    return { id, label, collapsed, icon, color, children: [] };
  }

  setPeriod(period: string) {
    this.navigate({ period });
  }

  setFrom($event: NgbDate) {
    this.navigate({ from: `${$event.year}-${$event.month}-${$event.day}` });
  }

  setUntil($event: NgbDate) {
    this.navigate({ until: `${$event.year}-${$event.month}-${$event.day}` });
  }

  loadUsers() {
    return this.users.length ? of([]) :
      combineLatest([this.dataService.getProjectUsers(), this.dataService.getProjectGroups()]).pipe(
        tap(([users, groups]) => {
          this.users = users.filter(u => !u.deactivated_since);
          this.groups = groups;
        })
      );
  }


  setTarget($event: NgbTypeaheadSelectItemEvent) {
    console.log($event);
    if ($event) {
      this.navigate($event.item.value)
    } else {
      this.navigate({ user_id: null, community_id: null, tags: null })
    }
  }

  setTargetUser(user) {
    console.log(user);
    if (user) {
      this.target = { prefix: 'User', label: `${ user.system.teltonika && user.system.teltonika.short_name && user.system.teltonika.short_name.length > 0 ? user.system.teltonika.short_name : (user.first_name || '') + ' ' + (user.last_name || '') }`, value: { community_id: null, user_id: user.user_id, tags: null } };
      this.navigate({ user_id: user.user_id, community_id: null, tags: null });
    } else {
      this.navigate({ user_id: null, community_id: null, tags: null })
    }
  }

  setTargetTag(tag) {
    console.log(tag);
    if (tag) {
      this.target = { prefix: 'Tag', label: `${tag.tag_name}`, value: { community_id: null, user_id: null, tags: tag.tag_name } };
      this.navigate({ user_id: null, community_id: null, tags: tag.tag_name });
    } else {
      this.navigate({ user_id: null, community_id: null, tags: null });
    }
  }

  set type(value: string) {
    if (this._type != value) {
      this.navigate({ type: value, id: null, domain: null });
    }
  }

  get type(): string {
    return this._type;
  }

  get typeLabel(): string {
    return {
      'general': 'General Indicators',
      'domain': 'Domain Scores',
      'trips': 'Trips'
    }[this._type];
  }

  set domain(value: string) {
    if (this._domain != value) {
      this.navigate({ type: 'domain', id: null, domain: value });
    }
  }

  get domain(): string {
    return this._domain;
  }

  private getDefaultChartId() {
    if (this._type == 'general') {
      return this.defaultChartId;
    }

    return {
      'eco_efficiency': 'eco',
      'safety': 'safety',
      'functioning': 'functioning'
    }[this._domain];

  }

  public getReportUrl() {
    if (this.from && this.until) return `api/report/dhondt/dummy?from=${this.ngbDate2Str(this.from)}&to=${this.ngbDate2Str(this.until)}`;
    return `api/report/dhondt/dummy`;
  }

  generateReport(isUserReport = false) {
    if (this.from && this.until) {
      let body;
      if (!isUserReport) {
        this.company_loading = true;
        body = {
          'from_date': this.ngbDate2Str(this.from),
          'until_date': this.ngbDate2Str(this.until),
          'project_id': this.dataService.project.project_id
        };
      }
      else {
        this.driver_loading = true;
        body = {
          'from_date': this.ngbDate2Str(this.from),
          'until_date': this.ngbDate2Str(this.until),
          'project_id': this.dataService.project.project_id
        };
        if (this.target.value.user_id)
          body['user_id'] = this.target.value.user_id;
        else if (this.target.value.tags)
          body['tag'] = this.target.value.tags;
        else {
          this.company_loading = false;
          console.log('Please select require params.');
          return false;
        }
      }
      this.dataService.generateReport(body).subscribe((data: any) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = isUserReport ? "driverreport.pdf" : "companyreport.pdf";
        link.click();
        this.driver_loading = false;
      });
    } else {
      console.log('Please select require params.');
      return false;
    }
  }

  generateDriversReport() {
    this.drivers_set_loading = true;
    this.selected_drivers = [];
    for (let i=0; i<this.drivers_selected.length; i++) {
      if (this.drivers_selected[i])
        this.selected_drivers.push(this.sortUser(this.users)[i].user_id);
    }
    if (this.from && this.until && this.selected_drivers.length !== 0) {
      const body = {
        'from_date': this.ngbDate2Str(this.from),
        'until_date': this.ngbDate2Str(this.until),
        'project_id': this.dataService.project.project_id,
        'user_ids': this.selected_drivers
      };
      this.dataService.generateReport(body).subscribe((data: any) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "driverreport.pdf";
        link.click();
        this.drivers_set_loading = false;
      });
    } else {
      this.drivers_set_loading = false;
      console.log('Please select require params.');
      return false;
    }
  }

  generateTagsReport() {
    this.tag_loading = true;
    this.selected_tags = [];
    for (let i=0; i<this.tags_selected.length; i++) {
      if (this.tags_selected[i])
        this.selected_tags.push(this.sortGroup(this.project_tags)[i].tag_name);
    }
    if (this.from && this.until && this.selected_tags.length !== 0) {
      const body = {
        'from_date': this.ngbDate2Str(this.from),
        'until_date': this.ngbDate2Str(this.until),
        'project_id': this.dataService.project.project_id,
        'tags': this.selected_tags
      };
      this.dataService.generateReport(body).subscribe((data: any) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "driverreport.pdf";
        link.click();
        this.tag_loading = false;
      });
    } else {
      this.tag_loading = false;
      console.log('Please select require params.');
      return false;
    }
  }

  selectDriver(index) {
    this.drivers_selected[index] = !this.drivers_selected[index];
    if (this.drivers_selected[index])
      this.selected_drivers_num --;
    else
      this.selected_drivers_num ++;
  }

  selectTag(index) {
    this.tags_selected[index] = !this.tags_selected[index];
    if (this.tags_selected[index])
      this.selected_tags_num --;
    else
      this.selected_tags_num ++;
  }

  sortUser(array) {
    return array.sort((a, b) => {
      if (a['safe-t-id'] < b['safe-t-id']) { return -1; }
      if (a['safe-t-id'] > b['safe-t-id']) { return 1; }
      return 0;
    });
  }

  sortGroup(array) {
    return array.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
}
