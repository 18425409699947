<div class="toolbar">
  <h3 translate>Shop</h3>
  <div class="searchfield-wrapper">
    <input class="searchfield" [(ngModel)]="searchstring" placeholder="{{ 'Search for a' | translate: {keyword: 'Product' | translate} }}">
  </div>
</div>
<div class="products-info-action">
  <div class="products-count">{{ getCurrentProdsCount() }} <span translate>Products</span>
    <button class="btn btn-sm btn-warning btn-add text-white mr-0 px-2" (click)="addProduct()">
      <i class="fa fa-plus"></i>
    </button>
  </div>
</div>
<div class="content-wrapper">
  <div class="tabcontainer">
    <strong class="tabcontainer-label" translate>Shop per category</strong>
    <div class="tab {{activecategory==null?'activetab':''}}" (click)="getProds(null)"><span translate>All Products</span> ({{allprods.length}})
    </div>
    <div *ngFor="let t of tabs" class="tab {{activecategory==t?'activetab':''}}" (click)="getProds(t)">
      {{t['display_name'] | translate}} ({{counts[t['key']] ? counts[t['key']] : '0'}})
    </div>
  </div>
  <div class="productcontainer" *ngIf="(!sub || sub.closed); else loader">
    <div *ngIf="getFilteredProducts().length==0" style="width: 100%; text-align: center; margin-top: 10px;" translate>No items to display</div>
    <div class="productitem" *ngFor="let p of getFilteredProducts()">
      <div class="productimagecontainer">
        <img [src]="getImageSrc(p)" class="productimage">
        <div (click)="viewItem(p)" class="detailsbutton">
          <span translate>View Details</span>
        </div>
      </div>
      <div class="produxtdesccontainer">
        <span class="productname">{{p.title}}</span>
        <span class="productprice">{{p.credits}} <span translate>Credits</span></span>
      </div>
    </div>
  </div>


  <ng-template #loader>
    <div *ngIf="sub && !sub.closed" class="text-center blink" style="width: 100%"><span translate>Loading</span>...</div>
  </ng-template>
</div>
