import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {tileLayer, TileLayer} from 'leaflet';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {from, Subject} from 'rxjs';
import {TrackingObject, TripService, Vehicle} from '../../../services/trip.service';
import {DataService} from '../../../services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {takeUntil} from 'rxjs/operators';
import {AlertPopupComponent} from '../../_shared/alert-popup/alert-popup.component';

@Component({
  selector: 'app-vehicles-position',
  templateUrl: './vehicles-position.component.html',
  styleUrls: ['./vehicles-position.component.scss']
})
export class VehiclesPositionComponent implements OnInit, OnDestroy, AfterViewInit {
  private map: L.DrawMap;
  private tiles: TileLayer[];
  private leafletOptions: { layers: TileLayer[]; zoom: number };
  form: UntypedFormGroup;
  vehicles: Vehicle[];
  vehicle: Vehicle = null;
  destroy$ = new Subject();

  constructor(private tripService: TripService, private fb: UntypedFormBuilder, private dataService: DataService,
              private modal: NgbModal) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      user_id: [''],
    });
    this.tripService.getListOfVehicles(this.dataService.project.project_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.vehicles = result;
      }, error => {
        const ref = this.modal.open(AlertPopupComponent, { centered: true });
        ref.componentInstance.title = 'Error';
        ref.componentInstance.msg = error.error.message;
        return from(ref.result);
      });
    this.loadTracking();
  }

  loadTracking() {
    const projectId = this.dataService.project.project_id;
    let vehicleId = null;
    if (this.vehicle) {
      vehicleId = this.vehicle.vehicle_uuid;
    }
    this.tripService.liveTrackingVehicles(projectId, vehicleId)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(result => {
        this.removeMarkers();
        if (!Array.isArray(result)) {
          this.attachMarkers([result]);
        } else {
          this.attachMarkers(result);
        }
        this.boundMap();
      }, (error) => {
        const ref = this.modal.open(AlertPopupComponent, { centered: true });
        ref.componentInstance.title = 'Error';
        ref.componentInstance.msg = error.error.message;
        return from(ref.result);
      });
  }

  ngAfterViewInit(): void {
    this.tiles = [
      tileLayer('https://imobwww.uhasselt.be/osm_tiles/{z}/{x}/{y}.png', {
        maxZoom: 19,
        // tileSize: 512,
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
      }),
    ];

    this.leafletOptions = {
      layers: [this.tiles[0]],
      zoom: 9,
    };
    this.map = L.map('map', this.leafletOptions).setView(
      new L.LatLng(
        51.143717, 4.616845),
      14,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectUser(vehicle: Vehicle) {
    this.vehicle = vehicle;
    this.loadTracking();
  }

  private attachMarkers(result: TrackingObject[]) {
    // need to add markers to the map using the result
    result.forEach((item, index) => {
      const trace_points = [];
      trace_points.push([item.lat, item.lon]);
      const popupContent = `
    <div class="marker-popup-position">Vehicle: ${item.license_plate}<br>Time: ${new Date(item.timestamp * 1000).toLocaleString()}</div>`;
      L.marker(trace_points[0], {
        icon: L.icon({
          iconUrl: 'assets/marker/trip_end.svg',
          iconSize: [45, 50],
          iconAnchor: [23, 50],
        }),
        draggable: false,
      }).bindPopup(
        popupContent,
        {}
      ).addTo(this.map);
    });
  }

  private boundMap() {
    const group = L.featureGroup([]);
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        group.addLayer(layer);
      }
    });
    this.map.fitBounds(group.getBounds(), {
      animate: true,
      maxZoom: 16,
    });
  }

  private removeMarkers() {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });
  }
}
