import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {

  stroke = 4;
  color: 'rgb(242, 161, 0)';
  radius = 40;
  normalizedRadius;
  _circumference;
  strokeDasharray;
  strokeDashoffset;

  @Input('percent') percent: number = 100;

  constructor() {
    this.normalizedRadius = this.radius - this.stroke * 2;
    this._circumference = this.normalizedRadius * 2 * Math.PI;
    this.strokeDasharray = `${this._circumference} ${this._circumference}`;
    this.strokeDashoffset = `${this._circumference}`;
   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.percent.currentValue ) {
      this.setProgress(changes.percent.currentValue);
    }
  }

  setProgress(percent) {
    const offset = this._circumference - percent / 100 * this._circumference;
    this.strokeDashoffset = offset;
  }
}
