import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../services/data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.scss']
})
export class ViewGroupComponent implements OnInit {

  item: any;

  sub: Subscription;

  items = [];

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(){
    this.sub = this.dataService.getGroupUsers(this.item.id).subscribe(data => {
      this.items = data;
    });
  }
  removeDriverFromGroup(item: any) {
    this.sub=this.dataService.removeGroupMembers(this.item.id, item.user_id).subscribe(data => {
      this.refresh();

    });
  }
}
