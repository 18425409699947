<div class="container">
  <div class="content-header">
    <div class="text-center">
      <strong class="title text-decoration-underline">ANNEX A</strong>
    </div>
    <div class="description pl-40">
      Below You will find Our detailed Personal Data Privacy Statement. This attachment is an integral part of the
      “Terms & Conditions of Use”.
    </div>
  </div>
  <ul class="content-list">
    <li>
      <strong>1. Collection and processing of Your personal data</strong>
      <ul class="sub-list">
        <li class="row">
          <div class="col-md-1 list-item-label">1.1</div>
          <div class="col-md-11">We have created this Privacy Policy for the collection and management of personal data
            to
            inform You that Your personal data will be treated properly. The personal data We process upon
            Your registration to the Application (e.g. email) or upon the acceptance of the invitation that
            You have received to use the Application (e.g. email), is the minimum information required for
            the provision of professional Application on Our part. Please note that the following information
            collected, is provided to i-DREAMS only with Your explicit consent given before the installation
            and use of the Application and after You obtain knowledge of these Terms.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">1.2</div>
          <div class="col-md-11">In the case You sign in with a username and password, You will also be requested to
            provide a
            password. This information will not be re-used for any purpose which does not fall within the
            scope of Our Application. Please note that You are solely responsible for keeping Your password
            and any Sign in emails to Your account confidential and that You are fully responsible for any
            activities in Your account.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">1.3</div>
          <div class="col-md-11">The type of data that We may collect with Your consent, which is given herewith, is
            listed below.</div>
        </li>
      </ul>
    </li>
    <li>
      <strong>2. Collection of Your Primary Data</strong>
      <p>
        <b>“Primary Data”:</b> Primary Data refers to the data recorded by the Application from the smartphone sensors or the operating system of the smartphone. 
        Primary Data is provided to i-DREAMS by the manufacturers and developers of the smartphone operating system (Apple: iOS, Google: Android).
The Application may collect and assess Primary Data (including Location data) to enable the automatic Trip recording even when the Application is closed
or not in use. <b>Specifically, the Application is activated by data received from the Android and iOS operating systems to verify if the User is in a driving
status. This process is called “Driving Detection”. If the Application verifies that the User is in driving status, then the recording of Primary Data begins. 
Otherwise, data collection is interrupted, until the next time that the Application is activated. In case that the User is not in a driving status the data 
collected during “Driving Detection” is not sent to the i-DREAMS platform and is finally deleted from the smartphone device.</b> Only the Primary Data that 
is recorded during a Trip is sent to the i-DREAMS platform. This data is stored by i-DREAMS in servers within the European Union utilizing recognized and 
acknowledged technology providers (indicatively Amazon, Microsoft).
<b>The Primary Data are the following:</b>
      </p>
      <ul class="list-style-disc">
        <li>
          Date/Time: The Recording date/time/timestamp for Primary Data.
        </li>
        <li>
          GPS Data: geographic longitude, geographic latitude and altitude of the vehicle position,
          horizontal and vertical accuracy of the GPS recording, vehicle movement speed, vehicle
          movement direction.
        </li>
        <li>
          Accelerometer data: Acceleration values on the three local axes of the smartphone, including
          and excluding the acceleration of gravity.
        </li>
        <li>Gyroscope data: Angular velocity values on the three local axes of the smartphone.</li>
        <li>Activity Data (Motion and Fitness / Activity Recognition): Data provided by Google companies
          related with the activity of the User as it is recognized by the smartphone operating system
          (indicatively but not limited to, walking, stopping, driving).</li>
        <li>Values of the angles formed by the local axes of the smartphone to the North and to the
          horizontal plane (ground).</li>
        <li>Rate of change of the angles formed by the local axes of the smartphone to the North and to
          the horizontal plane (ground) versus time.</li>
        <li>Screen State (Android only): Data on screen activation (activated or deactivated) as an
          additional criterion for identifying mobile use. It is noted that i-DREAMS does not have any
          access to the content of the smartphone screen.</li>
        <li>Smartphone device data. It is provided by Google and includes indicatively but not limited to,
          the manufacturer’s brand, the device model, the name and version of the operating system
          and the type of smartphone sensors (e.g., accelerometer, gyroscope, compass, etc.).</li>
        <li>Personal messages (via the messaging function)</li>
        <li>Answers to knowledge tests and quizzes</li>
        <li>Credits earned</li>
        <li>Purchases from the shop, bought with credits</li>
        <li>Engagement in goals</li>
        <li>App usage metrics</li>
        <li>Push Notification Token Data: A unique alphanumeric code produced by Google companies,
          which is sent to a smartphone. This code is associated with a single and only installation of the
          Application. In case of uninstalling and reinstalling of the Application by the User, a new code
          is generated indicating the day and time that it was generated.</li>
        <li>WiFi: Recording of the smartphone WiFi operation.</li>
        <li>Data on the date/time of Sign In/Out to/from the Application.</li>
        <li>Data on date/time of activation/deactivation of the smartphone Location Services/GPS.</li>
        <li>Data on date/time of activation/deactivation of the Activity Data (Motion and Fitness / Activity
          Recognition).</li>
        <li>Data on date/time of activation/deactivation of the Compass Calibration (iOS only).</li>
        <li>Data on date/time of activation/deactivation of the smartphone device.</li>
        <li>Data on date/time of activation/deactivation of the Application Recording through its settings.</li>
        <li>Data on date/time of activation/deactivation of the Background Refresh (iOS only).</li>
        <li>Data on date/time of activation/deactivation of the smartphone WiFi.</li>
        <li>Technical information regarding the functionalities of the Application.</li>
        <li>Data regarding the Power Mode/Battery settings of the smartphone device.</li>
        <li>Data regarding the paired Bluetooth devices.</li>
      </ul>
    </li>
    <li>
      <strong>3. The purpose of collecting and processing of Your Primary Data.</strong>
      <p>The purposes of collecting and processing of “Primary Data” are the following:</p>
      <ul class="list-style-disc">
        <li>The registration of the Users, the assignment of their data in the i-DREAMS platform, the
          calculation of the Driving Behaviour and Vehicle Use Data and the calculation of the
          Gamification Data and to ensure the proper functioning of the O7SDK and i-DREAMS platform
          for the Users.</li>
        <li>To provide technical support to the Users, if and when this is requested.</li>
        <li>To perform statistical analysis and to optimize the models and algorithms (indicatively the
          driving behaviour models and the machine learning algorithms) in the i-DREAMS platform, also
          improving the application for the Users.</li>
      </ul>
      <p>Specifically, regarding data which pertain to the longitude and latitude of the GPS, these are stored
        and kept in the i-DREAMS platform mainly for the following purposes:</p>
      <ul class="list-style-disc">
        <li>The representation of each Trip and the risk related driving events (indicatively harsh brakes,
          harsh accelerations, harsh cornering, mobile phone use while driving, speed limit violation) on
          a map for each Trip. This is a coaching feature that helps the Users to identify their weak points
          and improve their driving behaviour.</li>
        <li>The visualization of road sections with frequent risky driving behaviours for each User. This is a
          coaching feature that helps the Users to focus on areas that they have the highest frequency
          of risky driving behaviours.</li>
        <li>Mapping the risk of different sections of the road network as a whole and for different types of
          risk considering data from all the Users.</li>
        <li>The determination of the status of the User (driver of the vehicle or passenger in any other
          vehicle) per Trip.</li>
        <li>The development, evaluation and optimization of the map matching algorithms, and algorithms
          for the calculation of speed, course and travelled distance.</li>
      </ul>
      <p>
        Following the assessment of the above, the “Driving Behaviour and Vehicle Use Data” and
        “Gamification Data” which correspond to Your actual driving behaviour are calculated by i-DREAMS
        and then the score is extracted, as described in Annex A.
      </p>
    </li>
    <li>
      <strong>4. Extraction of Driving Behaviour, Vehicle Use Data and Gamification Data</strong>
      <p>Data of the User derived from the Primary Data via processing in the i-DREAMS platform and/or the
        Application using advanced mathematical algorithms and/or machine learning algorithms, as well as
        advanced driving behaviour models. Driving Behaviour and Vehicle Use Data are indicatively the
        following:</p>
      <ul class="list-style-disc">
        <li>Trip start date/time.</li>
        <li>Trip end date/time.</li>
        <li>Number of Trips (overall and per time period - day, week, month).</li>
        <li>Distance travelled (per Trip, overall and per time period - day, week, month).</li>
        <li>Trip Duration (per Trip, overall and per time period - day, week, month).</li>
        <li>Number of harsh brakes and intensity (per Trip, overall and per time period - day, week,
          month).</li>
        <li>Number of harsh accelerations and intensity (per Trip, overall and per time period - day, week,
          month).</li>
        <li>Number of harsh cornerings (per Trip, overall and per time period – day, week, month)</li>
        <li>Duration of driving over the speed limit if and when the speed limit is available to i-DREAMS
          by a third-party technology provider (per Trip, overall and per time period - day, week, month).</li>
        <li>Average speed, exceeding the speed limit if and when it is available to i-DREAMS by a third-
          party technology provider (per Trip, overall and per time period - day, week, month).</li>
        <li>Mobile phone use duration while driving. Mobile phone use is determined via tracking of the
          movement of the device (indicatively talking, texting or any other movement of the device)
          (per Trip, overall and per time period - day, week, month).</li>
        <li>Distance travelled in hours of increased risk (per Trip, overall and per time period - day, week,
          month).</li>
        <li>Overall score (per Trip, overall and per time period - day, week, month).</li>
        <li>Score per category. The categories are: Speeding (exceeding speed limit), Mobile phone use
          while driving, Braking, Acceleration (per Trip, overall and per time period - day, week, month).</li>
        <li>Gamification Data. i-DREAMS has developed gamification features for the training and the
          motivation of the Users in order to improve their driving behaviour. The Gamification Data
          include the performance of the Users related to gamification and/or rewarding features,
          provided that the Company has activated and/or utilized such features.</li>
      </ul>
      <p><b>“Score”:</b> The driving behaviour model of i-DREAMS, provides a score from 0 (lowest) to 100 (highest)
        per Trip, taking into account the following parameters, with decreasing level of significance:</p>
      <ul class="list-style-disc">
        <li>Driving duration over the speed limit and average speed exceedance of the speed limit if and
          when it is available to OSeven by a technology provider.</li>
        <li>Mobile phone use time while driving.</li>
        <li>Harsh brakes (number and degree of intensity).</li>
        <li>Harsh accelerations (number and degree of intensity).</li>
        <li>Harsh cornerings (number and degree of intensity).</li>
        <li>Distance traveled in hours of increased risk.</li>
        Trip duration.
      </ul>
      <p>The overall Score of the User is calculated as the weighted average of the Trips’ Scores of a specific
        time period (day, week, month, year, lifetime and custom period), where the User was the driver
        (according to the estimation of the machine learning algorithms of i-DREAMS or the characterization
        of the User), with distance as the weighting factor.</p>
      <p><b>“Gamification Data”:</b> i-DREAMS has developed gamification features for the training and the
        motivation of the Users in order to improve their driving behaviour.</p>
      <ul class="list-style-disc">
        <li>Coping tips, pros & cons, goals & badges, credits, shop, forum.</li>
      </ul>
    </li>
    <li>
      <strong>5. Time periods for the collection and processing of Your Data</strong>
      <ul class="sub-list">
        <li class="row">
          <div class="col-md-1 list-item-label">5.1</div>
          <div class="col-md-11">Your Primary Data is stored in the i-DREAMS platform within the European Union and the
            UK for
            a period of 5 years.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">5.2</div>
          <div class="col-md-11">You have the option to cancel your account by contacting i-DREAMS via the email address
            of
            Your Local Field Trial Partner at any time. Six (6) months after the cancellation of Your account
            Your personal data will be anonymized in a way that it is not reversible. In this way Your data
            and consequently, location data cannot be related with You anymore.
          </div>
        </li>
      </ul>
    </li>
    <li>
      <strong>6. Your rights</strong>
      <ul class="sub-list">
        <li class="row">
          <div class="col-md-1 list-item-label">6.1</div>
          <div class="col-md-11">Regarding Your right to be informed about the processing of Your personal data, please
            note
            that You may use the Application in order to receive information on the following indicative data
            per Trip, overall and per time period - day, week, month (for each case not all data are
            applicable): Trip start time and date, Trip end time and date, number of Trips, driving duration,
            travelled distance, travelled distance during hours of increased risk, distance and duration of
            speed limit exceedance, average speed exceeding the speed limit, average speed, duration of
            mobile phone use while driving, number and intensity of harsh brakings, number and intensity
            of harsh accelerations, overall score (that You may also compare with other drivers’ score in an
            anonymized approach) and score per category (the categories are the following: Speeding,
            Mobile phone use, Braking, Acceleration, Cornering), Your goals and badges, Your credits and
            Your postion in the leaderboard.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">6.2</div>
          <div class="col-md-11">i-DREAMS may also notify You of: a) its identity, b) the purpose of the processing, c)
            the
            recipients or categories of recipients of the data and d) of the right to access as defined in
            relevant legislation.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">6.3</div>
          <div class="col-md-11">You may submit a written query to i-DREAMS via the email address of Your Local Field
            Trial
            Partner. Through the above query, You may be informed solely of Your own personal data, their
            origin, the purpose of their processing, the recipients or categories of recipients, the progress of
            processing as of the date of the previous update or notice of information, the logic of automated
            processing and any notification to third parties to whom the data have been communicated.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">6.4</div>
          <div class="col-md-11">However, You should keep in mind that the access and obtaining knowledge of the
            automated
            processing, even with the assistance of an expert, may under no circumstances allow for
            extending this knowledge to the source code of the Application and the related algorithms.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">6.5</div>
          <div class="col-md-11">You may exercise Your rights as outlined in the European Union General Data Protection
            Regulation (indicatively Right to rectification, Right to erasure (‘right to be forgotten’), Right to
            restriction of processing, Notification obligation regarding rectification or erasure of personal
            data or restriction of processing, Right to data portability, Right to object) via the email address
            of Your Local Field Trial Partner.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">6.6</div>
          <div class="col-md-11">Please note that in case Your request related with the exercise of Your rights in the
            framework
            of EU GDPR, is abusive or manifestly unfounded or excessive, in particular because of their
            repetitive nature, We may refuse to respond to the request.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">6.7</div>
          <div class="col-md-11">You have the right to lodge a complaint with the competent supervisory (data
            protection)
            authority.
          </div>
        </li>
      </ul>
    </li>
    <li>
      <strong>7. How We have access to Your data</strong>
      <ul class="sub-list">
        <li class="row"><b>our partners</b></li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.1</div>
          <div class="col-md-11">We reserve the right to transfer Your pseudonymised data to the following
            organisations:
            Hasselt University (BE), National Technical University of Athens (GR), Loughborough University
            (UK), European Transport Safety Council (BE), OSeven Single Member Private Company (GR),
            Technische Universität München (DE), Barraqueiro Transportes (PT), Kuratorium für
            Verkehrssicherheit (AT), DriveSimSolutions (BE), CardioID Technologies (PT), POLIS (BE),
            Univerza v Mariboru (SI), Technische Universiteit Delft (NL) (collectively referred to as the "i-
            DREAMS consortium partners ").</div>
        </li>
        <li class="row"><b>privileged third parties</b></li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.2</div>
          <div class="col-md-11">Based on our legitimate interest, We reserve the right to forward Your anonymized data
            to third
            parties, by using all suitable technological means, in order to ensure anonymity, except where
            the disclosure of Your identity to service providers is required for the execution of the
            Application’s purpose, in connection with the contract which supports its functions (e.g.,
            technology services).<span class="text-decoration-line-through">Please note that We obligate all Our
              partners
              who legally have access to
              Your data to use Your data solely to perform legitimate tasks related only to the prompt and
              proper functioning of the Application and not for their own benefit.</span></div>
        </li>

        <li class="row">
          <div class="col-md-1 list-item-label">7.3</div>
          <div class="col-md-11">In the latter case, namely in the event of cross-border transfer to a recipient located
            in a foreign
            jurisdiction or in the UK, We shall at all times ensure the adequate protection and the effective
            implementation of the basic principles of data protection under the applicable legislation. In
            addition, We shall request from the i-DREAMS consortium partners, to fully respect this privacy
            statement.</div>
        </li>

        <li class="row">
          <div class="col-md-1 list-item-label">7.4</div>
          <div class="col-md-11">In the event that the application is licenced or integrated with another business, a
            limited
            number of Your details may be disclosed to any prospective purchaser’s adviser and could be
            transferred to the new owners of the business. In this case, We will implement the appropriate
            safeguards to ensure the integrity and confidentiality of Your personal data. However, use of
            Your personal information will remain subject to this Policy.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.5</div>
          <div class="col-md-11">We reserve the right to transfer Your data to our consultants / subcontractors that
            work for the
            development / improvement / optimization of the Application. Please note that We obligate all
            Our consultants / subcontractors who legally have access to Your data to use Your data solely to
            perform legitimate tasks related only to development / improvement / optimization of the
            Application.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.6</div>
          <div class="col-md-11">You should also note that We may disclose Your personal information if required to do
            so by
            court order or by decision issued by any other public authority, to the extent permitted by law.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.7</div>
          <div class="col-md-11">Finally, We may forward Your data to other third parties with Your explicit consent or
            upon Your
            request.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.8</div>
          <div class="col-md-11">Except as stated above, We shall not sell, share, lease or exchange Your personal data,
            or
            geolocation information except in the cases set forth in this privacy statement.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.9</div>
          <div class="col-md-11">i-DREAMS reserves the right to share non-personalized information, including
            non-identifying
            information and log data, with third parties for industry analysis, demographic profiling,
            statistical purposes and scientific publications. Additionally, i-DREAMS reserves the right to
            publish data analysis utilizing Your data, either directly or via its subsidiaries/parent companies
            or its scientific/research partners/consultants, provided that the published data will not include
            any personal data of the Users.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.10</div>
          <div class="col-md-11">i-DREAMS may use third party tracking services, to detect and analyze anonymous
            information
            of Our Users. These third parties may use cookies to assist in detecting the Users’ behaviour.
            However, the terms of use of those cookies are not controlled by i-DREAMS and therefore We
            have no liability in connection therewith.
          </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">7.11</div>
          <div class="col-md-11">i-DREAMS reserves the right to disclose anonymized data collected through the use of
            the
            Application, by any means and without restrictions, including third parties (vendors) who will
            assist it in enhancing or providing its Application to third parties.</div>
        </li>
      </ul>
    </li>
    <li>
      <strong>8. Control of Your Data</strong>
      <ul class="sub-list">
        <li class="row">
          <div class="col-md-1 list-item-label">8.1</div>
          <div class="col-md-11">In the event of change in Your personal data, or if You need to correct, delete
            inaccuracies or to
            modify them You must contact i-DREAMS via the email address of Your Local Field Trial Partner.
            We will respond to Your request for access, as soon as possible.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">8.2</div>
          <div class="col-md-11">Your data (“Primary Data”, “Driving Behaviour and Vehicle Use Data” and “Gamification
            Data”)
            will be stored in the database of i-DREAMS and i-DREAMS reserves the right to keep it stored,
            even if the service stops or Your account becomes inactive, in compliance with the Terms.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">8.3</div>
          <div class="col-md-11">If You no longer wish to use Our service or if You wish at any time to delete Your
            account or
            request that Your information should be no longer used for the provision of Our Application,
            You must contact i-DREAMS via the email address of Your Local Field Trial Partner.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">8.4</div>
          <div class="col-md-11">However, We shall maintain and use Your information as needed in order to comply with
            Our
            legal obligations, dispute resolution and enforcement of agreements.</div>
        </li>
      </ul>
    </li>
    <li>
      <strong>9. Handling of Your emails</strong>
      <ul class="sub-list">
        <li class="row">
          <div class="col-md-1 list-item-label">9.1</div>
          <div class="col-md-11">Each time You send an email, Your personal data is collected only to the extent
            necessary to
            respond to You and to send You Notifications which are relevant to the Application. If the
            Mailbox Manager team is not able to answer Your query, Your email will be forwarded to
            another service.
            9.2 If You have any questions regarding the processing of Your email and Your private data, please
            do not hesitate to include them in Your message.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">9.2</div>
          <div class="col-md-11">If You have any questions regarding the processing of Your email and Your private data,
            please
            do not hesitate to include them in Your message.</div>
        </li>
      </ul>
    </li>
    <li>
      <strong>10. Security</strong>
      <ul class="sub-list">
        <li class="row">
          <div class="col-md-1 list-item-label">10.1</div>
          <div class="col-md-11">Your personally identifiable information, which We collect, are safely stored in Our
            databases
            and We use standard, commercially widespread security practices, such as encryption,
            pseudonymization and firewalls, to secure Your information.</div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">10.2</div>
          <div class="col-md-11">However, as effective as technology may be, no security system is, nor does it remain,
            inaccessible. That is why, although We use all up-to-date technology practices, We cannot fully
            guarantee the security of Our database, nor can We guarantee that the information You provide
            will not be intercepted, as it is transmitted to i-DREAMS over the Internet at Your own risk. </div>
        </li>
        <li class="row">
          <div class="col-md-1 list-item-label">10.3</div>
          <div class="col-md-11">We advise You not to share Your username, Your password, or Your sign in emails with
            anyone
            at any time. </div>
        </li>
      </ul>
    </li>
  </ul>
</div>