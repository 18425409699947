<div class="popupcontainer">
  <div class="closecross" (click)="closeModal()">✕</div>
  <h2
    style="margin-left: 0">
    Add Question
  </h2>
  <div>
    <div class="flex-group">
      <div class="group-title">Question</div>
      <input [(ngModel)]="question" class="form-control" placeholder="">
    </div>
    <div *ngFor="let q of answers; let i=index" style="display: flex; align-items: center; margin-top: 10px;">
      <div style="margin-right: 5px;">Option {{i + 1}}</div>
      <div style="flex: 1;">
        <input class="form-control" [(ngModel)]="answers[i]['text']" placeholder="">
      </div>
      <div (click)="addA();"
           style="width: 20px;height: 20px; border-radius: 50%; background-color: green; color: white;display: flex;align-items: center;justify-content: center; margin: 5px; cursor: pointer; font-size: 20px;">
        +
      </div>
      <div (click)="deleteA(i)"
           style="width: 20px;height: 20px; border-radius: 50%; background-color: red; color: white;display: flex;align-items: center;justify-content: center; margin: 5px; cursor: pointer;">
        ✕
      </div>
    </div>
    <div>
      <div style="margin-top: 10px;">Correct Answer</div>
      <div ngbDropdown class="d-inline-block custom_dropdown">
        <button class="btn" id="dropdownBasic1" ngbDropdownToggle>Option {{answer+1}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button *ngFor="let a of answers;let i=index" (click)="answer=i" ngbDropdownItem>Option {{i+1}}</button>
        </div>
      </div>
    </div>
    <div class="flex-group">
      <div class="group-title"  style="margin-top: 10px;">Explanation for the Correct Answer
      </div>
      <textarea [(ngModel)]="explain" class="form-control"></textarea>
    </div>
  </div>
  <div class="horizontaldivider"></div>
  <div style="display: flex;justify-content: flex-end;">

    <div (click)="save()" class="savebutton">SAVE</div>
  </div>

</div>
