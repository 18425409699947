import {Observable} from 'rxjs';
import {ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';

export class DataCache {
  data: {} = {};

  get(key: number|string, obs: Observable<any>) {
    if (!this.data[key]) {
      let rs = new ReplaySubject();
      this.data[key] = rs;
      obs.subscribe(result => {
        rs.next(result);
      });
    }
    return this.data[key].pipe(take(1));
  }
}
