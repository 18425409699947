  <div class="toolbar">
    <h3 translate>Credits</h3>
    <button class="btn btn-sm btn-warning text-white mr-0 px-2" (click)="save()" translate>Save</button>
  </div>
<div *ngIf="(!sub || sub.closed); else loader"  class="creditsblock">
  <div style="background-color: black; padding: 12px;text-align: center"><span translate>Start Credits</span> <input
    style="background-color: black;color: white;   border-radius: 1px; border: solid 1px #ffffff; margin-left: 5px;padding-left: 5px;" value="0">
  </div>
  <div *ngIf="system !== 'cardio'">
    <div class="bonuscredits">
      <span translate>Bonus Credits</span>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center"
        style="background-color: rgba(0, 0, 0, 0.76);">
      <div class="creditrow" *ngFor="let level of getKeys(credit_levels); let ind=index">
        <div class="creditrowlevel">
          {{level}}</div>
        <div class="creditrowbonus"><input type="number" [(ngModel)]="credit_levels[level]"><span translate>bonus credited</span>
        </div>
      </div>
    </div>
  </div>
</div>

  <ng-template #loader>
    <div *ngIf="sub && !sub.closed" class="text-center blink"><span translate>Loading</span>...</div>
  </ng-template>
