import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { DataCache } from './data-cache';
import { DOCUMENT } from '@angular/common';
import type {CreateProject, User, CreateUserData, InviteUserData, ProjectRequestPayload} from '../helpers/interfaces';
import { ProjectTypeKey, UserProject} from '../helpers/interfaces';
import {LanguageService} from './language.service';


export interface DeleteProjectMemberPayload {
    project_id: string;
    user_id: number;
    role: 'projectleader';
}

export interface CreateMagicLinkPayload {
  system_type: string;
  short_id: string;
}

export interface ProjectMember {
  user_id: number;
  safe_t_id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface AttachProjectLeaderPayload {
  project_id: string;
  user_id: number;
  role: 'projectleader';
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public user: any;
  public projects: any;

  private users: any[];

  private _project: any;

  imageCache: DataCache = new DataCache();

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private storage: StorageService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  get project(): any {
    if (!this._project) {
      this._project = this.storage.get('project');
    }

    return this._project;
  }

  set project(value: any) {
    //
    this._project = value;
  }

  get system() {
    return this.project.systems[0].system_type;
  }

  get<T>(endpoint: string, ...args) {
    return this.http.get<T>(this.config.getEndpoint(endpoint), ...args);
  }

  post<T>(endpoint: string, body: any, ...args) {
    return this.http.post<T>(this.config.getEndpoint(endpoint), body, ...args);
  }

  getProjectList(): Observable<any> {
    return this.get('configuration');
  }

  getProject(projectId: number): Observable<any> {
    const params = new HttpParams().set('project_id', projectId.toString());
    return this.get<any>('project', { params });
  }

  getProjectConfiguration(projectId: number) {
    const params = new HttpParams()
      .set('project_id', projectId.toString())
      .set('language', this.languageService.getCurrentLanguageCodeAPI());
    return this.get<any>('project/configuration', { params });
  }

  getUserConfiguration(userId: number) {
    const params = new HttpParams().set('user_id', userId?.toString());
    return this.get<any>('configuration/user', { params });
  }

  getProjectConfigurationGeneral() {
    const params = new HttpParams().set('project_id', this.project.project_id.toString());
    return this.get<any>('project/configuration/general', { params });
  }

  getProjectUsers(withScore: boolean = false): Observable<any> {
    const params = new HttpParams()
      .set('project_id', this.project.project_id)
      .set('include_stats', withScore ? 'true' : 'false');

    /* const key = 'users_'+params.toString();

    const data = this.storage.get(key);*/

    /*return /*data ?
      of(data) :*/
    return this.get<any[]>('user', { params }).pipe(
      map((users) =>
        users.map((user) => {
          const project = user.projects.find((p) => p.project_id == this._project.project_id);
          user.profile.psychological_profile = user.profile.psychological_profile || {};
          return Object.assign(user, { role: project ? project.role : null });
        }),
      ),
      /*tap(users => this.storage.set(key, users))*/
    );
  }

  getProjectRolesUsers(withScore: boolean = false): Observable<any> {
    const params = new HttpParams()
      .set('project_id', this.project.project_id)
      .set('include_stats', withScore ? 'true' : 'false')
      .set('roles', 'user');

    return this.get<any[]>('user', { params }).pipe(
      map((users) =>
        users.map((user) => {
          const project = user.projects.find((p) => p.project_id == this._project.project_id);
          user.profile.psychological_profile = user.profile.psychological_profile || {};
          return Object.assign(user, { role: project ? project.role : null });
        }),
      ),
    );
  }

  getGroupUsers(community_id, withScore: boolean = false): Observable<any> {
    const params = new HttpParams()
      .set('community_id', community_id)
      .set('include_stats', withScore ? 'true' : 'false');
    return this.get<any[]>('user', { params }).pipe(
      map((users) =>
        users.map((user) => {
          const project = user.projects.find((p) => p.project_id == this._project.project_id);
          user.profile.psychological_profile = user.profile.psychological_profile || {};
          return Object.assign(user, { role: project ? project.role : null });
        }),
      ),
    );
  }

  getUser(id: number): Observable<any> {
    const params = new HttpParams().set('project_id', this.project.project_id).set('user_id', id.toString());

    return this.get<any[]>('user', { params });
  }

  saveUser(id: number, adrline1, adrline2, city, country, postal_code, phone): Observable<any> {
    let params = {
      user_id: id.toString(),
      address: {
        street: adrline1,
        number: adrline2,
        city: city,
        country: country,
        postal_code: postal_code,
      },
      phone_number: phone,
    };
    return this.post<any[]>('user', params);
  }

  toggleActivateUser(id: number, deactivated: boolean): Observable<any> {
    let params = {
      user_id: id.toString(),
    };
    return this.post<any[]>('user/' + ((deactivated && 'reactivate') || 'deactivate'), params);
  }

  saveUserProfile(id: number, trucker_type, psychological_profile): Observable<any> {
    let params = {
      user_id: id.toString(),
      trucker_type: trucker_type,
      psychological_profile: psychological_profile,
    };
    return this.post<any[]>('user/profile', params);
  }

  getProjectGroups() {
    return this.get<any[]>('communities', { params: { project_id: this.project.project_id } });
  }

  addProjectGroups(name: string, description: string, invite_only: boolean, system_type: string) {
    console.log(this.user);
    return this.post<any[]>('communities', {
      project_id: this.project.project_id,
      name: name,
      owner_id: this.user.user_id,
      description: description,
      invite_only: invite_only,
      system_type: system_type,
    });
  }

  editProjectGroups(community_id, name: string, description: string, invite_only: boolean) {
    console.log(this.user);
    return this.post<any[]>('communities', {
      community_id: community_id,
      name: name,
      description: description,
      invite_only: invite_only,
    });
  }

  deleteGroup(community_id): Observable<any[]> {
    let body = {
      community_id: community_id,
    };
    return this.http.request<any>('delete', this.config.getEndpoint('communities'), { body });
  }

  addGroupMembers(community_id, user_id) {
    return this.post<any[]>('communities/members/add', { community_id: community_id, user_id: user_id });
  }

  removeGroupMembers(community_id, user_id) {
    return this.post<any[]>('communities/members/remove', { community_id: community_id, user_id: user_id });
  }

  getProjectGroupsSchema(community_id) {
    return this.get<any[]>('communities/schema', { params: { community_id: community_id } });
  }

  getTipsForProject() {
    let params = new HttpParams().set('system_type', 'dynafleet').set('project_id', this.project.project_id);
    // .set('domain_type', domain)
    // .set('param_type', param)
    // .set('subparam_type', subparam);

    return this.http.get<any[]>(this.config.getEndpoint('tips'), { params });
  }

  getTipVideo(tip_id): Observable<any[]> {
    return this.http.get<any[]>(this.config.getEndpoint('tips/video'), {
      params: { tip_id: tip_id },
    });
  }

  requestTeltonikaTripVideo(trip_uuid, param, subparam, event_start_date, level, gps_point): Observable<any[]> {
    return this.http.post<any[]>(this.config.getEndpoint('trip/teltonika/video/request'), {
      project_id: this.project.project_id,
      trip_uuid,
      param,
      subparam,
      event_start_date,
      level,
      gps_point,
    });
  }

  postTipVideo(tip_id, videoId): Observable<any[]> {
    return this.http.post<any[]>(this.config.getEndpoint('tips/video'), {
      tip_id: tip_id,
      project_id: this.project.project_id,
      video_id: videoId,
    });
  }

  deleteTipVideo(tip_id, videoId): Observable<any[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        tip_id: tip_id,
        project_id: this.project.project_id,
        video_id: videoId,
      },
    };
    return this.http.delete<any[]>(this.config.getEndpoint('tips/video'), options);
  }

  getPhases(): Observable<any> {
    return this.http.get<any[]>(this.config.getEndpoint('project/functionalities'), {
      params: { project_id: this.project.project_id },
    });
  }

  setPhases(functionalities): Observable<any> {
    return this.http.post<any[]>(this.config.getEndpoint('project/functionalities'), {
      project_id: this.project.project_id,
      functionalities,
    });
  }

  getCreditLevels(): Observable<any> {
    return this.http.get<any[]>(this.config.getEndpoint('credit/level'), {
      params: { project_id: this.project.project_id },
    });
  }

  setCreditLevels(credit_levels): Observable<any> {
    return this.http.post<any[]>(this.config.getEndpoint('credit/level'), {
      project_id: this.project.project_id,
      credit_levels,
    });
  }

  getGoals(domain_type): Observable<any> {
    return this.get<any>('goals', {
      params: {
        project_id: this.project.project_id,
      },
    });
  }

  getUserLevels(user_id: number, domain: string): Observable<any> {
    return this.get<any>('level', { params: { user_id: user_id + '', domain: domain } });
  }

  getLeaderboards(system_type: string, domain_type: string, period: string, psychological_profile): Observable<any> {
    let params = {
      project_id: this.project.project_id + '',
      system_type: system_type,
      domain_type: domain_type,
    };
    if (psychological_profile) {
      params['psychological_profile'] = psychological_profile;
    }
    return this.get<any>('leaderboards/' + period, {
      params,
    });
  }

  getLeaderboardsCommunity(
    community_id: number,
    domain_type: string,
    period: string,
    goals_only: boolean,
    psychological_profile,
  ): Observable<any> {
    let params = {
      community_id: community_id + '',
      domain_type: domain_type,
      goals_only: goals_only,
    };
    if (psychological_profile) {
      params['psychological_profile'] = psychological_profile;
    }

    return this.get<any>('leaderboards/' + period, {
      params,
    });
  }

  getLeaderboardsTruckerType(
    trucker_type,
    domain_type: string,
    system_type: string,
    period: string,
    psychological_profile,
  ): Observable<any> {
    let params = {
      project_id: this.project.project_id + '',
      system_type: system_type,
      trucker_type: trucker_type,
      domain_type: domain_type,
    };
    if (psychological_profile) {
      params['psychological_profile'] = psychological_profile;
    }

    return this.get<any>('leaderboards/' + period, {
      params,
    });
  }

  getUserBadges(user_id: number): Observable<any[]> {
    return this.get<any[]>('badges', { params: { user_id: user_id + '' } });
  }

  getProductBasic(): Observable<any[]> {
    return this.get<any[]>('product');
  }

  getProduct(filter): Observable<any[]> {
    let params = { project_id: this.project.project_id };
    if (filter) {
      params['filter'] = [filter['key']];
    }
    return this.get<any[]>('product', { params });
  }

  deleteProduct(pid): Observable<any[]> {
    let body = {
      product_id: pid,
      project_id: this.project.project_id,
    };
    return this.http.request<any>('delete', this.config.getEndpoint('product'), { body });
  }

  addProduct(pid, title, description, credits, is_general_product, category): Observable<any[]> {
    let body = {
      project_id: this.project.project_id,
      credits: credits,
      category: category,
      is_general_product: is_general_product,
    };
    if (pid) {
      body['product_id'] = pid;
    }
    if (!is_general_product) {
      body['title'] = title;
      body['description'] = description;
    }
    return this.post<any[]>('product', body);
  }

  addProductImage(pid, image) {
    return this.post<any[]>('product/image', image, {
      params: {
        product_id: pid,
        project_id: this.project.project_id,
      },
    });
  }

  getProductConfig() {
    return this.get<any[]>('product/configuration', { params: { project_id: this.project.project_id } });
  }

  deleteProductImage(pid, image_id) {
    let body = {
      product_id: pid,
      project_id: this.project.project_id,
      image_id: image_id,
    };
    return this.http.request<any>('delete', this.config.getEndpoint('product/image'), { body });
  }

  getProductImage(product_id, image_id, max_height, max_width): Observable<any> {
    return this.get<any>('product/image', {
      params: {
        product_id: product_id,
        image_id: image_id,
        max_height: max_height,
        max_width: max_width,
      },
      responseType: 'blob',
    });
  }

  getMessages<T>(params: any = {}) {
    return this.get<T>('message', { params: { project_id: this.project.project_id, ...params } });
  }

  postMessage(body: any) {
    return this.post<any>('message', body);
  }

  postReaction(body: any) {
    return this.post<any>('message/reply', body);
  }

  postLike(message_id: number) {
    let body = {
      message_id: message_id,
      user_id: this.user.user_id,
    };
    return this.http.post<any>(this.config.getEndpoint('message/like'), body);
  }

  deleteLike(message_id: number) {
    let body = {
      message_id: message_id,
      user_id: this.user.user_id,
    };
    return this.http.request<any>('delete', this.config.getEndpoint('message/like'), { body });
  }

  postReactionLike(reply_id: number) {
    let body = {
      reply_id: reply_id,
      user_id: this.user.user_id,
    };
    return this.http.post<any>(this.config.getEndpoint('message/reply/like'), body);
  }

  deleteReactionLike(reply_id: number) {
    let body = {
      reply_id: reply_id,
      user_id: this.user.user_id,
    };
    return this.http.request<any>('delete', this.config.getEndpoint('message/reply/like'), { body });
  }

  postImage(message_id: number, image: File) {
    let params = { message_id: message_id } as any;

    return this.http.post<any>(this.config.getEndpoint('message/image'), image, {
      headers: { 'Content-Type': 'image/jpeg' },
      params,
    });
  }

  postVideo(message_id: number, video: File) {
    let params = {
      message_id: message_id,
      project_id: this.project.project_id,
      video_id: video.name,
    } as any;

    return this.http.post<any>(this.config.getEndpoint('message/video/create'), video, {
      headers: {},
      params,
    });
  }

  getImage(message_id, image_id) {
    let params = { message_id: message_id, image_id: image_id, project_id: this.project.project_id };
    let obs = this.http.get(this.config.getEndpoint('message/image'), { params: params, responseType: 'blob' });

    return this.imageCache.get(image_id, obs);
  }

  getVideo(message_id, video_id) {
    return this.http.get<any>(this.config.getEndpoint('message/video'), {
      params: {
        message_id: message_id,
        video_id: video_id,
      },
    });
  }

  getChart(api: string, params: any) {
    api = api || 'dummy404';
    return this.http.get(this.config.getEndpoint(`charts/${api}`), { params: params });
  }

  getUserPicture(user_id, max_width, max_height) {
    return this.http.get(this.config.getEndpoint('user/picture'), {
      params: {
        user_id: user_id,
        max_width: max_width,
        max_height: max_height,
      },
      responseType: 'blob',
    });
  }

  submitGroupSchema(community_id, currentschema) {
    let params = {
      community_id: community_id,
      project_id: this.project.project_id,
      schema: currentschema,
    };

    return this.http.post<any>(this.config.getEndpoint('communities/schema'), params);
  }

  getSpecificSurvey(survey_id: string) {
    return this.http.get<any[]>(this.config.getEndpoint('survey'), {
      params: {
        user_id: this.user.user_id,
        survey_id: survey_id,
      },
    });
  }

  getProsncons(
    system: string,
    domain: string,
    param: string,
    subparam: string,
    language = 'eng',
  ): Observable<{ pros; cons }> {
    const params = {
      project_id: this.project.project_id,
      system_type: system,
      domain_type: domain,
      param_type: param,
      subparam_type: subparam,
      language: language,
    };
    if (!param) delete params['param_type'];
    if (!subparam) delete params['subparam_type'];

    return combineLatest(this.get<any[]>('known/pro', { params }), this.get<any[]>('known/con', { params })).pipe(
      map(([pros, cons]) => {
        return { pros, cons };
      }),
    );
  }

  getCons(system: string, domain: string, param: string, subparam: string, language = 'eng'): Observable<any[]> {
    const params = {
      project_id: this.project.project_id,
      system_type: system,
      domain_type: domain,
      param_type: param,
      subparam_type: subparam,
      language: language,
    };
    if (!param) delete params['param_type'];
    if (!subparam) delete params['subparam_type'];

    return this.get<any[]>('known/con', { params });
  }

  getFacts(system: string, domain: string, param: string, subparam: string, language: string): Observable<any[]> {
    const params = {
      project_id: this.project.project_id,
      system_type: system,
      domain_type: domain,
      param_type: param,
      subparam_type: subparam,
      language: language,
    };
    if (!param) delete params['param_type'];
    if (!subparam) delete params['subparam_type'];

    return this.get<any[]>('known/fact', { params });
  }

  getFact(system: string, language: string, known_id: number): Observable<any[]> {
    const params = {
      project_id: this.project.project_id,
      system_type: system,
      language: language,
      known_id: known_id
    };

    return this.get<any[]>('known/fact', { params });
  }

  getFactImage(tip_id, image_id) {
    let params = { tip_id: tip_id, image_id: image_id, project_id: this.project.project_id };
    let obs = this.http.get(this.config.getEndpoint('facts/image'), { params: params, responseType: 'blob' });
    return this.imageCache.get(image_id, obs);
  }

  deleteFactImage(tip_id: number, image_id) {
    let params = { tip_id: tip_id, image_id: image_id } as any;
    return this.http.request<any>('delete', this.config.getEndpoint('facts/image'), { body: params });
  }

  getFactsVideo(tipsId, video_id): Observable<any> {
    return this.http.get<any>(this.config.getEndpoint('facts/video'), {
      params: {
        tip_id: tipsId,
        video_id: video_id,
      },
    });
  }

  editFact(fact: any): Observable<{ id: number }> {
    return this.post<{ id : number }>('known/fact', fact);
  }

  removeFact(body: any) {
    return this.http.request('delete', this.config.getEndpoint('known/fact'), { body: body });
  }

  postProsncons(body: any, type: string) {
    return type == 'pro' ? this.post<any>('known/pro', body) : type == 'con' ? this.post<any>('known/con', body) : null;
  }

  removeProsAndCons(body: any, type: string) {
    return type == 'Pro' ? this.http.request('delete', this.config.getEndpoint('known/pro'), { body: body }) : type == 'Con' ? this.http.request('delete', this.config.getEndpoint('known/con'), { body: body }) : null;
  }

  getProsAndConsImage(pros_and_cons_id, image_id) {
    let params = { pros_and_cons_id: pros_and_cons_id, image_id: image_id, project_id: this.project.project_id };
    let obs = this.http.get(this.config.getEndpoint('pros_and_cons/image'), { params: params, responseType: 'blob' });
    return this.imageCache.get(image_id, obs);
  }

  postProsAndConsImage(pros_and_cons_id: number, image: File) {
    let params = { pros_and_cons_id: pros_and_cons_id } as any;

    return this.http.post<any>(this.config.getEndpoint('pros_and_cons/image'), image, {
      headers: { 'Content-Type': 'image/jpeg' },
      params,
    });
  }

  deleteProsAndConsImage(pros_and_cons_id: number, image_id) {
    let params = { pros_and_cons_id: pros_and_cons_id, image_id: image_id } as any;
    return this.http.request<any>('delete', this.config.getEndpoint('pros_and_cons/image'), { body: params });
  }

  getSurvey(survey_id: string) {
    return this.http.get(this.config.getEndpoint('survey'), {
      params: {
        user_id: this.user.user_id,
        survey_id: survey_id,
      },
    });
  }

  getSurveySession(survey_id: string) {
    return this.http.post<any[]>(this.config.getEndpoint('survey/session'), {
      user_id: this.user.user_id,
      survey_id: survey_id,
    });
  }

  postEndSurveySession(survey_id: string, session_id: string, responses: any) {
    return this.http.post<any[]>(this.config.getEndpoint('survey/session'), {
      user_id: this.user.user_id,
      survey_id: survey_id,
      session_id: session_id,
      advance: true,
      responses: responses,
      done: true,
      embeddedData: {
        safetid: 'abcd',
      },
    });
  }

  getSurveys(surveyType: string): Observable<any[]> {
    let params = {
      project_id: this.project.project_id,
    };
    if (surveyType) {
      params['survey_type'] = surveyType;
    }
    return this.http.get<any[]>(this.config.getEndpoint('survey'), {
      params,
    });
  }

  getSurveyResult(survey_id: string, sessionId: string) {
    return this.http.get(this.config.getEndpoint('survey/result'), {
      params: {
        user_id: this.user.user_id,
        survey_id: survey_id,
        session_id: sessionId,
      },
    });
  }

  deleteSurveyQuestion(surveyid, questionid) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        survey_id: surveyid,
        question_id: questionid,
      },
    };
    return this.http.delete(this.config.getEndpoint('survey/question'), options);
  }

  editTip(tip: any): Observable<{ id: number }> {
    return this.post<{ id: number }>('known/tip', tip);
  }

  removeTip(body: any) {
    return this.http.request('delete', this.config.getEndpoint('known/tip'), { body: body });
  }

  getTips(system, domain, param, subparam, language = 'eng') {
    let params = {
      project_id: this.project.project_id,
      system_type: system,
      domain_type: domain,
      param_type: param,
      subparam_type: subparam,
      language: language,
    };

    if (!param) delete params['param_type'];
    if (!subparam) delete params['subparam_type'];

    return this.get<any[]>('known/tip', { params });
  }

  getTip(system, language, known_id) {
    let params = {
      project_id: this.project.project_id,
      system_type: system,
      language: language,
      known_id: known_id
    };

    return this.get<any[]>('known/tip', { params });
  }

  getTipImage(tip_id, image_id) {
    let params = { tip_id: tip_id, image_id: image_id, project_id: this.project.project_id };
    let obs = this.http.get(this.config.getEndpoint('tips/image'), { params: params, responseType: 'blob' });
    return this.imageCache.get(image_id, obs);
  }

  getKnownImageOrVideo(url) {
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  postTipImage(tip_id: number, image: File) {
    let params = { tip_id: tip_id } as any;

    return this.http.post<any>(this.config.getEndpoint('tips/image'), image, {
      params,
    });
  }

  deleteTipImage(tip_id: number, image_id) {
    let params = { tip_id: tip_id, image_id: image_id } as any;
    return this.http.request<any>('delete', this.config.getEndpoint('tips/image'), { body: params });
  }

  getTipsVideo(tipsId, video_id): Observable<any> {
    return this.http.get<any>(this.config.getEndpoint('tips/video'), {
      params: {
        tip_id: tipsId,
        video_id: video_id,
      },
    });
  }

  postTipsVideo(tipsId, videoId): Observable<any[]> {
    return this.http.post<any[]>(this.config.getEndpoint('tips/video'), {
      tip_id: tipsId,
      project_id: this.project.project_id,
      video_id: videoId,
    });
  }

  deleteTipsVideo(tipsId, videoId): Observable<any[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        tip_id: tipsId,
        project_id: this.project.project_id,
        video_id: videoId,
      },
    };
    return this.http.delete<any[]>(this.config.getEndpoint('tips/video'), options);
  }

  addSurveyQuestion(surveyid, choices, choice_order, scoring, answer_explanation) {
    const body = {
      survey_id: surveyid,
      // domain_type: "eco_efficiency",
      // param_type: "standstill",
      // subparam_type: "idling",
      scoring: scoring,
      answer_explanation: answer_explanation,
      question: {
        QuestionText: 'Question NEW with label',
        DataExportTag: 'Q_NEW_WITH_LABEL',
        QuestionType: 'MC',
        Selector: 'SAVR',
        SubSelector: 'TX',
        Configuration: {
          QuestionDescriptionOption: 'UseText',
        },
        QuestionDescription: 'Question NEW with label',
        Choices: choices,
        ChoiceOrder: choice_order,
        Validation: {
          Settings: {
            ForceResponse: 'OFF',
            ForceResponseType: 'ON',
            Type: 'None',
          },
        },
        Language: [],
        // "NextChoiceId": 4,
        // "NextAnswerId": 1,
        QuestionText_Unsafe: 'Question NEW',
      },
    };
    return this.http.post(this.config.getEndpoint('survey/question'), body);
  }

  postProsAndCons(body: any) {
    return this.http.post<any>(this.config.getEndpoint('pros_and_cons'), body);
  }

  getProsAndCons(): Observable<any[]> {
    return this.http.get<any[]>(this.config.getEndpoint('pros_and_cons'), {
      params: { project_id: this.project.project_id },
    });
  }

  getProAndCon(system, language, known_id, type): Observable<any[]> {
    let params = {
      project_id: this.project.project_id,
      system_type: system,
      language: language,
      known_id: known_id
    };
    return type === 'pro' ? this.http.get<any[]>(this.config.getEndpoint('known/pro'), { params }) : type === 'con' ? this.http.get<any[]>(this.config.getEndpoint('known/con'), { params }) : null;
  }

  getProConsVideo(proconsid, video_id): Observable<any> {
    return this.http.get<any>(this.config.getEndpoint('pros_and_cons/video'), {
      params: {
        pros_and_cons_id: proconsid,
        video_id: video_id,
      },
    });
  }

  postProConsVideo(proconsid, videoId): Observable<any[]> {
    return this.http.post<any[]>(this.config.getEndpoint('pros_and_cons/video'), {
      pros_and_cons_id: proconsid,
      project_id: this.project.project_id,
      video_id: videoId,
    });
  }

  deleteProConsVideo(proconsid, videoId): Observable<any[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        pros_and_cons_id: proconsid,
        project_id: this.project.project_id,
        video_id: videoId,
      },
    };
    return this.http.delete<any[]>(this.config.getEndpoint('pros_and_cons/video'), options);
  }

  getGoalsBatch(subparam: string): Observable<any[]> {
    // return of([{"id": 29, "title": "Coasting", "system_type": "trimble", "strategy": "fail", "strategy_base": "aggregate", "community_id": 86, "project_id": 14, "subparam_type": "coasting", "param_type": "anticipation", "domain_type": "eco_efficiency", "goals": [{"id": 99, "title": "Coasting", "description": "", "unit_label": "km", "target": 100.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 80.0, "batch_id": 29, "goal_batch_rank": 1, "goal_batch_order": 1}, {"id": 100, "title": "Coasting", "description": "", "unit_label": "km", "target": 200.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 85.0, "batch_id": 29, "goal_batch_rank": 2, "goal_batch_order": 1}, {"id": 101, "title": "Coasting", "description": "", "unit_label": "km", "target": 300.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 90.0, "batch_id": 29, "goal_batch_rank": 3, "goal_batch_order": 1}, {"id": 102, "title": "Coasting", "description": "", "unit_label": "km", "target": 400.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 95.0, "batch_id": 29, "goal_batch_rank": 4, "goal_batch_order": 1}], "started": false}]);

    let params = new HttpParams().set('subparam_type', subparam).set('project_id', this.project.project_id);
    return this.get<any[]>('goals/batch', { params });
  }

  postGoals(data: any) {
    return this.post('goals/batch', data);
  }

  startGoals(id: number) {
    const body = { batch_id: id };
    return this.post<any>('goals/batch/start', body);
  }

  getTrips(
    user_id: string = null,
    trip_uuid: string = null,
    include_gps: boolean = false,
    include_events: boolean = false,
    from_date: string = null,
    until_date: string = null,
  ): Observable<any[]> {
    // return of([{"id": 29, "title": "Coasting", "system_type": "trimble", "strategy": "fail", "strategy_base": "aggregate", "community_id": 86, "project_id": 14, "subparam_type": "coasting", "param_type": "anticipation", "domain_type": "eco_efficiency", "goals": [{"id": 99, "title": "Coasting", "description": "", "unit_label": "km", "target": 100.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 80.0, "batch_id": 29, "goal_batch_rank": 1, "goal_batch_order": 1}, {"id": 100, "title": "Coasting", "description": "", "unit_label": "km", "target": 200.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 85.0, "batch_id": 29, "goal_batch_rank": 2, "goal_batch_order": 1}, {"id": 101, "title": "Coasting", "description": "", "unit_label": "km", "target": 300.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 90.0, "batch_id": 29, "goal_batch_rank": 3, "goal_batch_order": 1}, {"id": 102, "title": "Coasting", "description": "", "unit_label": "km", "target": 400.0, "status": null, "suggested": false, "system_type": "trimble", "community_id": 86, "project_id": null, "domain_type": "eco_efficiency", "param_type": "anticipation", "subparam_type": "coasting", "subparam_value": 95.0, "batch_id": 29, "goal_batch_rank": 4, "goal_batch_order": 1}], "started": false}]);

    let params = new HttpParams()
      .set('project_id', this.project.project_id)
      .set('include_gps', include_gps ? 'true' : 'false')
      .set('include_events', include_events ? 'true' : 'false')
      .set('language', this.languageService.getCurrentLanguageCodeAPI());
    if (from_date) params = params.set('from_date', from_date);
    if (until_date) params = params.set('until_date', until_date);
    if (user_id) params = params.set('user_id', user_id);
    if (trip_uuid) params = params.set('trip_uuid', trip_uuid);

    return this.get<any[]>('trip/cardio', { params });
  }

  getTeltonikaVideos(
    user_id: string = null,
    video_from_date: string = null,
    video_until_date: string = null,
  ): Observable<any[]> {
    let params = new HttpParams()
      .set('project_id', this.project.project_id)
      .set('from_date', video_from_date)
      .set('until_date', video_until_date);
    if (user_id) params = params.set('user_id', user_id);

    return this.get<any[]>('trip/teltonika/video', { params });
  }

  getSamaraIncidents(
    user_id: string = null,
    incident_from_date: string = null,
    incident_until_date: string = null,
  ): Observable<any[]> {
    let params = new HttpParams()
      .set('project_id', this.project.project_id)
      .set('from_date', incident_from_date)
      .set('until_date', incident_until_date);

    return this.get<any[]>('trip/samsara/safety_events', { params });
  }

  downloadTeltonikaVideos(video_uuid): Observable<any[]> {
    let params = new HttpParams().set('project_id', this.project.project_id).set('video_uuid', video_uuid);

    return this.get<any[]>('trip/teltonika/video/download', { params });
  }

  setTeltonicaVideoStar(request_uuid): Observable<any[]> {
    return this.http.post<any[]>(this.config.getEndpoint('trip/teltonika/video/star'), {
      project_id: this.project.project_id,
      request_uuid,
    });
  }

  unsetTeltonicaVideoStar(request_uuid): Observable<any[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        project_id: this.project.project_id,
        request_uuid: request_uuid,
      },
    };
    return this.http.delete<any[]>(this.config.getEndpoint('trip/teltonika/video/star'), options);
  }

  prosandconsVideoPresignedUrl(proconsid, video_id, type) {
    return this.http.post<any>(this.config.getEndpoint(`known/${type}/video/create`), {
      known_id: proconsid,
      project_id: this.project.project_id,
      video_id: video_id,
    });
  }

  tipsVideoPresignedUrl(tipId, video_id) {
    return this.http.post<any>(this.config.getEndpoint('known/tip/video/create'), {
      known_id: tipId,
      project_id: this.project.project_id,
      video_id: video_id,
    });
  }

  factsVideoPresignedUrl(factId, video_id) {
    return this.http.post<any>(this.config.getEndpoint('known/fact/video/create'), {
      known_id: factId,
      project_id: this.project.project_id,
      video_id: video_id,
    });
  }

  deleteFactsVideo(tipsId, videoId): Observable<any[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        tip_id: tipsId,
        project_id: this.project.project_id,
        video_id: videoId,
      },
    };
    return this.http.delete<any[]>(this.config.getEndpoint('facts/video'), options);
  }

  uploadFileToS3(response, file) {
    let formData = new FormData();
    Object.keys(response.fields).map((key) => {
      formData.append(key, response.fields[key]);
    });
    formData.append('file', file);

    return this.http.post(response.url, formData);
  }

  public generateThumbnail(videoFile): Promise<string> {
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<string>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      video.addEventListener('error', reject);
      video.addEventListener('canplay', (event) => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
    });
  }

  public getBlobVideo(url: string) {
    let params = new HttpParams();

    return this.http.get(url, {
      params,
      responseType: 'blob',
    });
  }

  generateReport(body) {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer asdf',
        'cache-control': 'no-cache',
      }),
    };

    return this.http.post<any[]>(this.config.getEndpoint('report/company/download'), body, options);
  }

  public getProjects(type: string) {
    return this.http.get<CreateProject[]>(this.config.getEndpoint('project/' + type));
  }

  public getProjectDomains(project_id: number) {
    return this.http.get(this.config.getEndpoint('project/schema?project_id=' + project_id));
  }

  public createProject(project: CreateProject) {
    return this.http.post<{
      project_id: number; project_uuid: string
    }>(this.config.getEndpoint('project/' + project.project_type), project);
  }
  public updateProjectDomains(project_id: number, schema: { [key: string]: { status: string } }) {
    return this.http.post(this.config.getEndpoint('project/schema'), {
      project_id,
      schema,
    });
  }

  public getUsers(project_id: string, roles: string[]) {
    return this.http.get<User[]>(this.config.getEndpoint('user'), {
      params: {
        project_id,
        roles: roles.join(','),
      },
    });
  }

  public createUser(createUserData: CreateUserData) {
    return this.http.post<User>(this.config.getEndpoint('user'), createUserData);
  }

  public createUserByProjectType(createUserData: CreateUserData, projectType: ProjectTypeKey) {
    return this.http.post<UserProject>(this.config.getEndpoint(`user/${projectType}`), createUserData);
  }

  public inviteUser(inviteUserData: InviteUserData) {
    return this.http.post<User>(this.config.getEndpoint('user/invite'), inviteUserData);
  }

  public getUsersByProjectType(projectType: ProjectTypeKey, projectId: string): Observable<UserProject[]> {
    return this.http.get<UserProject[]>(this.config.getEndpoint(`user/${projectType}`), {
      params: {
        project_id: projectId,
      },
    });
  }

  deleteProjectMember(payload: DeleteProjectMemberPayload) {
    return this.http.request('delete', this.config.getEndpoint('project/member'), { body: payload });
  }

  createMagicLink(payload: CreateMagicLinkPayload): Observable<{ magic_link: string }> {
    return this.http.post<{ magic_link: string }>(this.config.getEndpoint(`user/invite/link`), payload);
  }

  getProjectMembers(role: string): Observable<ProjectMember[]> {
    return this.http.get<ProjectMember[]>(this.config.getEndpoint('user/admin'), {
      params: {
        roles: role,
      },
    });
  }

  attachProjectLeader(payload: AttachProjectLeaderPayload) {
    return this.http.post(this.config.getEndpoint('project/member'), payload);
  }

  getProjectTags(project_id: number): Observable<any[]> {
    return this.http.get<any[]>(this.config.getEndpoint('project/tag'), {
      params: {
        project_id: project_id,
      },
    });
  }

  getUserTags(project_id: number, user_id: number): Observable<any[]> {
    return this.http.get<any[]>(this.config.getEndpoint('user/tag'), {
      params: {
        project_id: project_id,
        user_id: user_id,
      }
    });
  }

  addUserTag(project_id: number, user_id: number, user_tag: string): Observable<any> {
    return this.http.post<any>(this.config.getEndpoint('user/tag/attach'), {
      project_id: project_id,
      user_id: user_id,
      tag_name: user_tag,
    });
  }

  deleteUserTag(project_id: number, user_id: number, user_tag: string): Observable<any> {
    return this.http.post<any>(this.config.getEndpoint('user/tag/detach'), {
      project_id: project_id,
      user_id: user_id,
      tag_name: user_tag,
    });
  }

  addTagToCharts(tag: string, project_id: number, system_type: string, aggregate: string, from_date: string, until_date: string): Observable<any> {
    return this.http.get<any>(this.config.getEndpoint('charts/safety'), {
      params: {
        system_type: system_type,
        aggregate: aggregate,
        from: from_date,
        until: until_date,
        project_id: project_id,
        tags: tag,
      }
    })
  }

  getLeaderboardsTag(system_type: string, tag: string, domain_type: string, period: string, project_id: number): Observable<any> {
    return this.get<any>('leaderboards/' + period, {
      params: {
        tags: tag,
        domain_type: domain_type,
        project_id: project_id,
        system_type: system_type,
      }
    })
  }

  getAvailableLanguages(project_uuid: string, project_id: number, project_type: string) {
    return project_type === 'teltonika' ? this.get<any>('project/teltonika', { params: { project_uuid: project_uuid } }) : project_type === 'oseven' ? this.get<any>('project/oseven', { params: { project_id: project_id }}) : null;
  }

  postKnownImage(known_id: number, project_id: number, image_id: string, image: File) {
    return this.post<any>('known/image', image, {
      params: {
        known_id: known_id,
        project_id: project_id,
        image_id: image_id
      }
    });
  }
}
