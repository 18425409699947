<div class="popupcontainer">

  <div class="modal-header">
    <h4 class="modal-title">Add Group</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pb-5">

    <ng-container *ngIf="(!sub || sub.closed); else loader">

      <div style="display: flex;flex-direction: column;">
        <div class="flex-group">
          <div class="group-title">Title</div>
          <input [(ngModel)]="title" class="form-control">
        </div>
        <div class="flex-group">
          <div class="group-title">Description</div>
          <textarea [(ngModel)]="description" class="form-control"></textarea>
        </div>
        <div class="flex-group" style="flex-direction: row;align-items: center;">
          <div class="group-title">Invite only</div>
          <input [(ngModel)]="invite_only" type="checkbox" style="margin-left: 15px;">
        </div>
      </div>
      <div class="text-right" style="margin-top: 15px; margin-right: 15px;">
        <button class="btn btn-warning text-white font-weight-bold px-4"
                (click)="addGroup()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </ng-container>

    <ng-template #loader>
      <div *ngIf="sub && !sub.closed" class="text-center blink">Loading...</div>
    </ng-template>

  </div>

</div>
