<form class="popupcontainer" (submit)="submit()">
  <div class="closecross" (click)="closeModal()">✕</div>
  <h2 class="ml-0"> {{item ? 'Edit' : 'Add'}} tip for {{subparam_label|t}}</h2>
  <div>

  </div>
  <ng-container *ngFor="let language of available_known_languages; let i = index">
    <div class="mt-2 form-row">
      <div class="col-md-12">{{available_language_choices[i]}}</div>
      <div class="col-md-4">
        <textarea class="form-control" rows="4" [(ngModel)]="description[i]" [name]="[i]"
          placeholder="Describe the tip you wish to add "></textarea>
      </div>
      <div class="col-md-4">
        <div appDragDropFileUpload class="dropboxcontainer"
        [style]="!filesToUpload[i] ? 'height: 150px;':'min-height: 150px;'"
        (fileDropped)="addFilesToUpload($event, i)" (click)="fileField.click()">
          <div *ngIf="filesToUpload[i]" class="dropimagelist">
            <ng-container *ngFor="let file of filesToUpload[i]; let index=index;">
              <div class="dropimagewrapper" *ngIf="file.mode != 'del'">
                <img [src]="file.url|sanitizeUrl" class="dropimage">
                <span appClickStopPropagation (click)="remove(index, i)" class="redclosicon">✕</span>
              </div>
            </ng-container>
          </div>
          <div *ngIf="!filesToUpload[i] || filesToUpload[i].length === 0" class="dropcaption">Upload Image
          </div>
          <!--suppress TypeScriptUnresolvedVariable -->
          <input type="file" name="avatars" #fileField (change)="addFilesToUpload($event.target.files, i)" hidden multiple>
        </div>
      </div>

      <div class="col-md-4">
        <div appDragDropFileUpload class="dropboxcontainer"
          [style]="!videoToUpload[i] ? 'height: 150px;':'min-height: 150px;'"
          (fileDropped)="addVideoToUpload($event, i)" (click)="videofield.click()">
          <div *ngIf="videoToUpload[i]" class="dropimagelist">
            <ng-container *ngFor="let file of videoToUpload[i]; let index=index;">
              <div class="dropimagewrapper" *ngIf="file.mode != 'del'">
                <img src="/assets/images/thumbnail.jpg" class="dropimage">
                <span appClickStopPropagation (click)="removeVideo(index, i)" class="redclosicon">✕</span>
              </div>
            </ng-container>
          </div>
          <div *ngIf="!videoToUpload[i] || videoToUpload[i].length === 0" class="dropcaption">Upload Video
          </div>
          <!--suppress TypeScriptUnresolvedVariable -->
          <input type="file" name="videos" #videofield accept="video/mp4,video/x-m4v,video/*"
            (change)="addVideoToUpload($event.target.files, i)" hidden multiple>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="horizontaldivider"></div>
  <div style="display: flex;justify-content: center;">
    <button class="btn btn-warning text-white w-25" [disabled]="(sub && !sub.closed) || !valid || image_uploading || video_uploading">
      <div class="spinner-btn">
        <img *ngIf="(sub && !sub.closed) || !valid || image_uploading || video_uploading" src="/assets/images/spinner.svg" width="24px" />
        SAVE
      </div>
    </button>
  </div>

</form>
