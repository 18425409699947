<div class="wrapper position-relative" style="">

  <div *ngIf="!logged" style="display: flex;flex-direction: column;align-items: center;" class="form-wrapper">
    <div style="margin-bottom: 60px; text-align: center"><img src="/assets/images/logo_horizontaal_RGB.svg" class="logo" alt="">
    </div>

    <form action="" (submit)="login()">
<!--      <div class="signintext" translate>Sign In</div>-->
      <input type="text" name="username" [(ngModel)]="model.username" class="form-control grayinput mb-3"
             placeholder="E-mail">
      <input type="password" name="password" [(ngModel)]="model.password" class="form-control grayinput mb-3"
             placeholder="Password">
      <p *ngIf="error" class="text-danger small">Wrong username or password</p>
      <button
        type="submit"
        class="btn btn-warning text-white w-100 ld-ext-right"
        [disabled]="sub && !sub.closed"
        [ngClass]="{'running': sub && !sub.closed}"
        translate>
        <span translate>SIGN IN</span>
        <div class="ld ld-ring ld-spin"></div>
      </button>
      <a routerLink="/forgot-password" class="mb-4" translate>Forgot Password?</a>
      <!-- <div class="forgottext">Forgot password?</div> -->
    </form>
    <!-- <div class="signuptext">
      Don't have an account? <span class="signupactivetext">Sign Up</span>
    </div> -->
  </div>

  <div class="position-absolute" style="top: 80px; right: 20px;">
    <select
      name="l"
      id=""
      (change)="changeLang($event)"
      class="form-control custom_dropdown mb-4" [(ngModel)]="lang">
      <option *ngFor="let l of languages" [ngValue]="l.code" translate>{{l.label}}</option>
    </select>
  </div>

</div>
