import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { DataService } from '../../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { Route, Segment } from '../../../models/segment';
import * as moment from 'moment';
import 'moment-duration-format';
import * as L from 'leaflet';
import { gridLayer, latLng, tileLayer } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';
import 'hls.js';
import { EventEmitter } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import {TranslateService} from '@ngx-translate/core';

declare let Hls;

let _this;

@Component({
  selector: 'app-trip-map',
  templateUrl: './trip-map.component.html',
  styleUrls: ['./trip-map.component.scss'],
})
export class TripMapComponent implements OnInit, OnChanges {
  @Input() selected: any;
  @Input() flattenLegend = {};
  @Input() filterLegends = [];
  @Input() status = [];
  @Input() domain: string;

  @Output() mapClick: EventEmitter<any> = new EventEmitter();
  @Output() tripUpdate: EventEmitter<any> = new EventEmitter();

  map: L.DrawMap;
  menu = [];

  leafletOptions: any;
  tiles: any[] = [];

  isMapOpen = false;

  trips: any[] = [];
  legends = {};

  layergroup: L.LayerGroup;
  routing: L.Routing.Control;
  popupShown: boolean = false;
  markerPopup: any;
  tripRoute: Route;
  highlight: L.Polyline;
  system: string;

  statusAvailableFields = ['steering', 'acceleration', 'deceleration', 'speeding', 'tailgating'];

  downloading = false;
  public _httpClient: HttpClient;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar,
    private handler: HttpBackend,
    private translate: TranslateService
  ) {
    this._httpClient = new HttpClient(this.handler);
    _this = this;
  }

  ngOnInit(): void {
    this.system = this.dataService.project.systems[0].system_type;
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('filterLegends' in changes && changes['filterLegends'].previousValue !== undefined) {
      this.addEvents();
    }

    if ('status' in changes && changes['status'].previousValue !== undefined) {
      this.addEvents();
    }
  }

  initMap() {
    this.tiles = [
      tileLayer('https://imobwww.uhasselt.be/osm_tiles/{z}/{x}/{y}.png', {
        maxZoom: 18, // Set the maximum zoom level here

        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
      })
    ];

    this.leafletOptions = {
      layers: [this.tiles[0]],
      zoom: 9,
    };
    if (this.selected['gps_trace'].lat?.length && this.selected['gps_trace'].lon?.length) {
      this.map = L.map('map', this.leafletOptions).setView(
        new L.LatLng(this.selected['gps_trace'].lat[0], this.selected['gps_trace'].lon[0]),
        14,
      );
    }
    this.map.on('zoomend', () => {
      if (this.map.getZoom() > 18) {
        this.map.setZoom(18);
      }
    });

    this.newRouting(
      this.selected['gps_trace'].lat.map((e, index) => {
        return L.latLng(e, this.selected['gps_trace'].lon[index]);
      }),
    );

    interface CustomPopup extends L.Popup {
      _source: {
        video: any;
        event: any;
      }
    }
    const self = this;
    this.map.on('popupopen', function (e: { popup: CustomPopup }) {
      var video = document.getElementById('video') as HTMLMediaElement;
      var videoSrcInHls = e.popup._source.video;
      var event = e.popup._source.event;
      var requestBtn = document.getElementById('request-btn') as HTMLButtonElement;
      var downloadBtn = document.getElementById('download-btn') as HTMLButtonElement;

      if (videoSrcInHls && Hls.isSupported() && videoSrcInHls.url) {
        var hls = new Hls();
        hls.loadSource(videoSrcInHls.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          // video.play();
        });
        video.addEventListener('loadedmetadata', () => {
          video.currentTime = videoSrcInHls.delta || 0;
        });
      }
      if (requestBtn) {
        requestBtn.addEventListener('click', () => {
          self.dataService
            .requestTeltonikaTripVideo(
              event.trip_uuid,
              event.param,
              event.subparam,
              event.start_date,
              event.level,
              event.gps_point,
            )
            .subscribe(
              (result) => {
                const newItem = document.createElement('button');
                newItem.setAttribute('id', 'request-btn');
                newItem.setAttribute('class', 'btn btn-sm btn-secondary');
                newItem.disabled = true;
                newItem.innerHTML = 'Video requested';
                requestBtn.replaceWith(newItem);
                self.tripUpdate.emit();
              },
              (error) => {
                console.log(error);
              },
            );
        });
      }

      if (downloadBtn) {
        downloadBtn.addEventListener('click', () => {
          self.videoDownload(event.video);
        });
      }
    });

    this.map.on('click', (e) => {
      this.mapClick.emit(null);
    });
  }

  removeEvents() {
    if (this.legends) {
      Object.keys(this.legends).forEach((key) => {
        this.legends[key].entities.forEach((entity) => {
          this.map.removeLayer(entity);
        });
      });
    }
  }

  removeHighlights() {
    if (this.highlight) {
      this.highlight.removeFrom(this.map);
      this.highlight = null;
    }
  }

  addEvents() {
    this.removeEvents();
    this.removeHighlights();
    Object.keys(this.flattenLegend).forEach((key) => {
      if (this.filterLegends.length && this.filterLegends.indexOf(key) > -1) {
        this.addMarker(key);
      }
    });
    // if ( this.filterLegends.length) this.addHighlights();
  }

  // addHighlights() {
  //   this.tripRoute.segments.forEach(seg => {
  //     if ( seg.visible ) {
  //       if ( seg.appliedKeys.length ) {
  //         seg.poly.addTo(this.map);
  //       }
  //       else {
  //         seg.visible = false;
  //       }
  //     }
  //   })
  // }

  addMarker(key) {
    const param = key.split('/')[1],
      subparam = key.split('/')[2];

    this.legends[key] = {
      ...this.flattenLegend[key],
      entities: [],
    };

    let items = this.legends[key].entities;

    const domainKey = this.domain === 'safety' ? 'safety_events' : 'eco_events';

    (this.selected[domainKey] || [])
      .filter((e) => e.param == param && e.subparam == subparam)
      .forEach((event) => {
        let canVisible = false;
        if (this.statusAvailableFields.indexOf(event.subparam) > -1) {
          this.status.forEach((st) => {
            if (event.subparam !== 'tailgating') {
              if (event.status && st === event.status) {
                canVisible = true;
              }
            } else {
              if (st === 'high') {
                if (event.level && event.level === 3) {
                  canVisible = true;
                }
              } else if (st === 'medium') {
                if (event.level && event.level === 2) {
                  canVisible = true;
                }
              }
            }
          });
        } else {
          canVisible = true;
        }

        if (event.gps_point && canVisible) {
          let marker = this.marker({ ...event, trip_uuid: this.selected?.trip_uuid }, this.legends[key].info);
          marker.addTo(this.map);
          items.push(marker);
          if (event.duration) {
          }
        }
      });
  }

  newRouting(trace_points: L.LatLng[]) {
    var polyline = L.polyline(trace_points, { color: 'grey' }).addTo(this.map);
    this.map.fitBounds(polyline.getBounds());

    // Add start, end markers
    L.marker(trace_points[0], {
      icon: L.icon({
        iconUrl: 'assets/marker/trip_start.svg',
        iconSize: [45, 50],
        iconAnchor: [23, 50],
      }), // here pass the custom marker icon instance,
      draggable: false,
    }).addTo(this.map);

    L.marker(trace_points[trace_points.length - 1], {
      icon: L.icon({
        iconUrl: 'assets/marker/trip_end.svg',
        iconSize: [45, 50],
        iconAnchor: [23, 50],
      }), // here pass the custom marker icon instance,
      draggable: false,
    }).addTo(this.map);

    this.addEvents();
    return this.routing;
  }

  extendMarker(i, waypoint, n) {
    if (i == 0 || i == n - 1) return null;

    return L.marker(waypoint.latLng, {
      icon: L.icon({ iconUrl: 'assets/marker/dot.png', iconSize: [19, 19] }),
      draggable: true,
    });
  }

  getFileNameFromUrl = (url: string) => {
    let chunks = url.split('/');
    let filename = chunks[chunks.length - 1];
    chunks = filename.split('?');
    return chunks[0];
  };

  videoDownload(video: any) {
    this.downloading = true;
    this.dataService.downloadTeltonikaVideos(video.uuid).subscribe(
      (res: any) => {
        if (res.download_url) {
          this._httpClient.get(res.download_url, { responseType: 'blob', observe: 'response' }).subscribe(
            (response: any) => {
              const filename = this.getFileNameFromUrl(res.download_url);
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response.body);
              const blob = new Blob(binaryData, { type: dataType });
              saveAs(blob, filename);
              this.downloading = false;
            },
            (err) => {
              this.downloading = false;
              console.log('Error while downloading');
            },
          );
        }
      },
      (err) => {
        this.downloading = false;
        console.log('Error while downloading');
      },
    );
  }

  private marker(event, iconInfo) {
    const myCustomColour = '#583470';

    let color;
    if (event.label_color) {
      color = event.label_color;
    } else {
      color = iconInfo.color ? iconInfo.color : myCustomColour;
    }
    const markerHtmlStyles = `
      background: url('${iconInfo.icon}') no-repeat;
      background-size: calc(100% - 5px);
      background-position-x: 2.5px;
      background-position-y: 2.5px;`;

    let icon = L.divIcon({
      className: 'custom-div-icon',
      html: `<div style='background-color: ${
        color
      };' class='marker-pin'></div>
      <span style="${markerHtmlStyles}" />`,
      iconSize: [40, 50],
      iconAnchor: [20, 50],
    });
    let marker = L.marker(L.latLng(event.gps_point.lat, event.gps_point.lon), {
      icon: icon,
    });

    marker.on('click', (e) => {
      this.removeHighlights();
      marker['video'] = event.video;
      marker['event'] = event;
      let fields_to_show = '';
      if (event.fields_to_show) {
        const shows = Object.keys(event.fields_to_show).map(
          (key) =>
            `<div class="leaflet-popup-body-show"><label>${this.translate.instant(key)}:</label><span>${this.translate.instant(event.fields_to_show[key])}</span></div>`,
        );
        fields_to_show = shows.join('');
      }
      let video = '';
      const downloadAction = `
        <div class="d-flex">
          <img src="/assets/images/download.png" id="download-btn" class="icon-img" style="cursor:pointer;" width="24" height="24" />
        </div>`;
      if (this.system === 'teltonika') {
        if (event.video?.url) {
          video = `<video id="video" style="margin:auto;" width="320" height="240" controls></video>`;
        } else {
          switch (event.video?.request_status) {
            case null:
            case undefined:
              video = `<button id="request-btn" class="btn btn-sm">${this.translate.instant('Request video')}</button>`;
              break;
            case 'requested_processing':
              video = `<button id="request-btn" class="btn btn-sm btn-secondary" disabled>${this.translate.instant('Video requested')}</button>`;
              break;
            case 'requested_available':
              video = `<button id="request-btn" class="btn btn-sm btn-secondary" disabled>${this.translate.instant('Video requested')}</button>`;
              break;
            case 'auto_requested_available':
              video = `<button id="request-btn" class="btn btn-sm btn-secondary" disabled>${this.translate.instant('Video requested')}</button>`;
              break;
            case 'auto_requested_processing':
              video = `<button id="request-btn" class="btn btn-sm btn-secondary" disabled>${this.translate.instant('Video requested')}</button>`;
              break;
            default:
              break;
          }
        }
      }

      if (event.fields_to_show || event.video) {
        if (!marker.getPopup()) {
          marker
            .bindPopup(
              `
            <div class="leaflet-popup-content-header d-flex"><strong class="flex-grow-1">${this.translate.instant(event.subparam)}</strong>${
                event.video?.request_status === 'requested_available' ||
                event.video?.request_status === 'auto_requested_available'
                  ? downloadAction
                  : ''
              }</div>
            <div class="leaflet-popup-content-body">
              ${fields_to_show}
              ${this.system === 'teltonika' ? video : ''}
            </div>`,
            )
            .openPopup();
        } else {
          marker.bindPopup(`
            <div class="leaflet-popup-content-header d-flex"><strong class="flex-grow-1">${event.subparam}</strong>${
            event.video?.request_status === 'requested_available' ||
            event.video?.request_status === 'auto_requested_available'
              ? downloadAction
              : ''
          }</div>
            <div class="leaflet-popup-content-body">
              ${fields_to_show}
              ${this.system === 'teltonika' ? video : ''}
            </div>`);
        }
      }
      if (event.duration && event.gps_trace) {
        this.highlight = L.polyline(
          event.gps_trace.lat.map((e, index) => {
            return L.latLng(e, event.gps_trace.lon[index]);
          }),
          { color: 'red' },
        );
        this.highlight.addTo(this.map);
      }
    });

    return marker;
  }
}
