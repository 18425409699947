<div class="toolbar">
  <h3>Survey</h3>
  <div ngbDropdown class="d-inline-block custom_dropdown">
    <button class="btn" id="dropdownBasic1" ngbDropdownToggle>{{systype | t}}</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button *ngFor="let st of stypes" (click)="systype=st;refresh()" ngbDropdownItem>{{st | t}}</button>
    </div>
  </div>
  <div ngbDropdown class="d-inline-block custom_dropdown" *ngIf="types.length">
    <button class="btn" id="dropdownBasic2" ngbDropdownToggle>{{type|t}}</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
      <button *ngFor="let t of types" (click)="getSurvey(t)" ngbDropdownItem>{{t|t}}</button>
    </div>
  </div>
  <div class="filler"></div>
</div>
<div *ngIf="!loading;else loader" class="surveycontainer mb-4">
  <div *ngFor="let q of surveyQuestions; let index=index" class="surveyquestion">
    <div class="questionnumbercont" [ngStyle]="{backgroundColor: q.number ? 'black' : 'transparent'}">
      {{q.number}}
    </div>
    <div class="questioncont" style="min-height: 80px;">
      <div [innerHTML]="q['questionText']"></div>
      <div style="display: flex;flex-direction: column; margin-top: 10px;">
        <ol>
          <li style="margin-top: 5px;" *ngFor="let c of q.choicesArray">
            {{c['choiceText']}}
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loading&&!type">
  No surveys for this category
</div>
<ng-template #loader>
  <div class="text-center blink">Loading...</div>
</ng-template>
