<div class="position">
  <div class="position__filters">
    <form [formGroup]="form">
      <div class="toolbar justify-content-start">
        <h3 translate>Vehicle</h3>
        <div ngbDropdown class="custom_dropdown">
          <button class="btn" ngbDropdownToggle>{{vehicle ? vehicle.license_plate: 'All Vehicles' }}</button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="selectUser(null)" *ngIf="vehicle">All Vehicles</button>
            <button *ngFor="let vehicle of vehicles" ngbDropdownItem (click)="selectUser(vehicle)">{{vehicle.license_plate}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="position__map">
    <div id="map"></div>
  </div>
</div>
