<div class="d-flex flex-row leaderboards" style="min-height: 100%; width: 100%;">

  <div class="sidebar">
    <h2 translate>Leaderboards</h2>
    <div class="custom_dropdown_label" translate>Target Audiences</div>
    <div ngbDropdown class="d-inline-block custom_dropdown">
      <button class="btn" ngbDropdownToggle style="width: 200px;">{{selectedGroup.name | translate}}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem style="padding-left: 15px;"
          (click)="groupUpdate(alldrivers)" translate>{{alldrivers.name | translate}}</button>
        <button ngbDropdownItem disabled style="color: black; padding-left: 15px;font-weight: bold;" translate>Group</button>
        <button *ngFor="let g of groups" ngbDropdownItem (click)="groupUpdate(g)">{{g.name}}</button>
        <button ngbDropdownItem disabled style="color: black; padding-left: 15px;font-weight: bold;" translate>Tags</button>
        <button *ngFor="let project_tag of project_tags" (click)="addTag(project_tag)" ngbDropdownItem>{{project_tag}}</button>
        <button ngbDropdownItem disabled style="color: black; padding-left: 15px;font-weight: bold;" translate>Transport Type</button>
        <button *ngFor="let g of types" ngbDropdownItem (click)="groupUpdate(g)">{{g.name}}</button>
      </div>
    </div>
    <div class="custom_dropdown_label" translate>Behavourial Phase</div>
    <div ngbDropdown class="d-inline-block custom_dropdown">
      <button class="btn" ngbDropdownToggle
        style="width: 200px;">{{psychological_profile_type_dict[psychological_profile[psyprofindex]]?psychological_profile_type_dict[psychological_profile[psyprofindex]]:psychological_profile[psyprofindex] | translate}}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button *ngFor="let p of psychological_profile; let i= index" (click)="psyprofUpdate(i)"
          ngbDropdownItem>{{psychological_profile_type_dict[p]?psychological_profile_type_dict[p]:p | translate}}</button>
      </div>
    </div>
    <div class="custom_dropdown_label" translate>Position</div>
    <div ngbDropdown class="d-inline-block custom_dropdown">
      <button class="btn" ngbDropdownToggle style="width: 200px;">{{periods[selectedperiodindex].label | translate}}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button *ngFor="let p of periods; let i= index" (click)="periodUpdate(i)" ngbDropdownItem translate>{{p.label}}</button>
      </div>
    </div>

  </div>
  <div style="flex: 1; padding-left: 35px;padding-right: 35px; width: 100%">
    <div class="toolbar">
      <div ngbDropdown class="d-inline-block custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{domains[domainselectedindex].label}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button *ngFor="let b of domains; let i= index" (click)="domainUpdate(i)" ngbDropdownItem>{{b.label}}</button>
        </div>
      </div>
    </div>
    <div class="tablescroll" style="max-height: 80vh; overflow-y: scroll;">
      <table style="width: 100%;min-width: 500px; ">
        <thead>
          <tr>
            <th></th>
            <th style="width: 150px" translate>Driver ID</th>
            <th translate>Scores</th>
            <th translate>Position</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i = index">
            <td>{{i+1}}</td>
            <td>
              <div style="display: flex;">

                <img style="flex:0;width: 50px; height: 50px;border-radius: 50%" src="/assets/images/face-1.png">
                <div style="flex: 1;display: flex;flex-direction: column;margin-left: 5px;">
                  <div style="white-space: nowrap">
                    {{item.first_name + ' ' + item.last_name}}
                  </div>
                  <div style="color: #7e7e7e;white-space: nowrap;">
                    ID #{{item.username ? item.username.split('@')[0] : ''}}
                  </div>
                </div>
              </div>
            </td>
            <td>{{floor(item.score)}}</td>
            <td>
              <div class="scoreblock"
                [style]=" 'background-color: '+(item.position_delta==0?'#f5a623;':(item.position_delta>0?'#19af28;':item.position_delta<0? '#ff0a0a;': '#8f8f8f;'))">
                {{item.position_delta ? abs(item.position_delta): '-'}}
                <i *ngIf="item.position_delta && item.position_delta!=0" style="margin-left: 5px;"
                  [class]="'fa fa-arrow-'+(item.position_delta>0?'up':'down')"></i>
              </div>
            </td>
          </tr>
          <tr *ngIf="loading">
            <td colspan="9" class="text-center blink"><span translate>Loading</span>...</td>
          </tr>
          <tr *ngIf="!loading && items.length==0">
            <td colspan="9" class="text-center">{{'No data available' | translate}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="leaderboard-description">
      <small translate>*A yellow box indicates the ranking has not changed. A green box indicates a ranking progression. A red box
        indicates a ranking regression.</small>
    </div>
  </div>
</div>
