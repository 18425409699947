<div class="modal-header">
  <h4 class="modal-title">Group Members Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pb-5">

  <ng-container *ngIf="(!sub || sub.closed); else loader">
    <table>
      <thead>
      <tr>
        <th>Driver</th>
        <th>Transport Type</th>
        <th>Behavioural Phase</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of items">
        <td>
          <div style="display: flex;">

            <img style="flex:0;width: 50px; height: 50px;border-radius: 50%" src="/assets/images/face-1.png">
            <div style="flex: 1;display: flex;flex-direction: column">
              <div>
                {{item.first_name}} {{item.last_name}}
              </div>
              <div style="color: #7e7e7e">
                {{item.system?.system_type && item.system[item.system?.system_type]?.driver_id ? 'ID #'+item.system[item.system?.system_type]?.driver_id : ''}}
              </div>
            </div>
          </div>
        </td>
        <td style="font-weight: 300">{{item.profile?.trucker_type}}</td>
        <td>
          <button (click)="removeDriverFromGroup(item)" type="button" class="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-template #loader>
    <div *ngIf="sub && !sub.closed" class="text-center blink">Loading...</div>
  </ng-template>

</div>
