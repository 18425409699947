import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { ActivatedRoute} from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import { combineLatest, Subscription } from "rxjs";

@Component({
  selector: 'app-forum-message',
  templateUrl: './forum-message.component.html',
  styleUrls: ['./forum-message.component.scss']
})
export class ForumMessageComponent implements OnInit, OnDestroy {

  message: any;
  users: any[] = [];

  id: number;

  newReaction: UntypedFormGroup;
  sub: Subscription;
  sendSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.id = params.id;

      this.newReaction = this.fb.group({
        body: ['', Validators.required]
      });

      this.loadMessage();
    });
  }



  getUser(id) {
    return this.dataService.getUser(id);
  }

  ngOnDestroy(): void {
    this.sub && this.sub.unsubscribe();
    this.sendSub && this.sendSub.unsubscribe();
  }

  like(msg: any) {
    const idx = msg.likes.findIndex(like => like.user_id == this.dataService.user.user_id);

    if (idx > -1) {
      msg.likes.splice(idx, 1);
      this.dataService.deleteLike(msg.message_id).subscribe();
    } else {
      msg.likes.push({user_id: this.dataService.user.user_id});
      this.dataService.postLike(msg.message_id).subscribe();
    }
  }

  private loadMessage() {
    this.sub = this.dataService.getMessages<any>({message_id: this.id}).pipe(
      switchMap(msg => {
        const uids = [msg.sender_id];
        msg.replies.forEach(reply => {
          uids.push(reply.sender_id);
        });

        const uniqueUids = uids.filter((value, index, array) => array.indexOf(value) === index);
        console.log(uniqueUids);
        const users$ = uniqueUids.map(uid => this.getUser(uid));

        return combineLatest(users$).pipe(
          map(users => {
            users.forEach(user => this.users[user.user_id] = user);
            return msg;
          })
        );

      })
    ).subscribe(msg => {
      this.message = msg;
    });
  }

  postReaction() {
    const body ={
      message_id: this.id,
      title: '',
      body: this.newReaction.value.body,
      sender_id: this.dataService.user.user_id
    };

    this.sendSub = this.dataService.postReaction(body).subscribe(result => {
      this.newReaction.reset();
      this.loadMessage();
    });
  }

  likeReaction(reaction: any) {
    const idx = reaction.likes.findIndex(like => like.user_id == this.dataService.user.user_id);

    if (idx > -1) {
      reaction.likes.splice(idx, 1);
      this.dataService.deleteReactionLike(reaction.reply_id).subscribe();
    } else {
      reaction.likes.push({user_id: this.dataService.user.user_id});
      this.dataService.postReactionLike(reaction.reply_id).subscribe();
    }
  }

  get sendDisabled() {
    return !this.newReaction.valid || (this.sendSub && !this.sendSub.closed);
  }

}
