import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewProductComponent } from './view-product/view-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  tabs = [];
  products = [];
  allprods = [];
  config = {};
  activecategory = null;
  searchstring = '';
  sub: Subscription;
  currentProdsCount = 0;
  currentLang: string;

  constructor(
    private dataService: DataService,
    private sanitizer: DomSanitizer,
    private modal: NgbModal,
    private t: TranslateService
  ) {
    this.currentLang = this.t.currentLang;
  }

  ngOnInit(): void {
    this.dataService.getProductConfig().subscribe(data => {
      this.config = data;
      this.tabs = [];
      Object.keys(data['categories']).map(key => {
        this.tabs.push({
          key: key,
          ...data['categories'][key]
        });
      });
      console.log(this.config);
      console.log(this.tabs);
    });
    this.getProds(null);
  }

  viewItem(item) {
    const ref = this.modal.open(ViewProductComponent, { size: 'lg' });
    ref.componentInstance.item = item;
    ref.componentInstance.tabs = this.tabs;

    ref.result.then((data) => {
      if (data === 'edit' || data === 'remove') this.ngOnInit();
    });
  }

  addProduct() {

    const ref = this.modal.open(EditProductComponent, { size: 'lg' });
    // ref.componentInstance.item = this.item;
    ref.componentInstance.create = true;
    ref.componentInstance.tabs = this.tabs;

    ref.result.then((data) => {
      this.ngOnInit();
    });

  }

  counts = {};

  getProds(t: any) {
    if (!t) {
      this.sub = this.dataService.getProduct(t).subscribe(data => {
        this.counts = {};
        data.map(prod => {
          if (prod.category) {
            if (!this.counts[prod.category]) {
              this.counts[prod.category] = 0;
            }
            this.counts[prod.category]++;

          }
        });
      });
    }
    this.activecategory = t;
    this.products = [];
    this.sub = this.dataService.getProduct(t).subscribe(data => {
      this.products = data;
      if (!t) {
        this.allprods = data;
      }
      for (let i = 0; i < this.products.length; i++) {
        let p = this.products[i];
        if (p.product_image) {
          this.dataService.getProductImage(p.product_id, p.product_image.image_id, 500, 500).subscribe(blob => {
            console.log(blob)
            if (this.products[i]) this.products[i]['imgurl'] = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
          });
        }
      }
    });
  }

  getCurrentProdsCount() {
    return this.activecategory ? this.counts[this.activecategory['key']] ? this.counts[this.activecategory['key']] : 0 : this.allprods.length;
  }

  getFilteredProducts() {
    const filtered = this.products.filter(o => {
      return o.title.indexOf(this.searchstring) > -1;
    });
    this.currentProdsCount = filtered.length;
    return filtered;
  }

  getImageSrc(item): string {
    const langSuffix =  this.currentLang === 'fr' ? '_fr' : '';
    const src = item.imgurl ? item.imgurl : `assets/images/no_img${langSuffix}.svg`;
    return src;
  }
}
