import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {WebConfigurationService} from './web-configuration.service';
import {map} from 'rxjs/operators';
import {StorageService} from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class WebConfigurationGuard implements CanActivate, CanActivateChild {


  constructor(private configurationService: WebConfigurationService, private storage: StorageService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getConfiguration();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getConfiguration();
  }

  private getConfiguration() {
    const project = this.storage.get('project');
    const projectId = project.configuration.project_id;
    if (!projectId) {
      return false;
    }
    const webConfiguration = this.configurationService.getValue();
    if (webConfiguration) {
      return true;
    }
    return this.configurationService.getConfigurationWeb(projectId).pipe(map((response) => {
      return true;
    }));
  }

}
