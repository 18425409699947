<div class="wrapper">
  <div class="row"
    *ngFor="let page of pages | paginate: { itemsPerPage: pageSize, currentPage: p[index], id: 'control' + index}">
    <div class="col-md-1 text-center">{{index}}</div>
    <div class="col-md-5">{{ page.description }}</div>
    <div class="col-md-2 text-center">
      <ng-container *ngFor="let item of pages">
        <img *ngIf="item.image" class="w-100" [src]="item.image" (click)="clickImage(item.image)">
      </ng-container>
    </div>
    <div class="col-md-2">
      <ng-container *ngFor="let item of pages">
        <img *ngIf="item.video" class="w-100" src="/assets/images/thumbnail.jpg" (click)="clickVideo(item.video)">
      </ng-container>
    </div>
    <div class="col-md-2 text-center">
      <img class="m-2 pointer mr-4" src="assets/images/ic_edit.svg" (click)="edit()">
      <img class="m-2 pointer mr-4" src="assets/images/ic_delete.svg" (click)="delete()">
    </div>
  </div>
  <!-- <div *ngIf="pages.length > 1" style="display: flex;align-items: center;justify-content: flex-end;">
    <pagination-controls [id]="'control'+ index" (pageChange)="pageChanged($event)"
      (pageBoundsCorrection)="pageChanged($event)" directionLinks="true" responsive="false" previousLabel='<'
      nextLabel='>' screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
      screenReaderCurrentLabel="You're on page">
    </pagination-controls>
  </div> -->
</div>
