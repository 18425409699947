import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../services/data.service';
import { Subscription } from 'rxjs';
import { StorageService } from '../../../services/storage.service';
@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.scss'],
})
export class AddDriverComponent implements OnInit {

  sub: Subscription;
  fname: any;
  lname: any;
  email: any;
  phone: any;
  address1: any;
  address2: any;
  city: any;
  postal: any;
  country: any;
  deactivated: boolean;
  @Output() passDeactivated: EventEmitter<any> = new EventEmitter();

  edit = true;

  project_id: number;
  user_id: any;
  transport_type: any;
  behaviour_phase: any;
  group: any;
  keyword: string = '';

  _label: any = 0;
  item: any;
  domains: string[];
  domain: any;
  safeId = '';
  psychological_profile_type_dict = {};
  list: string[] = [];
  trucker_type_dict = {};
  psychological_profile = ['none', 'precontemplation', 'contemplation', 'preparation', 'action', 'maintenance'];
  transport_profile = ['none', 'construction', 'distribution', 'heavy_haulage', 'long_haul_gt_300', 'long_haul_lt_300'];
  project_tags: string[] = [];
  user_tags: string[] = [];
  shown: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private storage: StorageService
  ) {

  }

  ngOnInit(): void {
    console.log(this.storage.get('project').configuration);

    this.psychological_profile_type_dict = this.storage.get('project').configuration.psychological_profile_type_dict;
    this.trucker_type_dict = this.storage.get('project').configuration.trucker_type_dict;

    this.project_id = this.storage.get('project').configuration.project_id;
    this.user_id = this.item.user_id;
    this.safeId = this.item['safe-t-id'];
    this.fname = this.item.first_name;
    this.lname = this.item.last_name;
    this.email = this.item.email;
    this.phone = this.item.phone_number;
    this.address1 = this.item.address.street;
    this.address2 = this.item.address.number;
    this.city = this.item.address.city;
    this.postal = this.item.address.postal_code;
    this.country = this.item.address.country;
    this.transport_type = this.item.profile.trucker_type;
    this.behaviour_phase = this.item.profile.psychological_profile[this.domain];
    this.group = this.item.communities.length ? this.item.communities[0].community_name : null;
    this.deactivated = !!this.item.deactivated_since;
    this.dataService.getProjectTags(this.project_id).subscribe(project_tags => {
      project_tags.forEach(project_tag => {
        this.project_tags.push(project_tag.tag_name);
      })
      this.list = this.project_tags;
    }, err => {
      console.log(err);
    })
    this.dataService.getUserTags(this.project_id, this.user_id).subscribe(user_tags => {
      user_tags.forEach(user_tag => {
        this.user_tags.push(user_tag.tag_name);
      })
    }, err => {
      console.log(err);
    })
  }

  getTransportTypeName(ttcode) {
    return this.trucker_type_dict[ttcode] ? this.trucker_type_dict[ttcode] : 'none';
  }

  getPsychoProfName(ppcode) {
    return this.psychological_profile_type_dict[ppcode] ? this.psychological_profile_type_dict[ppcode] : 'none';
  }

  saveDriver() {
    this.item.profile.psychological_profile[this.domain] = this.behaviour_phase;

    this.sub = this.dataService.saveUser(this.item.user_id, this.address1, this.address2, this.city, this.country, this.postal, this.phone).subscribe(data => {
      this.dataService.saveUserProfile(this.item.user_id, this.transport_type, this.item.profile.psychological_profile).subscribe(((data2) => {
        this.activeModal.close();
      }));
    })
  }

  toggleActivate() {
    this.sub = this.dataService.toggleActivateUser(this.item.user_id, this.deactivated).subscribe(data => {
      this.deactivated = !this.deactivated;
      this.passDeactivated.emit(this.deactivated);
    })
  }

  show() {
    this.shown = !this.shown;
  }

  search(e) {
    const val = e.toLowerCase();
    const temp = this.project_tags.filter(project_tag => {
      let tag = project_tag.slice(0, e.length);
      if (tag == val) {
        return project_tag;
      }
    })
    this.list = temp;
  }

  addTag(tag: string) {
    this.dataService.addUserTag(this.project_id, this.user_id, tag)
      .subscribe(res => {
        this.dataService.getUserTags(this.project_id, this.user_id)
          .subscribe(user_tags => {
            this.user_tags = [];
            user_tags.forEach(user_tag => {
              this.user_tags.push(user_tag.tag_name);
            })
          })
        this.dataService.getProjectTags(this.project_id)
          .subscribe(project_tags => {
            this.project_tags = [];
            project_tags.forEach(project_tag => {
              this.project_tags.push(project_tag.tag_name);
            })
          })
      })
  }

  deleteTag(tag: string) {
    this.dataService.deleteUserTag(this.project_id, this.user_id, tag)
      .subscribe(res => {
        this.dataService.getUserTags(this.project_id, this.user_id)
          .subscribe(user_tags => {
            this.user_tags = [];
            user_tags.forEach(user_tag => {
              this.user_tags.push(user_tag.tag_name);
            })
          })
      })
  }
}
