<div class="modal-header">
  <h4 class="modal-title"></h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="text-center mb-4">
    <img *ngIf="image" class="w-100" [src]="image" />
  </div>
</div>