import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, from, of, Subscription } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../../../services/data.service";
import { catchError, defaultIfEmpty, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AlertPopupComponent } from "../../../_shared/alert-popup/alert-popup.component";
import { AddFactComponent } from '../add-fact/add-fact.component';
import { ConfirmationComponent } from 'src/app/components/_shared/confirmation/confirmation.component';
import { VjsPlayerComponent } from 'src/app/components/_shared/vjs-player/vjs-player.component';
import { ImageViewerComponent } from 'src/app/components/_shared/imageViewer/image-viewer.component';

@Component({
  selector: 'app-fact-edit-item',
  templateUrl: './fact-edit-item.component.html',
  styleUrls: ['./fact-edit-item.component.scss']
})
export class FactsEditItemComponent implements OnInit, OnDestroy {

  @Input('item') item: any;
  @Input('index') index: number;
  @Output() callFunction: EventEmitter<any> = new EventEmitter();

  pageSize = 1;
  p = {};

  pages = [];


  filesToUpload = [];
  videoToUpload = [];
  sub: Subscription;

  constructor(
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    if (this.item) {
      this.pages = this.item.pages || [];
      let description_item = this.pages.find(item => item.description !== '');
      if (description_item) {
        let index = this.pages.indexOf(description_item);
        this.pages.splice(index, 1);
        this.pages.unshift(description_item);
      }
    }
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  edit() {
    const ref = this.modalService.open(AddFactComponent, { size: 'xl' });
    const c = ref.componentInstance as AddFactComponent;
    c.item = this.item;

    ref.result.then(result => {
      if (result == 'saved') {
        this.updateData();
      }
    }).catch(result => {})
  }

  delete() {
    let ref = this.modalService.open(ConfirmationComponent);
    let component = ref.componentInstance as ConfirmationComponent;
    component.title = `Remove fact`;
    let summary = this.item.pages[0].description.substring(0, 15);
    component.message = `Are you sure you want to remove fact "${summary} ..."?`;
    ref.result.then(result => {
      if (result == 'yes') {
        this.dataService.removeFact({ "known_id": this.item.known_id }).subscribe(() => {
          this.updateData();
        });
      }
    }).catch(() => { });

  }

  clickImage(img) {
    if (img) {
      let ref = this.modalService.open(ImageViewerComponent);
      let component = ref.componentInstance as ImageViewerComponent;
      component.image = img;
    }
  }

  clickVideo(video) {
    if (video) {
      let ref = this.modalService.open(ConfirmationComponent);
      let component = ref.componentInstance as ConfirmationComponent;
      component.title = `Download`;
      component.message = 'Are you sure you want to download this video?';
      ref.result.then(result => {
        if (result == 'yes') {
          window.open(video, '_blank');
        }
      }).catch(() => { });
    }
  }

  pageChanged($event: number) {
    this.p[this.index] = $event;
  }

  updateData() {
    this.callFunction.emit();
  }
}
