<div class="card p-4">
  <ngb-toast
    *ngIf="isToastVisible"
    class="bg-success text-light"
    [autohide]="true"
  >
    Driver invited
  </ngb-toast>
	<div class="d-flex align-items-center mb-2">
		<h2 class="m-0">Project Drivers</h2>
		<button class="btn btn-primary ml-auto" (click)="openModal('creating')">Add Project Driver</button>
	</div>
	<table class="table table-striped">
		<thead>
			<tr>
        <th class="text-left">
          <span translate>Short ID</span>
        </th>
        <th class="text-left">
          <span>Short Name</span>
        </th>
				<th class="text-left cursor-pointer" (click)="toggleSort('first_name')">
					<span translate>First Name</span>&nbsp;
					<i class="fa" [ngClass]="getSortClass('first_name')" aria-hidden="true"></i>
				</th>
				<th class="text-left cursor-pointer" (click)="toggleSort('last_name')">
					<span translate>Last Name</span>&nbsp;
					<i class="fa" [ngClass]="getSortClass('last_name')" aria-hidden="true"></i>
				</th>
				<th class="text-left cursor-pointer" (click)="toggleSort('email')">
					<span translate>Email</span>&nbsp;
					<i class="fa" [ngClass]="getSortClass('email')" aria-hidden="true"></i>
				</th>
				<th></th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let driver of getDrivers()">
				<td class="text-left">{{ driver.short_id }}</td>
				<td class="text-left">{{ driver.short_name }}</td>
				<td class="text-left">{{ driver.first_name }}</td>
				<td class="text-left">{{ driver.last_name }}</td>
				<td class="text-left">{{ driver.email }}</td>
				<td class="cursor-pointer">
					<div class="d-flex align-items-center">
						<i class="fa fa-pen mr-2" (click)="openModal('updating', driver)"></i>
						<i class="fa fa-paper-plane mr-2" (click)="inviteUser(driver)"></i>
						<i class="fa fa-link" (click)="magicLink(driver)"></i>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
