<div class="modal-actions">
  <div style="display: flex; justify-content: flex-end;">
    <img (click)="editProduct()" src="assets/images/ic_edit.svg" class="pencil-ico">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-header">
  <strong translate>Product Details</strong>
</div>
<div class="modal-body pb-5">
  <div style="display: flex;">
    <div class="product-image-container">
      <img [src]="item.imgurl" class="view-product-image">
    </div>
    <div class="view-product-image-info">
      <div style="display: flex;flex-direction: column; flex: 1;">
        <div class=" view-product-header">
          <span class="modal-title" style="font-weight: 300;">{{item.title}}</span>
          <strong>{{item.credits}} <span translate>Credits</span></strong>
        </div>
        <div class="view-product-description">{{item.description}}
        </div>
        <div style="padding: 10px 0;">
          <strong translate>Category<span>:</span></strong>&nbsp;<span class="view-product-category">{{getCategoryDisplayName(item.category).display_name | translate}}</span>
        </div>
      </div>
      <div (click)="removeProduct()" class="view-product-delete" translate>delete product from shop</div>
    </div>
  </div>
</div>
