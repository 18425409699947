export enum ProjectType {
  all = 'all',
  oseven = 'oseven',
  teltonika = 'teltonika',
}

export type ProjectTypeKey = keyof typeof ProjectType;

export enum Order {
  none = 'none',
  asc = 'asc',
  desc = 'desc',
}

export enum ModalType {
  creating = 'creating',
  updating = 'updating',
}

export type Sort = {
  field: string;
  order: Order;
};

export type Project = {
  project_id: number;
  project_uuid: string,
  project_name: string;
  project_display_name: string;
  project_type: string;
  api_key?: string;
  base_url?: string;
  project_url?: string;
  vehicle_type: string;
  language: string;
  auto_accept_trip_hours: number;
  auto_reject_trip_hours_start: string;
  auto_reject_trip_hours_end: string;
  auto_reject_trip_days_of_week: number[];
  available_known_languages: string[];
  domains: string[];
};

export type ProjectRequestPayload = {
  project_id: number;
  project_uuid: string,
  project_name: string;
  project_display_name: string;
  vehicle_type: string;
  language: string;
  available_known_languages: string[];
  auto_accept_trip_hours: number;
  auto_reject_trip_hours_start: string;
  auto_reject_trip_hours_end: string;
  auto_reject_trip_days_of_week: number[];
};

// todo:: rename
export type ProjectShort = {
  project: {
    project_uuid: string,
  }
};
export type UserProject = {
  user_id: string,
  short_id: string,
  short_name: string,
  first_name: string,
  last_name: string,
  driver_uuid: string,
  beacon_id: string,
  email: string,
  projects: ProjectShort[],
};


export type User = {
  user_id: string;
  first_name: string;
  last_name: string;
  short_name?: string;
  email: string;
  system: {
    system_type: string;
    [key: string]:
      | string
      | {
      short_id: string;
    };
  };
};

export type CreateUser = Partial<User> & {
  short_name?: string;
};

export type CreateUserData = Partial<User> & {
  project: {
    project_id: string;
    role: string;
  };
};

export type EditUser = Partial<User> & {
  user_id?: string,
  short_id?: string,
  short_name?: string,
};

export type InviteUserData = {
  project_id: string;
  user_short_ids?: string[];
  user_id?: string;
  invitation_type?: 'mail' | 'sms';
};

export type ProjectForm = Partial<Project> & {
  auto_accept_trip: 'Yes' | 'No';
};

export type CreateProject = Omit<Project, 'auto_reject_trip_days_of_week'> & {
  auto_reject_trip_days_of_week: number[];
};


export interface MenuItem {
  label: string;
  url: string;
  exact?: boolean;
  configProperty?: string;
  disabled?: boolean;
}
