<div class="modal-header">
  <h4 class="modal-title"><span translate>BADGES</span></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pb-5">
  <div class="mx-3 overflow-x-scroll">
    <ng-container *ngIf="(!sub || sub.closed); else loader">
      <table *ngIf="params.length">
        <thead>
          <tr>
            <th *ngFor="let param of params"><span translate>{{param|t}}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of subparams">
            <td *ngFor="let cell of row">
              <ng-container *ngIf="cell">
                <div class="submenu-item-label-wrapper">
                  <span class="flex-span" [ngStyle]="{ 'background-color': cell.color }">
                    <img src={{cell.icon}} class="icon-img" width="24" height="24" />
                    <span><span translate>{{cell.name|t}}</span></span>
                  </span>
                </div>

                <img class="m-1 pointer" [ngbTooltip]="(cell.awarded_date || {}).bronze"
                  [src]="!(cell.level || []).includes('bronze') ? 'assets/images/losebadge.svg' : 'assets/images/winbadge.svg'">
                <img class="m-1 pointer" [ngbTooltip]="(cell.awarded_date || {}).silver"
                  [src]="!(cell.level || []).includes('silver') ? 'assets/images/losebadge.svg' : 'assets/images/winbadge.svg'">
                <img class="m-1 pointer" [ngbTooltip]="(cell.awarded_date || {}).gold"
                  [src]="!(cell.level || []).includes('gold') ? 'assets/images/losebadge.svg' : 'assets/images/winbadge.svg'">
                <img class="m-1 pointer" [ngbTooltip]="(cell.awarded_date || {}).platinum"
                  [src]="!(cell.level || []).includes('platinum') ? 'assets/images/losebadge.svg' : 'assets/images/winbadge.svg'">
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!params.length" class="text-center"><span translate>No Badges to display</span></div>
    </ng-container>

    <ng-template #loader>
      <div *ngIf="sub && !sub.closed" class="text-center blink"><span translate>Loading</span>...</div>
    </ng-template>
  </div>

</div>