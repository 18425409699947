import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { UserComponent } from './components/user/user.component';
import { MainComponent } from './components/main/main.component';
import { AppComponent } from './components/app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DriversComponent } from './components/drivers/drivers.component';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LeaderboardsComponent } from './components/leaderboards/leaderboards.component';
import { AuthService } from './services/auth.service';
import { AppConfig } from './app.config';
import { JwtInterceptor } from './services/jwt.interceptor';
import { UserGuard } from './services/user.guard';
import { ChooseProjectComponent } from './components/choose-project/choose-project.component';
import { GamificationComponent } from './components/gamification/gamification.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { StorageService } from './services/storage.service';
import { DriverBadgesComponent } from './components/drivers/driver-badges/driver-badges.component';
import { DriverLevelsComponent } from './components/drivers/driver-levels/driver-levels.component';
import { DoughnutComponent } from './components/_shared/doughnut/doughnut.component';
import { GoalsComponent } from './components/gamification/goals/goals.component';
import { ProsnconsComponent } from './components/gamification/prosncons/prosncons.component';
import { TipsEditComponent } from './components/gamification/tips/tips-edit.component';
import { CreditsComponent } from './components/gamification/credits/credits.component';
import { ShopComponent } from './components/gamification/shop/shop.component';
import { SurveyComponent } from './components/gamification/survey/survey.component';
import { PhasesComponent } from './components/gamification/phases/phases.component';
import { EditGoalsModalComponent } from './components/gamification/goals/edit-goals-modal/edit-goals-modal.component';
import { AddProsnconsComponent } from './components/gamification/prosncons/add-prosncons/add-prosncons.component';
import { ResultsComponent } from './components/results/results.component';
import { ChartModule } from 'angular2-chartjs';
import { ForumComponent } from './components/forum/forum.component';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload/drag-drop-file-upload.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation/click-stop-propagation.directive';
import { ForumMessageComponent } from './components/forum-message/forum-message.component';
import { SplitPipe } from './pipes/split.pipe';
import { IsLikedPipe } from './pipes/is-liked.pipe';
import { SenderPipe } from './pipes/sender.pipe';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AuthImagePipe } from './pipes/auth-image.pipe';
import { TipsComponent } from './components/gamification/tips/tips.component';
import { ProsnconseditComponent } from './components/gamification/prosncons/prosnconsedit/prosnconsedit.component';
import { AlertPopupComponent } from './components/_shared/alert-popup/alert-popup.component';
import { ConfirmationComponent } from './components/_shared/confirmation/confirmation.component';
import { ContextMenuModule, ContextMenuService } from 'ngx-contextmenu';
import { ViewGroupComponent } from './components/drivers/view-group/view-group.component';
import { EditGroupComponent } from './components/drivers/edit-group/edit-group.component';
import { TreeviewModule } from 'ngx-treeview';
import { ViewProductComponent } from './components/gamification/shop/view-product/view-product.component';
import { EditProductComponent } from './components/gamification/shop/edit-product/edit-product.component';
import { AddTipComponent } from './components/gamification/tips/add-tip/add-tip.component';
import { AddGroupComponent } from './components/drivers/add-group/add-group.component';
import { AddDriverComponent } from './components/drivers/add-driver/add-driver.component';
import { AddQuestionComponent } from './components/gamification/survey/add-question/add-question.component';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { ContactComponent } from './components/contact/contact.component';
import { ParamGoalsComponent } from './components/gamification/goals/param-goals/param-goals.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { TripMapComponent } from './components/results/shared/trip-map.component';
import { ReportsComponent } from './components/results/reports/reports.component';
import { TripsComponent } from './components/results/trips/trips.component';
import { ScoresComponent } from './components/results/scores/scores.component';
import { VjsPlayerComponent } from './components/_shared/vjs-player/vjs-player.component';
import { VideoPopupComponent } from './components/_shared/video-popup/video-popup.component';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FactsEditComponent } from './components/gamification/facts/facts-edit.component';
import { FactsComponent } from './components/gamification/facts/facts.component';
import { AddFactComponent } from './components/gamification/facts/add-fact/add-fact.component';
import { TipEditItemComponent } from './components/gamification/tips/tip-edit-item/tip-edit-item.component';
import { ProsConsEditItemComponent } from './components/gamification/prosncons/prosncons-edit-item/prosncons-edit-item.component';
import { FactsEditItemComponent } from './components/gamification/facts/facts-edit-item/fact-edit-item.component';
import { ImageViewerComponent } from './components/_shared/imageViewer/image-viewer.component';
import { AuthVideoPipe } from './pipes/auth-video.pipe';
import { NgxTranslateModule } from './translate/translate.module';
import { ProgressBarComponent } from './components/_shared/progress-bar/progress-bar.component';
import { VideosComponent } from './components/results/videos/videos.component';
import { VideoInfoComponent } from './components/results/videos/video-info/video-info.component';
import { AdminProjectsComponent } from './components/admin/projects/admin-projects.component';
import { AdminProjectComponent } from './components/admin/project/admin-project.component';
import { ProjectLeadersComponent } from './components/admin/project/leaders/project-leaders.component';
import { ProjectDriversComponent } from './components/admin/project/drivers/project-drivers.component';
import { AdminProjectModalComponent } from './components/admin/modals/project-modal/admin-project-modal.component';
import { AdminUserModalComponent } from './components/admin/modals/user-modal/admin-user-modal.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DriversPositionComponent } from './components/position/drivers-position/drivers-position.component';
import { VehiclesPositionComponent } from './components/position/vehicles-position/vehicles-position.component';
import { PositionsComponent } from './components/position/positions.component';
import { AttachProjectLeaderModalComponent } from './components/admin/modals/attach-project-leader-modal/attach-project-leader-modal.component';
import { DomainPipe } from './pipes/domain.pipe';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IncidentsComponent } from './components/results/incidents/incidents.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    MainComponent,
    DriversComponent,
    LeaderboardsComponent,
    ChooseProjectComponent,
    GamificationComponent,
    DriverBadgesComponent,
    DriverLevelsComponent,
    DoughnutComponent,
    TripMapComponent,
    GamificationComponent,
    GoalsComponent,
    ProsnconsComponent,
    TipsComponent,
    TipsEditComponent,
    FactsComponent,
    FactsEditComponent,
    AddFactComponent,
    FactsEditItemComponent,
    CreditsComponent,
    ShopComponent,
    SurveyComponent,
    PhasesComponent,
    EditGoalsModalComponent,
    AddProsnconsComponent,
    ProsConsEditItemComponent,
    AddQuestionComponent,
    ResultsComponent,
    ReportsComponent,
    TripsComponent,
    ScoresComponent,
    VideosComponent,
    ForumComponent,
    ResultsComponent,
    DragDropFileUploadDirective,
    ClickStopPropagationDirective,
    ClickStopPropagationDirective,
    ForumMessageComponent,
    SplitPipe,
    IsLikedPipe,
    SenderPipe,
    AuthImagePipe,
    AuthVideoPipe,
    ViewGroupComponent,
    EditGroupComponent,
    AddGroupComponent,
    AddDriverComponent,
    ViewProductComponent,
    EditProductComponent,
    ProsnconseditComponent,
    SanitizeUrlPipe,
    AlertPopupComponent,
    VjsPlayerComponent,
    VideoPopupComponent,
    ConfirmationComponent,
    ImageViewerComponent,
    AddTipComponent,
    TipEditItemComponent,
    ContactComponent,
    ParamGoalsComponent,
    TermsandconditionsComponent,
    PrivacypolicyComponent,
    ProgressBarComponent,
    VideoInfoComponent,
    AdminProjectsComponent,
    AdminProjectComponent,
    ProjectLeadersComponent,
    ProjectDriversComponent,
    AdminProjectModalComponent,
    AdminUserModalComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DriversPositionComponent,
    VehiclesPositionComponent,
    PositionsComponent,
    AttachProjectLeaderModalComponent,
    DomainPipe,
    IncidentsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    HttpClientModule,
    ChartModule,
    NgxFileDropModule,
    NgxTranslateModule,
    ContextMenuModule,
    TreeviewModule.forRoot(),
    LeafletDrawModule,
    NgxExtendedPdfViewerModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    AuthService,
    AppConfig,
    NgbActiveModal,
    NgbModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    UserGuard,
    StorageService,
    ContextMenuService,
    SplitPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
