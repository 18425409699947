<div class="container-fluid">
  <div class="toolbar justify-content-start">
    <a [routerLink]="['/forum']" class="h2 mr-0"><i class="fas fa-chevron-left"></i></a>
    <h2 translate>FORUM</h2>
    <div class="blink text-center" *ngIf="(sub && !sub.closed)"><span translate>Loading</span>...</div>
    <div class="filler"></div>
  </div>

  <div *ngIf="message" class="message-list-item d-flex">


    <div class="message-body">
      <div class="d-block d-lg-flex align-items-center">

        <div class="d-flex align-items-center flex-grow-1 order-1 flex-wrap">
          <div class="avatar-small mr-2" style="background-image: url('/assets/images/face-1.png')"> </div>
          <div class="h4 mb-0 font-weight-bold flex-grow-1" *ngIf="users[message.sender_id]">{{users[message.sender_id]|sender}}</div>
          <div class="message-timestamp text-secondary" *ngIf="message">
            {{(message.created_date+'Z')|date:'medium'}}
          </div>
        </div>
        <h2 class="ml-0 my-0 order-0">{{message?.title}}</h2>
      </div>
      <div>{{message?.body}}</div>

      <div class="msg-thumb-container" lightbox-group>
        <img *ngFor="let img of message.images" [src]="[message.message_id, img.image_id]|authImage|async" lightbox
          class="msg-thumb">
      </div>

      <div class="msg-thumb-container" lightbox-group>
        <video *ngFor="let video of message.videos" controls muted playsinline preload="none"
          [src]="[message.message_id, video.video_id]|authVideo|async"></video>
      </div>

      <div class="d-flex mt-3 justify-content-between">

        <!--        <div class="message-counter ml-3">-->
        <!--          <i class="fas fa-eye"></i> 64-->
        <!--        </div>-->
        <div class="message-counter" *ngIf="message">
          <i class="fas fa-heart pointer" [ngClass]="{'gray500': !(message && (message|isLiked))}"
            (click)="like(message)"></i> {{message.likes.length}}
          Like{{message.likes.length == 1 ? '' : 's' }}
        </div>
        <div class="message-counter" *ngIf="message">
          <i class="fas fa-comment gray500"></i> {{message.replies.length}} Comment{{message.replies.length == 1 ? '' :
          's'}}
        </div>
      </div>
    </div>

  </div>

  <div class="row">

    <div class="col-12 col-md-6 pl-5 mt-4">
      <ng-container *ngIf="message">
        <h5 class="mb-1">Recent Comments</h5>
        <div class="text-center" *ngIf="!message.replies.length" translate>No recent comments to display...</div>
        <div class="reactions-list" *ngFor="let reaction of message.replies">
          <div class="avatar-small mr-4" style="background-image: url('/assets/images/face-1.png')"></div>
          <div class="reaction">
            <div class="message-body w-100">
              <div class="flex-grow-1">
                {{reaction.body}}
              </div>
              <div class="d-flex mt-3">
                <div class="message-timestamp flex-grow-1">
                  <span translate>Posted on</span> {{(reaction.created_date+'Z')|date:'medium'}} <span translate>by</span> {{users[reaction.sender_id]|sender}}
                </div>

                <!--                <div class="message-counter ml-3">-->
                <!--                  <i class="fas fa-comment"></i> 4-->
                <!--                </div>-->

                <div class="message-counter ml-3" *ngIf="reaction">
                  <i class="fas fa-heart pointer" [ngClass]="{'gray500': !(reaction && (reaction|isLiked))}"
                    (click)="likeReaction(reaction)"></i> {{reaction.likes.length}}
                </div>
              </div>
            </div>


            <!--          <div class="reactions-list">
                            ...
                          </div>-->


          </div>

        </div>
      </ng-container>
    </div>


    <div class="col-12 col-md-6 mt-4">

      <div class="react-form">
        <h5 class="mb-1" translate>Write a Comment</h5>

        <form class="mb-4" [formGroup]="newReaction" (ngSubmit)="postReaction()">
          <textarea class="form-control" name="body" formControlName="body" rows="5"
            placeholder="{{'Message text' | translate}}"></textarea>

          <div class="text-right">
            <button class="btn btn-warning text-white font-weight-bold px-4 my-2"
              [disabled]="sendDisabled" translate>SEND</button>
          </div>

        </form>

      </div>

    </div>
  </div>




</div>
