<div class="popupcontainer">

  <div class="modal-header">
    <h4 class="modal-title"><span translate>{{item ? 'Group Details' : 'Add Group'}}</span></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pb-5">

    <ng-container *ngIf="(!sub || sub.closed); else loader">
      <div style="display: flex;flex-direction: column;">
        <!-- <div class="flex-group">
          <div class="group-title">Title</div>
          <input [(ngModel)]="title" class="form-control">
        </div>
        <div class="flex-group" style="flex-direction: row;align-items: center;">
          <div class="group-title">Invite only</div>
          <input [(ngModel)]="invite_only" type="checkbox" style="margin-left: 15px;">
        </div>
-->
      </div>
      <div class="group-content">

        <div class="flex-group">
          <div>
            <div class="group-title"><span translate>Group Category</span></div>
            <input [(ngModel)]="title" class="form-control" placeholder="{{'Select a category' | translate}}">
          </div>
          <div>
            <div class="group-title"><span translate>Team</span></div>
            <input [(ngModel)]="description" class="form-control" placeholder="{{'Select a team' | translate}}">
          </div>
        </div>

        <div class="flex-group">
          <div class="group-title"><span translate>Group Members</span></div>
          <div class="group-gray">
            <input class="form-control bordered-input" placeholder="{{'Search' | translate}}" [(ngModel)]="searchUserTerm">
            <div class="group-gray-bg" *ngIf="!filterUsers().length">
              <div class="user-row custom-control">
                <label class="d-flex align-items-center">
                  <div><span translate>No matching groups</span></div>
                </label>
              </div>
            </div>
            <div class="group-gray-bg" *ngIf="filterUsers().length">
              <div class="user-row form-check" *ngFor="let u of filterUsers()">
                <input type="checkbox" class="form-check-input" [checked]="isUserInGroup(u)"
                  (change)="userToggle($event,u.user_id);" id="u{{u.user_id}}">
                <label for="u{{u.user_id}}" class="d-flex align-items-center form-check-label">
                  <img src="assets/images/face-1.png" />
                  <div>{{u.first_name + ' ' + u.last_name}}</div>
                </label>
              </div>
            </div>

          </div>
        </div>
        <div class="flex-group">
          <div class="group-title"><span translate>Will be working on</span></div>
          <div class="group-gray-bg grey-bg-filter">

            <ngx-treeview #checkboxtree [config]="config" [items]="items" (selectedChange)="onSelectedChange($event)"
              (filterChange)="onFilterChange($event)">
            </ngx-treeview>
          </div>
        </div>
      </div>
      <!--<div class="flex-group">
        <div class="group-title">Description</div>
        <textarea [(ngModel)]="description" class="form-control"></textarea>
      </div>-->
      <div class="text-center" style="margin-top: 15px; margin-right: 15px;">
        <button class="btn btn-warning text-white font-weight-bold px-4" (click)="submitSchema()"><span translate>SAVE</span></button>
      </div>

    </ng-container>

    <ng-template #loader>
      <div *ngIf="sub && !sub.closed" class="text-center blink"><span translate>Loading</span>...</div>
    </ng-template>

  </div>

</div>