<ng-template #load>
  <div class="text-center blink mt-5"><span translate>Loading</span>...</div>
</ng-template>

<div class="reportspage">

  <div class="page-header">
    <h2 translate>Scores</h2>
    <div class="toolbar" style="justify-content: flex-start; margin-top: 10px;">

      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{ (type && typeLabel ? typeLabel : 'Unknown') | translate}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="type = 'general'"><span translate>General Indicators</span></button>
          <button ngbDropdownItem (click)="type = 'domain'"><span translate>Domain Scores</span></button>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown" *ngIf="type == 'domain'">
        <button class="btn" ngbDropdownToggle>{{domain|t}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem *ngFor="let d of domains" (click)="domain = d">{{d|t}}</button>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="dd/mm/yyyy" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">
        <button class="btn" ngbDropdownToggle>{{(target && !target.label.includes('null') ? target.label : 'Anonymous User') | translate}}</button>
        <div ngbDropdownMenu class="max-height-350 overflow-y-auto">
          <button ngbDropdownItem (click)="setTargetAudience('All Drivers')" class="pl-2"><strong translate>All Drivers</strong></button>
          <button ngbDropdownItem (click)="setTargetAudience('Drivers')" class="pl-2"><strong translate>Drivers</strong></button>
          <button ngbDropdownItem *ngFor="let u of sortUser(users)" (click)="setTargetUser(u)" class="pl-4">
            {{ u.system.teltonika && u.system.teltonika.short_name && u.system.teltonika.short_name.length > 0 ? u.system.teltonika.short_name : (u.first_name || '') + ' ' + (u.last_name || '') }}
          </button>
          <button ngbDropdownItem (click)="setTargetAudience('Groups')" class="pl-2"><strong translate>Groups</strong></button>
          <button ngbDropdownItem *ngFor="let group of sortGroup(groups)" (click)="setTargetGroup(group)" class="pl-4">
            {{ group.name }}
          </button>
          <button ngbDropdownItem (click)="setTargetAudience('Tags')" class="pl-2"><strong translate>Tags</strong></button>
          <button ngbDropdownItem *ngFor="let project_tag of sortGroup(project_tags)" (click)="setTargetTag(project_tag)" class="pl-4">
            {{ project_tag.tag_name }}
          </button>
        </div>
      </div>

      <!--      <div ngbDropdown class="d-inline-block custom_dropdown">-->
      <!--        <button class="btn" id="dropdownTargetAudience" ngbDropdownToggle>{{targetAudience}}</button>-->
      <!--        <div ngbDropdownMenu aria-labelledby="dropdownTargetAudience">-->
      <!--          <button ngbDropdownItem>Day</button>-->
      <!--        </div>-->
      <!--      </div>-->

      <div ngbDropdown class="d-inline-block custom_dropdown">
        <button class="btn" id="dropdownTimeInterval" ngbDropdownToggle>{{periods[period]['title'] | translate }}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownTimeInterval">
          <button ngbDropdownItem (click)="setPeriod('day')">{{periods['day']['title'] | translate }}</button>
          <button ngbDropdownItem (click)="setPeriod('week')">{{periods['week']['title'] | translate }}</button>
          <button ngbDropdownItem (click)="setPeriod('month')">{{periods['month']['title'] | translate }}</button>
          <button ngbDropdownItem (click)="setPeriod('3month')">{{periods['3month']['title'] | translate }}</button>
          <button ngbDropdownItem (click)="setPeriod('year')">{{periods['year']['title'] | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="sidebar">

      <ng-container *ngIf="type == 'general'">
        <div class="menuitem-wrapper">
          <div class="boldtitle titleunderline pointer"><span translate>General Indicators</span></div>
          <div class="subparams">
            <div *ngFor="let menuItem of generalMenu" class="pointer w-100 d-inline-block">
              <label class="submenu-item">
                <div class="submenu-item-label-wrapper general"
                  [ngStyle]="{ 'background-color': menuItem.color, 'cursor': 'pointer' }"
                  [routerLink]="['/results/scores']" [queryParams]="{id: menuItem.id}" queryParamsHandling="merge">
                  <span class="flex-span" style="">
                    <img src={{menuItem.icon}} class="icon-img" width="24" height="24" />
                    <span>{{menuItem.name|t | translate}} <img *ngIf="menuItem.id == chartId"
                        src='assets/images/rounded-check-mark-white.svg'
                        style="margin-left: 10px; height: 80%;" /></span>

                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="type == 'domain'">
        <ng-container *ngFor="let menuItem of menu">
          <ng-container *ngIf="menuItem.label == domain">

            <div class="menuitem-wrapper">
              <div class="boldtitle titleunderline pointer" [routerLink]="['/results/scores']"
                [queryParams]="{id: menuItem.id}" queryParamsHandling="merge">{{menuItem.name|t | translate}} <img
                  *ngIf="menuItem.id == chartId" src='assets/images/rounded-check-mark-black.svg'
                  style="margin-left: 10px; height: 20px;" /></div>

              <div class="subparams"
                *ngFor="let subMenuItem of menuItem.children">
                <label class="submenu-item" *ngIf="subMenuItem.children.length">
                  <div class="submenu-item-label-wrapper"
                    [ngStyle]="{ color: 'black', 'cursor': 'pointer', 'border': '1px solid', 'border-color': 'black' }"
                    [routerLink]="['/results/scores']" [queryParams]="{id: subMenuItem.id}" queryParamsHandling="merge">
                    <span class="flex-span" style="">
                      <span>{{subMenuItem.name|t | translate}} <img *ngIf="subMenuItem.id == chartId"
                          src='assets/images/rounded-check-mark-black.svg'
                          style="margin-left: 10px; height: 20px;" /></span>
                    </span>
                  </div>
                </label>
                <div class="subparams">
                  <div *ngFor="let sub2menuitem of subMenuItem.children" class="pointer w-100 d-inline-block">
                    <label class="submenu-item">
                      <div class="submenu-item-label-wrapper"
                        [ngStyle]="{ 'background-color': subMenuItem.color, 'cursor': 'pointer', 'margin-top': '5px', 'color' : 'white' }"
                        [routerLink]="['/results/scores']" [queryParams]="{id: sub2menuitem.id}"
                        queryParamsHandling="merge" [ngClass]="{'disabled': isDisabled(sub2menuitem)}">
                        <span class="flex-span" style="">
                          <img src={{sub2menuitem.icon}} class="icon-img" width="24" height="24" />
                          <span>{{sub2menuitem.name|t | translate}} <img *ngIf="sub2menuitem.id == chartId"
                              src='assets/images/rounded-check-mark-black.svg'
                              style="margin-left: 10px; height: 80%;" /></span>

                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="resultscontainer">
      <ng-container *ngIf="!loader && !errorFound; else load">
        <div class="container-content">
          <div class="chartcontainer" *ngIf="chartCfg">
            <h2><img [src]="getImage()" style="margin-right: 5px;">{{chartCfg.label|t | translate}}</h2>
            <chart *ngIf="chartData && chartData !='loading'" type="bar" [data]="chartData" [options]="options"></chart>
            <!--      <chart id="chart"  style="height: 300px;" [type]="charttype" [data]="data" [options]="options"></chart>-->
          </div>
          <small style="font-style:italic;margin-left: 25px;" *ngIf="period !== 'day'">* not full calendar
            {{period}}</small>
        </div>
      </ng-container>
    </div>
  </div>



</div>

<ng-template #rt let-r="result" let-t="term">
  <div class="text-left">
    <i style="width: 30px; text-align: center; color: silver" class="fas"
      [ngClass]="{'fa-users': r.prefix == 'Group', 'fa-user': r.prefix == 'User'}"></i>
    <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
  </div>
</ng-template>
