<div class="modal-header">
	<h4 class="modal-title" id="modal-title">{{ getModalTitle() }}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<form [formGroup]="projectForm">
		<div class="form-group">
			<label for="name">Project Name</label>
			<input id="name" class="form-control" name="name" formControlName="project_name" />
		</div>

		<div class="form-group">
			<label for="type">Project Type</label>
			<select class="form-control" id="type" formControlName="project_type">
				<option value="oseven">OSeven</option>
				<option value="teltonika">Teltonika</option>
			</select>
		</div>

		<div class="form-group">
			<label for="ata">Automatic Trip Acceptance</label>
			<select class="form-control" id="ata" formControlName="auto_accept_trip">
				<option value="Yes">Yes</option>
				<option value="No">No</option>
			</select>
		</div>

		<div *ngIf="projectForm.value.auto_accept_trip === 'No'" class="form-group">
			<label for="ataTime">Automatic Trip Acceptance Time (hours)</label>
			<input
				id="ataTime"
				class="form-control"
				name="ataTime"
				type="number"
				formControlName="auto_accept_trip_hours"
				min="1"
				max="72"
			/>
		</div>

		<div class="form-group">
			<label for="trh">Trip Recording Hours</label>
			<div class="input-group">
				<input id="trhStart" [ngClass]="{
				invalid: projectForm.get('auto_reject_trip_hours_end').invalid && projectForm.get('auto_reject_trip_hours_end').touched
				}" placeholder="00:00" class="form-control" name="trhStart" formControlName="auto_reject_trip_hours_end" />
				<input id="trhEnd"
               [ngClass]="{
				invalid: projectForm.get('auto_reject_trip_hours_start').invalid && projectForm.get('auto_reject_trip_hours_start').touched
				}"
               placeholder="00:00" class="form-control" name="trhEnd" formControlName="auto_reject_trip_hours_start" />
			</div>
		</div>

		<div class="form-group">
			<label for="trd">Trip Recording Days</label>
			<select multiple class="form-control" id="trd" formControlName="auto_reject_trip_days_of_week">
				<option *ngFor="let day of DAYS; let i = index" [value]="i">{{ day }}</option>
			</select>
		</div>

    <div class="form-group">
      <label for="language">Available Info Unit Languages</label>
      <select multiple class="form-control" id="akl" formControlName="available_known_languages">
        <option *ngFor="let unit of KNOWNLANGUAGES; let i = index" [value]="i">{{ unit }}</option>
      </select>
    </div>

		<div class="form-group">
			<label for="language">Project Language</label>
			<select class="form-control" id="language" formControlName="language">
				<option *ngFor="let language of LANGUAGES" [value]="language">{{ language }}</option>
			</select>
		</div>

		<div class="form-group">
			<label for="transport">Transport</label>
			<select class="form-control" id="transport" formControlName="vehicle_type">
				<option *ngFor="let transport of TRANSPORTS" [value]="transport">{{ transport }}</option>
			</select>
		</div>

		<div class="form-group">
			<label for="domains">Domains</label>
			<select multiple class="form-control" id="domains" formControlName="domains">
				<option *ngFor="let domain of DOMAINS" [value]="domain" translate>{{ domain | domain }}</option>
			</select>
		</div>
	</form>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-primary" [disabled]="modalType === 'creating' ? false : loading" (click)="handleSubmit()">Submit</button>
</div>
