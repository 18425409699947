import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 't'
})
export class SplitPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? value.split('_').map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' ') : '';
  }

}
