<div class="reportspage">

  <div class="sidebar">
    <h2 translate>Reports</h2>
  </div>

  <div class="resultscontainer">

    <div style="margin-top: 10px; margin-left: 20px; padding-left: 15px;">company report</div>

    <div class="toolbar" style="justify-content: flex-start; padding-top: 0; padding-bottom: 0;">

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <button class="btn btn-outline-secondary" style="white-space: nowrap;" (click)="generateReport()" [disabled]="company_loading">
            <i class="fas fa-file"></i>&nbsp;&nbsp;<span translate>
              <img *ngIf="company_loading" src="/assets/images/spinner2.svg" width="16">
              generate company report
            </span></button>
          <!-- <a class="btn btn-outline-secondary" style="white-space: nowrap;" [href]="getReportUrl()">
            <i class="fas fa-file"></i>&nbsp;&nbsp;Generate Report</a> -->
        </div>
      </div>

    </div>

    <div style="margin-top: 10px; margin-left: 20px; padding-left: 15px;">driver report</div>

    <div class="toolbar" style="justify-content: flex-start; padding-top: 0;">

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">

        <button class="btn" ngbDropdownToggle style="min-height:34px; min-width: 100px" translate>{{target &&
          !target.label.includes('null') ? target.label : ''}}</button>
        <div ngbDropdownMenu class="max-height-200 overflow-y-auto">
          <button ngbDropdownItem *ngFor="let u of sortUser(users)" (click)="setTargetUser(u)">
            {{ u.system.teltonika && u.system.teltonika.short_name && u.system.teltonika.short_name.length > 0 ? u.system.teltonika.short_name : (u.first_name || '') + ' ' + (u.last_name || '') }}
          </button>
          <button *ngIf="target" ngbDropdownItem disabled style="color: black;" class="pl-2"><strong translate>Tags</strong></button>
          <button ngbDropdownItem *ngFor="let project_tag of sortGroup(project_tags)" (click)="setTargetTag(project_tag)" class="pl-4">
            {{ project_tag.tag_name }}
          </button>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <button class="btn btn-outline-secondary" style="white-space: nowrap;" (click)="generateReport(true)" [disabled]="driver_loading">
            <i class="fas fa-file"></i>&nbsp;&nbsp;<span translate>
              <img *ngIf="driver_loading" src="/assets/images/spinner2.svg" width="16">
              generate report
            </span></button>
          <!-- <a class="btn btn-outline-secondary" style="white-space: nowrap;" [href]="getReportUrl()">
            <i class="fas fa-file"></i>&nbsp;&nbsp;Generate Report</a> -->
        </div>
      </div>

    </div>

    <div style="margin-top: 10px; margin-left: 20px; padding-left: 15px;">set of drivers</div>

    <div class="toolbar" style="justify-content: flex-start; padding-top: 0;">

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">

        <button class="btn" ngbDropdownToggle style="min-height:34px; min-width: 100px" translate>{{'Select Drivers'}}</button>
        <div ngbDropdownMenu class="max-height-200 overflow-y-auto">
          <button *ngFor="let u of sortUser(users); let i=index;" [class]="drivers_selected[i] ? 'custom-dropdown-item bg-light' : 'custom-dropdown-item'" (click)="selectDriver(i)">
            {{ u.system.teltonika && u.system.teltonika.short_name && u.system.teltonika.short_name.length > 0 ? u.system.teltonika.short_name : (u.first_name || '') + ' ' + (u.last_name || '') }}
          </button>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <button class="btn btn-outline-secondary" style="white-space: nowrap;" (click)="generateDriversReport()" [disabled]="drivers_set_loading">
            <i class="fas fa-file"></i>&nbsp;&nbsp;<span translate>
              <img *ngIf="drivers_set_loading" src="/assets/images/spinner2.svg" width="16">
              generate report
            </span></button>
          <!-- <a class="btn btn-outline-secondary" style="white-space: nowrap;" [href]="getReportUrl()">
            <i class="fas fa-file"></i>&nbsp;&nbsp;Generate Report</a> -->
        </div>
      </div>

    </div>

    <div style="margin-top: 10px; margin-left: 20px; padding-left: 15px;">tag selection</div>

    <div class="toolbar" style="justify-content: flex-start; padding-top: 0;">

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp1" [(ngModel)]="from" ngbDatepicker
            #d1="ngbDatepicker" (dateSelect)="setFrom($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d1.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <input class="form-control" placeholder="yyyy-mm-dd" readonly name="dp2" [(ngModel)]="until" ngbDatepicker
            #d2="ngbDatepicker" (dateSelect)="setUntil($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d2.toggle()" type="button"><i
                class="fas fa-calendar-alt"></i></button>
          </div>
        </div>
      </div>

      <div ngbDropdown class="custom_dropdown">

        <button class="btn" ngbDropdownToggle style="min-height:34px; min-width: 100px" translate>{{'Select Tags'}}</button>
        <div ngbDropdownMenu class="max-height-200 overflow-y-auto">
          <button *ngFor="let project_tag of sortGroup(project_tags); let i=index;" [class]="tags_selected[i] ? 'custom-dropdown-item bg-light' : 'custom-dropdown-item'" (click)="selectTag(i)">
            {{ project_tag.tag_name }}
          </button>
        </div>
      </div>

      <div class="form-group" style="width: 140px;">
        <div class="input-group input-group-sm">
          <button class="btn btn-outline-secondary" style="white-space: nowrap;" (click)="generateTagsReport()" [disabled]="tag_loading">
            <i class="fas fa-file"></i>&nbsp;&nbsp;<span translate>
              <img *ngIf="tag_loading" src="/assets/images/spinner2.svg" width="16">
              generate report
            </span></button>
          <!-- <a class="btn btn-outline-secondary" style="white-space: nowrap;" [href]="getReportUrl()">
            <i class="fas fa-file"></i>&nbsp;&nbsp;Generate Report</a> -->
        </div>
      </div>

    </div>

  </div>
</div>
