<div class="container py-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#" routerLink="/admin/projects">Projects List</a></li>
      <li class="breadcrumb-item active" aria-current="page" >Project Details</li>
    </ol>
  </nav>
	<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs justify-content-center border border-0">
		<li [ngbNavItem]="1" class="tab-link">
			<a ngbNavLink class="border-0">Leaders</a>
			<ng-template ngbNavContent>
				<admin-project-leaders></admin-project-leaders>
			</ng-template>
		</li>
		<li [ngbNavItem]="2" class="tab-link">
			<a ngbNavLink class="border-0">Drivers</a>
			<ng-template ngbNavContent>
				<admin-project-drivers></admin-project-drivers>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
