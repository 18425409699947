import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as L from 'leaflet';
import { gridLayer, latLng, tileLayer } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';
import 'hls.js';
import { saveAs } from 'file-saver';

import { DataService } from "../../../../services/data.service";
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

declare let Hls;
@Component({
  selector: 'app-video-info',
  templateUrl: './video-info.component.html',
  styleUrls: ['./video-info.component.scss']
})
export class VideoInfoComponent implements OnInit {
  @Output() videoUpdate: EventEmitter<any> = new EventEmitter();
  userId: any;
  video: any;
  menu: any;

  step = 0;
  trip = null;
  map: L.DrawMap;
  leafletOptions: any;
  tiles: any[] = [];

  layergroup: L.LayerGroup;
  routing: L.Routing.Control;
  popupShown: boolean = false;
  markerPopup: any;
  highlight: L.Polyline;
  statusAvailableFields = ['steering', 'acceleration', 'deceleration', 'speeding', 'tailgating'];

  downloading = false;
  video_down_url = null;
  public _httpClient: HttpClient;

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private handler: HttpBackend,
  ) {
    this._httpClient = new HttpClient(this.handler)
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initVideoDownloadLink();
    this.startPlayingVideo();
  }

  initVideoDownloadLink() {
    this.dataService.downloadTeltonikaVideos(
      this.video.video_uuid
    ).subscribe((result: any) => {
      this.video_down_url = result.download_url;
    },
      error => {
        console.log(error)
      })
  }

  videoDownload() {
    this.downloading = true;
    // const url = this.video_down_url.replace('https://dss-track.s3.us-west-2.amazonaws.com/video', '/video-download')
    this._httpClient.get(this.video_down_url, { responseType: 'blob', observe: 'response' }).subscribe((response: any) => {
      const filename = this.getFileNameFromUrl(this.video_down_url);
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response.body);
      const blob = new Blob(binaryData, { type: dataType });
      saveAs(blob, filename);
      this.downloading = false;
    }, err => {
      this.downloading = false;
      console.log('Error while downloading');
    });
  }

  getFileNameFromUrl = (url: string) => {
    let chunks = url.split('/');
    let filename = chunks[chunks.length - 1];
    chunks = filename.split('?');
    return chunks[0];
  };


  starringVideo(video) {
    if (!video.starred) {
      this.dataService.setTeltonicaVideoStar(
        video.request_uuid
      ).subscribe(result => {
        video.starred = true;
        this.videoUpdate.emit();
      },
        error => {
        })
    } else {
      this.dataService.unsetTeltonicaVideoStar(
        video.request_uuid
      ).subscribe(result => {
        video.starred = false;
        this.videoUpdate.emit();
      },
        error => {
        })
    }
  }

  getTrip() {
    this.dataService.getTrips(
      this.userId,
      this.video.trip_uuid,
      true,
      true,
    ).subscribe(result => {
      this.trip = result;
      this.trip.events = this.trip.events.filter(ev => {
        return (ev.video?.url === this.video.video_url) && ev.video?.url && (ev.video.uuid === this.video.video_uuid);
      })
      console.log(this.menu, this.trip, this.video)
      this.initMap();
    },
      error => {
      })
  }

  startPlayingVideo() {
    var video = document.getElementById("video") as HTMLMediaElement;
    if (video && this.video.video_url) {
      var hls = new Hls();
      hls.loadSource(this.video.video_url);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
      });
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = 0;
      });
    }
  }

  onNext() {
    this.getTrip();
    var video = document.getElementById("video") as HTMLMediaElement;
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
    this.step += 1;
  }

  initMap() {
    this.tiles = [
      tileLayer('https://imobwww.uhasselt.be/osm_tiles/{z}/{x}/{y}.png', {
        maxZoom: 19,
        // tileSize: 512,
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
      })
    ];

    this.leafletOptions = {
      layers: [
        this.tiles[0]
      ],
      zoom: 9
    };

    if (this.trip['gps_trace'].lat?.length && this.trip['gps_trace'].lon?.length) {
      this.map = L.map('map', this.leafletOptions).setView(new L.LatLng(
        this.trip['gps_trace'].lat[0],
        this.trip['gps_trace'].lon[0],
      ), 14);
    }

    this.newRouting(this.trip['gps_trace'].lat.map((e, index) => {
      return L.latLng(e, this.trip['gps_trace'].lon[index])
    }));

  }


  addEventMarker() {
    if (this.trip.events.length === 0) return;
    const event = this.trip.events[0];
    if (event.gps_point) {
      let iconInfo: any = null;
      this.menu.forEach(m => {
        m.children.forEach(mm => {
          mm.children.forEach(mmm => {
            if (mm.label === event.param && mmm.label === event.subparam) {
              iconInfo = {
                icon: mmm.icon,
                color: mm.color
              }
              const myCustomColour = '#583470'

              const markerHtmlStyles = `
                background: url('${iconInfo.icon}') no-repeat;
                background-size: calc(100% - 5px);
                background-position-x: 2.5px;
                background-position-y: 2.5px;`;

              let icon = L.divIcon({
                className: 'custom-div-icon',
                html: `<div style='background-color: ${iconInfo.color ? iconInfo.color : myCustomColour};' class='marker-pin'></div><span style="${markerHtmlStyles}" />`,
                iconSize: [40, 50],
                iconAnchor: [20, 50]
              });
              let marker = L.marker(L.latLng(event.gps_point.lat, event.gps_point.lon), {
                icon: icon,
              });
              marker.addTo(this.map);
            }
          })
        })
      });
    }
  }

  newRouting(trace_points: L.LatLng[]) {
    var polyline = L.polyline(trace_points, { color: 'grey' }).addTo(this.map);
    this.map.fitBounds(polyline.getBounds());

    // Add start, end markers
    L.marker(trace_points[0], {
      icon: L.icon({
        iconUrl: 'assets/marker/trip_start.svg',
        iconSize: [45, 50],
        iconAnchor: [23, 50]
      }),
      draggable: false
    }).addTo(this.map);

    L.marker(trace_points[trace_points.length - 1], {
      icon: L.icon({
        iconUrl: 'assets/marker/trip_end.svg',
        iconSize: [45, 50],
        iconAnchor: [23, 50]
      }),
      draggable: false
    }).addTo(this.map);

    this.addEventMarker();
    return this.routing;
  }

  onBack() {
    var video = document.getElementById("video") as HTMLMediaElement;
    if (video) video.currentTime = 0;
    this.step -= 1;
  }
}
