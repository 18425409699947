import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from "../services/data.service";

@Pipe({
  name: 'isLiked',
  pure: false
})
export class IsLikedPipe implements PipeTransform {

  constructor(private dataService: DataService) {
  }

  transform(value: any): boolean {
    if (value && value.likes && value.likes.length) {
      return value.likes.findIndex(e => e.user_id == this.dataService.user.user_id) > -1;
    }
    return false;
  }

}
