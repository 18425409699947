<div class="card p-4">
	<div class="d-flex align-items-center mb-2">
		<h2 class="m-0">Project Leaders</h2>
		<button class="btn btn-primary ml-auto" (click)="openModal('creating')">Add Project Leader</button>
		<button class="btn btn-primary ml-2" (click)="attachProjectLeader()">Attach existing Project Leader</button>
	</div>

	<table class="table table-striped">
		<thead>
			<tr>
<!--				<th class="text-left">-->
<!--					<span translate>Safe-T ID</span>-->
<!--				</th>-->
				<th class="text-left cursor-pointer" (click)="toggleSort('first_name')">
					<span translate>First Name</span>&nbsp;
					<i class="fa" [ngClass]="getSortClass('first_name')" aria-hidden="true"></i>
				</th>
				<th class="text-left cursor-pointer" (click)="toggleSort('last_name')">
					<span translate>Last Name</span>&nbsp;
					<i class="fa" [ngClass]="getSortClass('last_name')" aria-hidden="true"></i>
				</th>
				<th class="text-left cursor-pointer" (click)="toggleSort('email')">
					<span translate>Email</span>&nbsp;
					<i class="fa" [ngClass]="getSortClass('email')" aria-hidden="true"></i>
				</th>
				<th></th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let leader of getLeaders()">
<!--				<td class="text-left">{{ getSafeTID(leader) }}</td>-->
				<td class="text-left">{{ leader.first_name }}</td>
				<td class="text-left">{{ leader.last_name }}</td>
				<td class="text-left">{{ leader.email }}</td>
				<td class="cursor-pointer">
					<div class="d-flex align-items-center">
						<i class="fa fa-pen mr-2" (click)="openModal('updating', leader)"></i>
						<i class="fa fa-trash" (click)="deleteUser(leader)"></i>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
