import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})



export class CreditsComponent implements OnInit {
  levels: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  credit_levels: any;
  sub;
  public system: string;
  constructor(
    private dataService:DataService,
  ) { }

  ngOnInit(): void {
    this.system = this.dataService.project.systems && this.dataService.project.systems.length ? this.dataService.project.systems[0].system_type : '';
    this.sub=this.dataService.getCreditLevels().subscribe(data=>{
      console.log(data);
      this.credit_levels=data.credit_levels;
    });
  }
  getKeys(obj){
    return Object.keys(obj);
  }

  save() {
    Object.keys(this.credit_levels).map(k=>{
      this.credit_levels[k]=parseInt(this.credit_levels[k]);
    });


    this.sub=this.dataService.setCreditLevels(this.credit_levels).subscribe(data=>{
      console.log(data);
    });
  }
}
