<div class="wrapper">
  <div class="toolbar px-0">
    <h3 class="ml-0 pointer breadcrumb-back" [routerLink]="['/gamification/prosncons']"
      [queryParams]="{domain: domain}"><i class="fas fa-chevron-left"></i></h3>
    <h3 class="text-left"><span translate>Pros for</span> {{subparam_label|translate}}</h3>
    <button class="btn btn-sm btn-warning text-white mr-0 px-2 btn-add" (click)="add('pro')">
      <i class="fa fa-plus"></i>
    </button>
  </div>
  <div class="tablescroll">
    <div class="row table-header-row">
      <div class="col-md-1"></div>
      <div class="col-md-5 text-bold text-center" translate>Pros</div>
      <div class="col-md-2 text-bold text-center" translate>Image</div>
      <div class="col-md-2 text-bold text-center" translate>Video</div>
      <div class="col-md-1"></div>
      <div class="col-md-1"></div>
    </div>
    <div class="row" *ngIf="dataSub && !dataSub.closed">
      <div class="blink text-center py-4"><span translate>Loading</span>...</div>
    </div>
    <div class="row" *ngIf="pros.length">
      <app-prosncons-edit-item class="w-100" *ngFor="let item of pros; let ind=index" type="pro" [item]="item" (callFunction)="updateData()"
        [index]="ind+1" style="border-radius: 4px;"></app-prosncons-edit-item>
    </div>
    <div *ngIf="!(dataSub && !dataSub.closed) && !pros.length">
      <div class="text-center py-4" translate>No Pros to display</div>
    </div>
  </div>
  <div class="toolbar px-0">
    <h3 class="text-left ml-40"><span translate>Cons for</span> {{subparam_label|translate}}</h3>
    <button class="btn btn-sm btn-warning text-white mr-0 px-2 btn-add" (click)="add('con')">
      <i class="fa fa-plus"></i>
    </button>
  </div>
  <div class="tablescroll">
    <div class="tablescroll">
      <div class="row table-header-row">
        <div class="col-md-1"></div>
        <div class="col-md-5 text-bold text-center" translate>Cons</div>
        <div class="col-md-2 text-bold text-center" translate>Image</div>
        <div class="col-md-2 text-bold text-center" translate>Video</div>
        <div class="col-md-1"></div>
        <div class="col-md-1"></div>
      </div>
      <div class="row" *ngIf="dataSub && !dataSub.closed">
        <div class="blink text-center py-4"><span translate>Loading</span>...</div>
      </div>
      <div class="row" *ngIf="cons.length">
        <app-prosncons-edit-item class="w-100" *ngFor="let item of cons; let ind=index" type="con" [item]="item" (callFunction)="updateData()"
          [index]="ind+1" style="border-radius: 4px;"></app-prosncons-edit-item>
      </div>
      <div *ngIf="!(dataSub && !dataSub.closed) && !cons.length">
        <div class="text-center py-4" translate>No Cons to display</div>
      </div>
    </div>
  </div>
</div>
