import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {Language, LanguageCode, LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  languages: Language[];
  lang: LanguageCode = 'en';

  form: UntypedFormGroup;
  requested: boolean;
  sub: Subscription;

  constructor(private translate: TranslateService,
              private fb: UntypedFormBuilder,
              private authService: AuthService,
              private languageService: LanguageService
  ) {
    this.languages = this.languageService.getListOfLanguages();
    this.lang = this.languageService.getCurrentLanguage().code;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: '',
    });
  }

  handleSubmit() {
    const {email} = this.form.value as { email: string };

    this.requested = false;
    this.sub = this.authService.requestNewPassword(email).subscribe(() => {
      this.requested = true;
    });
  }

  handleLangChange(): void {
    this.languageService.switchLanguage(this.lang);
  }
}
